
import LocalstorageService from "../helpers/localstorage-services";
import otpservice from "../services/resendOtp";
import { SetErrorMessage, SetSuccessMessage, SetVerifyUser, verifyOtpReq } from "../slices/resendOtp";

export const verifyOtp = (payload) => {
    return (dispatch) => {
        otpservice.otpVerify(payload)
            .then(response => {
                const { data, status, error, message } = response.data;
                if (status === 1) {
                    console.log(data.user, ">>>");

                    dispatch(SetVerifyUser(data.user));
                    dispatch(SetSuccessMessage(message));
                } else {
                    dispatch(SetErrorMessage(error));
                }
            })
            .catch(error => {
                dispatch(SetErrorMessage(error));
            });
    };
};

