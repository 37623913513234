import React, { useEffect } from 'react';
import User from './Routes/User/User';
// import ReactGA from 'react-ga';

// ReactGA.initialize('UA-XXXXXXXXX-X');

const App = () => {

  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);

  return (
    <>
      <User />
    </>
  )
}

export default App