import { get, post, postFormData } from ".";

const URI = "/wishlists";

const getWishList = (payload) => {
    const URL = `${URI}`;
    return get(URL, payload);
};

const addProductWishList = (payload) => {
    const URL = `${URI}-add-product`;
    return postFormData(URL, payload);
}

const checkProductWishList = (payload) => {
    const URL = `${URI}-check-product`;
    return post(URL, payload);
}

const deleteProductWishList = (payload) => {
    const URL = `${URI}-remove-product`;
    return postFormData(URL, payload);
}

const wishListService = {
    getWishList,
    addProductWishList,
    checkProductWishList,
    deleteProductWishList,
};
export default wishListService;