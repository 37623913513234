import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LocalstorageService from '../../../helpers/localstorage-services';
import { getCartData } from '../../../middlewares/cart/cart';

const CheckOutSummary = () => {
    const dispatch = useDispatch();
    const { cart, cartLoading, cartMessage, cartError } = useSelector((state) => state.cart);
    const userData = LocalstorageService.getLoggedInUserDetails();
    useEffect(() => {
        const bodyFormData = new FormData();
        bodyFormData.append("user_id", userData?.id);
        dispatch(getCartData(bodyFormData));
    }, []);

    const bagTotal = cart.reduce((total, item) => total + item.cart_price, 0);
    const bagDiscount = cart.reduce((total, item) => total + (item.tag_price - item.cart_indiazona_price) * item.quantity, 0);
    const cartIndiaZonaPrice = cart.reduce((total, item) => total + item.cart_indiazona_price, 0);

    const grandTotal = cartIndiaZonaPrice;

    return (
        <div className="col-12 col-md-4 my-5">
            <div className="total-content">
                <div className="title text-center">
                    <h5>Order Summary</h5>
                </div>
                <div className="order-details my-3">
                    <div className="bill-section">
                        <p>Bag Total</p>
                        <p>₹{bagTotal.toFixed(2)}</p>
                    </div>

                    <div className="bill-section">
                        <p>Bag Discount</p>
                        <p>- ₹{bagDiscount.toFixed(2)}</p>
                    </div>

                    {/* <ul>
                        <h6>Convenience Fee</h6>
                        <li>
                            <div className="bill-section">
                                <small>Delivery Fee</small>
                                <small>₹{deliveryFee.toFixed(2)}</small>
                            </div>
                        </li>
                        <li>
                            <div className="bill-section">
                                <p>Platform Fee</p>
                                <p>₹{platformFee.toFixed(2)}</p>
                            </div>
                        </li>
                    </ul> */}

                    <div className="bill-section mt-3">
                        <p><b>Amount Payable</b></p>
                        <p><b>₹{grandTotal.toFixed(2)}</b></p>
                    </div>
                </div>
                <div className='table-responsive'>

                </div>
            </div>
        </div>
    )
}

export default CheckOutSummary