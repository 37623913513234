import { createSlice } from "@reduxjs/toolkit";
import { SHORTVIDEO } from "../helpers/slice.name";



export const shortVideoSlice = createSlice({
    name: SHORTVIDEO,
    initialState: {
        loading: false,

        shortVideoLoading: false,
        shortVideo: [],
        shortVideoMessage: "",
        shortVideoError: "",
    },
    reducers: {
        shortVideoRequest: (state) => {
            state.shortVideoLoading = true;
            state.shortVideoMessage = "";
            state.shortVideoError = "";
        },
        shortVideoSuccess: (state, action) => {
            state.shortVideoLoading = false;
            state.shortVideo = action.payload.data;
            state.shortVideoMessage = action.payload.message;
        },
        shortVideoError: (state, action) => {
            state.shortVideoLoading = false;
            state.shortVideoError = action.payload;
        },
    }
})

export const { shortVideoRequest, shortVideoSuccess, shortVideoError } = shortVideoSlice.actions;

export default shortVideoSlice.reducer;