import React, { useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { resendCodeWhileVerify, verifyUserEmail } from "../middlewares/auth";
import { useDispatch, useSelector } from "react-redux";
import { otp } from "../helpers/yup.validation.schema";
import * as Yup from "yup";
import ToastService from "../helpers/toast-services";
import ROUTE_URLS from "../config/routes";
import ErrorList from "../Components/error-list/ErrorList";
import LocalstorageService from "../helpers/localstorage-services";

const VerifyEmail = () => {
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const localEmail = localStorage.getItem("IN_USER_DATA");
  const convertObj = JSON.parse(localEmail);
  const { loading, emailVerifyMessage, emailVerifyError, resendMessage, resendError } = useSelector((state) => state.auth);

  const maskEmail = (email) => {
    if (!email) return "";
    const [localPart, domain] = email.split("@");
    const maskedLocalPart = localPart.substring(0, 2) + "****";
    return `${maskedLocalPart}@${domain}`;
  };

  useEffect(() => {
    if (!loading) {
      if (emailVerifyMessage) {
        ToastService.success(emailVerifyMessage);
        navigate(ROUTE_URLS.HOME, { replace: true });
      }
    }
  }, [emailVerifyMessage]);

  useEffect(() => {
    if (!loading) {
      if (resendMessage) {
        ToastService.success(resendMessage);
        verifyEmailFormik.setFieldValue("otp", "");
        setMinutes(1);
        setSeconds(0);
      }
    }
  }, [resendMessage]);

  useEffect(() => {
    if (!loading) {
      if (emailVerifyError) {
        // ToastService.error("The OTP code is incorrect");
        verifyEmailFormik.setFieldValue("otp", "");
      }
    }
  }, [emailVerifyError]);

  useEffect(() => {
    let timer;
    if (seconds > 0 || minutes > 0) {
      timer = setInterval(() => {
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(timer);
          } else {
            setMinutes((prevMinutes) => prevMinutes - 1);
            setSeconds(59);
          }
        } else {
          setSeconds((prevSeconds) => prevSeconds - 1);
        }
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [seconds, minutes]);

  const OtpSchema = Yup.object({
    otp: otp,
  });

  const handleSubmit = (values) => {
    const payload = {
      user_id: convertObj?.id,
      verification_code: values.otp,
    };
    dispatch(verifyUserEmail(payload));
  };

  const verifyEmailFormik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: OtpSchema,
    onSubmit: handleSubmit,
  });

  const resendOTP = () => {
    const payload = {
      user_id: LocalstorageService.getLoggedInUserDetails().id,
      register_by: "email",
    };
    dispatch(resendCodeWhileVerify(payload));
  };

  return (
    <div className="indiazona-login">
      <div className="container" id="container">
        <div className="form-container sign-in-container otp-page">
          <div className="account-page login-page">
            <div className="row">
              <div className="p-4">
                <div className="text-center">
                  <h3 className="account-title mt-5 mb-2">
                    Verify Your Email Address
                  </h3>
                  {emailVerifyError && <ErrorList error={emailVerifyError} />}
                  <p className="account-subtitle mb-2">
                    Please enter the 6-digit OTP sent to your registered email address.
                  </p>
                  <span className="">
                    <b>Email:</b> {maskEmail(convertObj.email)}
                  </span>
                </div>
                <form
                  className="row g-3 otp-form p-0"
                  onSubmit={verifyEmailFormik.handleSubmit}>
                  <div className="col-md-12 otp-group mb-4">
                    <div className="d-flex justify-content-center align-items-center">
                      <OTPInput
                        value={verifyEmailFormik.values.otp}
                        onChange={(val) => verifyEmailFormik.setFieldValue("otp", val)}
                        numInputs={6}
                        className="otp-inputs"
                        renderSeparator={<span className="mx-1">-</span>}
                        renderInput={(props, index) => (
                          <input
                            className="otp-input"
                            {...props}
                            type="number"
                          />
                        )}
                      />
                    </div>
                    {verifyEmailFormik.touched.otp && verifyEmailFormik.errors.otp && (
                      <div className="error">
                        {verifyEmailFormik.errors.otp}
                      </div>
                    )}
                  </div>
                  <div
                    className=""
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {seconds > 0 || minutes > 0 ? (
                      <p className="otp-time p-0"
                        style={{ paddingTop: "15px" }}>
                        Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}
                        :{seconds < 10 ? `0${seconds}` : seconds}
                      </p>
                    ) : (
                      <p className="otp-time">Didn't recieve code?</p>
                    )}
                    <button
                      className="otp-button"
                      type="button"
                      style={{
                        border: "none",
                        background: "none",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        flex: 1,
                        cursor: "pointer",
                        color: seconds > 0 || minutes > 0 ? "grey" : "#00A6A6",
                      }}
                      onClick={resendOTP}
                      disabled={seconds > 0 || minutes > 0}
                    >
                      Resend OTP
                    </button>
                  </div>
                  <div className="input-block d-flex justify-content-center">
                    <button className="common-btn Login-btn w-100"
                      type="submit" disabled={loading} >
                      {loading ? "Processing..." : "Submit"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="overlay-container">
          <div className="overlay">
            <div className="overlay-panel overlay-right">
              <div className="india-zona-login">
                <img src="/assets/images/svg-img/logo.svg" alt="logo" />
              </div>
              <p className="py-2">
                Confirm your email address to complete the registration process.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
