import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      "Welcome": "Welcome",
      "Become a Seller!": "Become a Seller!",
      "Login to Seller": "Login to Seller",
      "Helpline": "Helpline"
    }
  },
  es: {
    translation: {
      "Welcome": "Bienvenido",
      "Become a Seller!": "¡Conviértete en vendedor!",
      "Login to Seller": "Iniciar sesión como vendedor",
      "Helpline": "Línea de ayuda"
    }
  },
  fr: {
    translation: {
      "Welcome": "Bienvenue",
      "Become a Seller!": "Devenez vendeur!",
      "Login to Seller": "Connexion au vendeur",
      "Helpline": "Hotline"
    }
  },
  hi: {
    translation: {
      "Welcome": "स्वागत है",
      "Become a Seller!": "विक्रेता बनें!",
      "Login to Seller": "विक्रेता में लॉगिन करें",
      "Helpline": "हेल्पलाइन"
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en', 
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
