import { createSlice } from "@reduxjs/toolkit";
import { CATEGORY } from "../helpers/slice.name";

export const categorySlice = createSlice({
    name: CATEGORY,
    initialState: {
        loading: false,
        homeCategoryLoading: false,
        homeCategory: [],
        homeCategoryMessage: "",
        homeCategoryError: "",
    },
    reducers: {
        homeCategoryRequest: (state) => {
            state.homeCategoryLoading = true;
            state.homeCategoryMessage = "";
            state.homeCategoryError = "";
        },
        homeCategorySuccess: (state, action) => {
            state.homeCategoryLoading = false;
            state.homeCategory = action.payload.data;
            state.homeCategoryMessage = action.payload.message;
        },
        homeCategoryError: (state, action) => {
            state.homeCategoryLoading = false;
            state.homeCategoryError = action.payload;
        },
    }
})
export const { homeCategoryRequest, homeCategorySuccess, homeCategoryError } = categorySlice.actions;

export default categorySlice.reducer;