import React, { useEffect, useState } from 'react';
import * as Yup from "yup";
import { useFormik } from 'formik';
import { ConfirmPassword, Pass } from "../helpers/yup.validation.schema";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetPasswordReset } from '../slices/auth.slice';
import ToastService from '../helpers/toast-services';
import ErrorList from '../Components/error-list/ErrorList';
import { resetPassword } from '../middlewares/auth';
import ROUTE_URLS from '../config/routes';

const Resetpassword = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { user_id } = location.state;
    const [showPass, setShowpass] = useState(false);
    const [showPass1, setShowpass1] = useState(false);
    const toggleShowPassword = () => {
        setShowpass(!showPass);
    };
    const toggleShowPassword1 = () => {
        setShowpass1(!showPass1);
    };

    const {
        resetPasswordError,
        resetPasswordLoading,
        resetPasswordMessage,
    } = useSelector((state) => state.auth);

    useEffect(() => {
        if (!resetPasswordLoading) {
            if (resetPasswordMessage) {
                ToastService.success(resetPasswordMessage);
                dispatch(resetPasswordReset());
                navigate(ROUTE_URLS.LOGIN, { replace: true });
            }
        }
    }, [resetPasswordMessage, resetPasswordLoading]);

    const handleSubmit = (values) => {
        const payload = {
            user_id: user_id,
            new_password: values.password,
            new_password_confirmation: values.cpassword
        };
        dispatch(resetPassword(payload));
    }

    const resetSchema = Yup.object({
        password: Pass,
        cpassword: ConfirmPassword,
    });

    const resetFormik = useFormik({
        initialValues: {
            password: '',
            cpassword: '',
        },
        validationSchema: resetSchema,
        onSubmit: handleSubmit,
    });

    return (
        <>
            <div className='indiazona-login'>
                <div className='container right-panel-active' id="container">
                    <div className="form-container sign-up-container">
                        <form onSubmit={resetFormik.handleSubmit} className='CreateAccount-Form'>
                            <h4 className='mb-4'>Reset Your Password</h4>
                            {resetPasswordError && <ErrorList error={resetPasswordError} />}
                            <div className='password-input mb-2'>
                                <input type={showPass ? 'text' : 'password'} className='m-0' placeholder="Enter New password"
                                    value={resetFormik.values.password} name="password"
                                    onChange={resetFormik.handleChange}
                                    onBlur={resetFormik.handleBlur} />
                                <i className={`fa-solid ${showPass ? 'fa-eye-slash' : 'fa-eye'} eye-icon `} style={{ cursor: 'pointer', }} onClick={toggleShowPassword}></i>
                                {resetFormik.touched.password && resetFormik.errors.password && (
                                    <div className="error">
                                        {resetFormik.errors.password}
                                    </div>
                                )}
                            </div>
                            <div className='password-input mb-2'>
                                <input type={showPass1 ? 'text' : 'password'} className='m-0'
                                    value={resetFormik.values.cpassword} placeholder="Confirm Password"
                                    onChange={resetFormik.handleChange} name="cpassword"
                                    onBlur={resetFormik.handleBlur} />
                                <i className={`fa-solid ${showPass1 ? 'fa-eye-slash' : 'fa-eye'} eye-icon `} style={{ cursor: 'pointer', }} onClick={toggleShowPassword1}></i>
                                {resetFormik.touched.cpassword && resetFormik.errors.cpassword && (
                                    <div className="error">
                                        {resetFormik.errors.cpassword}
                                    </div>
                                )}
                            </div>
                            <button className='my-2 common-btn Login-btn w-100' type='submit' disabled={resetPasswordLoading}>{resetPasswordLoading ? 'Processing...' : 'Change Password'}</button>
                        </form>
                    </div>

                    <div className="overlay-container">
                        <div className="overlay">
                            <div className="overlay-panel overlay-left">
                                <div className="india-zona-login">
                                    <img src="/assets/images/svg-img/logo.svg" alt="logo" />
                                </div>
                                <p className='py-2'>To keep connected with us please fill up the form for reset password</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Resetpassword