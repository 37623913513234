import { SearchService } from "../../services/search";
import { searchError, searchRequest, searchSuccess } from "../../slices/search.slice";


export const searchProducts = (payload) => {
    return (dispatch) => {
        dispatch(searchRequest());
        SearchService.searchProduct(payload)
            .then((response) => {
                const { status, data, message, error } = response.data;
                if (status === 1) {
                    dispatch(searchSuccess({ data: data?.data, message }));
                } else {
                    dispatch(searchError(error));
                }
            }).catch((error) => {
                dispatch(searchError(error));
            });
    }
}