import DoodleService from "../services/doodle";
import { doodleError, doodleRequest, doodleSuccess } from "../slices/doodleSlice";



export function getDoodlesData(payload) {
    return (dispatch) => {
        dispatch(doodleRequest());
        DoodleService.getDoodle(payload)
            .then((response) => {
                const { status, data, message, error } = response.data;
                if (status === 1) {
                    dispatch(doodleSuccess({ data, message }));
                } else {
                    dispatch(doodleError(error));
                }
            }).catch((error) => {
                dispatch(doodleError(error));
            })
    }
}
