import React from 'react'

import { Modal } from 'react-bootstrap';

const MobileSearchModel = ({ showSearchModal, handleCloseSearchModal }) => {

    return (
        <Modal show={showSearchModal} onHide={handleCloseSearchModal}>
            <div className="modal-dialog">
                <div className="modal-content border-0">
                    {/* <div className="modal-header position-relative text-right justify-content-end d-flex">
                        <span style={{ cursor: 'pointer' }} onClick={handleCloseSearchModal}>
                            <i className="fa-solid fa-x fa-1x p-2" />
                        </span>
                    </div> */}
                    <div className="modal-body searchBox">
                        <div className="row">
                            <div className="col-12">
                                <div className="searchForm position-relative">
                                    <form action="https://indiazona.addonwebtech.com/search" method="GET" className="stop-propagation flex navForm" role="search">
                                        <input className="form-control searchInput" type="search" placeholder="Bharatki Apni Dukaan" aria-label="Search" id="search" />
                                        <button className="btn btn-success btnSearch" type="submit">
                                            <i className="fa-solid fa-magnifying-glass" />
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default MobileSearchModel