import React from 'react'
import TopBar from '../Components/Common/TopBar'
import Header from '../Components/Common/Header'
import Footer from '../Components/Common/Footer'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper/modules'
import { Link } from 'react-router-dom'

const BlogDetails = () => {
    return (
        <>
            <main>
                <section className="page-title">
                    <div className="pattern-layer"></div>
                    <div className="container-full">
                        <div className="content-box">
                            <h3>Blog Details</h3>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item active" aria-current="page"><Link to='/'>Home</Link></li>
                                    <li className="breadcrumb-item" aria-current="page">/</li>
                                    <li className="breadcrumb-item" aria-current="page">Blog Details Page</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section className="blog-detail-section">
                    <div className="container-fluid">
                        <div className="row justify-content-between">
                            <div className="col-lg-7 col-md-7 col-sm-12">
                                <div className="blog-detail-wrapper">
                                    <img src="/assets/images/blog/blog-giftcard-4.jpg" alt="blog-detail-img" />
                                </div>
                                <div className="blog-detail-content mt-2 mb-2">
                                    <h6>
                                        Make Your Expanding Contracting Content
                                    </h6>
                                    <p>
                                        Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                                        dolore of magna aliqua. Ut enim ad minim veniam, made of owl the quis nostrud
                                        exercitation ullamco laboris nisi ut aliquip ex ea dolor commodo consequat. Duis
                                        aute irure and dolor in reprehenderit.
                                        The is ipsum dolor sit amet consectetur adipiscing elit. Fusce eleifend porta
                                        arcu In hac habitasse the is platea augue thelorem turpoi dictumst. In lacus
                                        libero faucibus at malesuada sagittis placerat eros sed istincidunt augue ac
                                        ante rutrum sed the is sodales augue consequat.
                                        Nulla facilisi. Vestibulum tristique sem in eros eleifend imperdiet. Donec quis
                                        convallis neque. In id lacus pulvinar lacus, eget vulputate lectus. Ut viverra
                                        bibendum lorem, at tempus nibh mattis in. Sed a massa eget lacus consequat
                                        auctor.
                                    </p>
                                    <p>
                                        Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                                        dolore of magna aliqua. Ut enim ad minim veniam, made of owl the quis nostrud
                                        exercitation ullamco laboris nisi ut aliquip ex ea dolor commodo consequat. Duis
                                        aute irure and dolor in reprehenderit.
                                        The is ipsum dolor sit amet consectetur adipiscing elit. Fusce eleifend porta
                                        arcu In hac habitasse the is platea augue thelorem turpoi dictumst. In lacus
                                        libero faucibus at malesuada sagittis placerat eros sed istincidunt augue ac
                                        ante rutrum sed the is sodales augue consequat.
                                        Nulla facilisi. Vestibulum tristique sem in eros eleifend imperdiet. Donec quis
                                        convallis neque. In id lacus pulvinar lacus, eget vulputate lectus. Ut viverra
                                        bibendum lorem, at tempus nibh mattis in. Sed a massa eget lacus consequat
                                        auctor.
                                    </p>
                                    <p>
                                        Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                                        dolore of magna aliqua. Ut enim ad minim veniam, made of owl the quis nostrud
                                        exercitation ullamco laboris nisi ut aliquip ex ea dolor commodo consequat. Duis
                                        aute irure and dolor in reprehenderit.
                                        The is ipsum dolor sit amet consectetur adipiscing elit. Fusce eleifend porta
                                        arcu In hac habitasse the is platea augue thelorem turpoi dictumst. In lacus
                                        libero faucibus at malesuada sagittis placerat eros sed istincidunt augue ac
                                        ante rutrum sed the is sodales augue consequat.
                                        Nulla facilisi. Vestibulum tristique sem in eros eleifend imperdiet. Donec quis
                                        convallis neque. In id lacus pulvinar lacus, eget vulputate lectus. Ut viverra
                                        bibendum lorem, at tempus nibh mattis in. Sed a massa eget lacus consequat
                                        auctor.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="India-map">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="india-map-img">
                                                <img src="/assets/images/svg-img/FevIcon.svg" alt="india " />
                                            </div>
                                            <div className="blog-post py-2">
                                                <p className="text-center">
                                                    Hello, We’re content writer who is fascinated by content fashion, celebrity
                                                    and lifestyle. We helps clients bring the right content to the right people.
                                                </p>
                                                <ul className="social_link  list-inline mt-2 mb-2 h-100 d-flex  align-items-center">
                                                    <li><a href="#"><i className="fab fa-facebook-f" /></a></li>
                                                    <li><a href="#"><i className="fab fa-instagram" /></a></li>
                                                    <li><a href="#"><i className="fab fa-youtube" /></a></li>
                                                    <li><a href="#"><i className="fab fa-linkedin-in" /></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="blog-detail-inner wrap-sidebar-mobile">
                                    <div className="blog-detail-inner-content">
                                        <h6 className="mb-3">Recent Post</h6>
                                        <div className="blog-detail-content">
                                            <ul>
                                                <li className="mb-3">
                                                    <div className="blog-article-item style-sidebar">
                                                        <div className="article-thumb">
                                                            <a href="/blog-details">
                                                                <img src="/assets/images/blog/blog-8.jpg" />
                                                            </a>
                                                        </div>
                                                        <div className="article-content">
                                                            <div className="article-label">
                                                                <a href="/blog-details" className="tf-btn btn-sm radius-3 btn-fill animate-hover-btn">Accessories</a>
                                                            </div>
                                                            <div className="article-title">
                                                                <a href="/blog-details" >Effortlessly Blends The
                                                                    Carefree
                                                                    Style</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="mb-3">
                                                    <div className="blog-article-item style-sidebar">
                                                        <div className="article-thumb">
                                                            <a href="/blog-details">
                                                                <img src="/assets/images/blog/blog-5.jpg" />
                                                            </a>
                                                        </div>
                                                        <div className="article-content">
                                                            <div className="article-label">
                                                                <a href="/blog-details" className="tf-btn btn-sm radius-3 btn-fill animate-hover-btn">Accessories</a>
                                                            </div>
                                                            <div className="article-title">
                                                                <a href="/blog-details" >The Limited Edition Club des
                                                                    Sports Courchevel</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="mb-3">
                                                    <div className="blog-article-item style-sidebar">
                                                        <div className="article-thumb">
                                                            <a href="/blog-details">
                                                                <img src="/assets/images/blog/blog-1.jpg" />
                                                            </a>
                                                        </div>
                                                        <div className="article-content">
                                                            <div className="article-label">
                                                                <a href="/blog-details" className="tf-btn btn-sm radius-3 btn-fill animate-hover-btn">Fashion</a>
                                                            </div>
                                                            <div className="article-title">
                                                                <a href="/blog-details" >Christine Is A True Style
                                                                    Icon</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="mb-3">
                                                    <div className="blog-article-item style-sidebar">
                                                        <div className="article-thumb">
                                                            <a href="/blog-details">
                                                                <img src="/assets/images/blog/blog-9.jpg" />
                                                            </a>
                                                        </div>
                                                        <div className="article-content">
                                                            <div className="article-label">
                                                                <a href="/blog-details" className="tf-btn btn-sm radius-3 btn-fill animate-hover-btn">Accessories</a>
                                                            </div>
                                                            <div className="article-title">
                                                                <a href="/blog-details" >Effortlessly Blends The
                                                                    Carefree
                                                                    Style</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="mb-3">
                                                    <div className="blog-article-item style-sidebar">
                                                        <div className="article-thumb">
                                                            <a href="/blog-details">
                                                                <img src="/assets/images/blog/blog-10.jpg" />
                                                            </a>
                                                        </div>
                                                        <div className="article-content">
                                                            <div className="article-label">
                                                                <a href="/blog-details" className="tf-btn btn-sm radius-3 btn-fill animate-hover-btn">Accessories</a>
                                                            </div>
                                                            <div className="article-title">
                                                                <a href="/blog-details" >The Limited Edition Club des
                                                                    Sports Courchevel</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="mb-3">
                                                    <div className="blog-article-item style-sidebar">
                                                        <div className="article-thumb">
                                                            <a href="/blog-details">
                                                                <img src="/assets/images/blog/blog-11.jpg" />
                                                            </a>
                                                        </div>
                                                        <div className="article-content">
                                                            <div className="article-label">
                                                                <a href="/blog-details" className="tf-btn btn-sm radius-3 btn-fill animate-hover-btn">Fashion</a>
                                                            </div>
                                                            <div className="article-title">
                                                                <a href="/blog-details" >Christine Is A True Style
                                                                    Icon</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* =======blog-detail-section======End */}

                <section className="article-section">
                    <div className="container-fluid">

                        <div className="row">
                            <Swiper
                                modules={[Autoplay]}
                                spaceBetween={30}
                                slidesPerView={4}
                                autoplay={{ delay: 2500 }}
                                loop={true}
                                pagination={{ clickable: true }}
                                navigation={true}
                                breakpoints={{
                                    320: { slidesPerView: 1 },
                                    640: { slidesPerView: 2 },
                                    768: { slidesPerView: 2 },
                                    1024: { slidesPerView: 4 },
                                    1200: { slidesPerView: 4 },
                                }}>
                                <SwiperSlide>
                                    <div className="blog-article-item">
                                        <div className="article-thumb">
                                            <a to='/blog-details'>
                                                <img className="lazyload" data-src="/assets/images/blog/blog-2.jpg" src="/assets/images/blog/blog-2.jpg" alt="img-blog" />
                                            </a>
                                            <div className="article-label">
                                                <Link to='/blog-details' className="tf-btn btn-sm radius-3 btn-fill animate-hover-btn">Accessories</Link>
                                            </div>
                                        </div>
                                        <div className="article-content">
                                            <div className="article-title">
                                                <Link to='/blog-details' >The next generation of leather alternatives</Link>
                                            </div>
                                            <div className="article-btn">
                                                <Link to='/blog-details' className="tf-btn btn-line fw-6">Read more<i className="icon fa-solid fa-arrow-right px-1" /></Link>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="blog-article-item">
                                        <div className="article-thumb">
                                            <Link to='/blog-details'>
                                                <img className="lazyload" data-src="/assets/images/blog/blog-3.jpg" src="/assets/images/blog/blog-3.jpg" alt="img-blog" />
                                            </Link>
                                            <div className="article-label">
                                                <Link to='/blog-details' className="tf-btn btn-sm radius-3 btn-fill animate-hover-btn">Accessories</Link>
                                            </div>
                                        </div>
                                        <div className="article-content">
                                            <div className="article-title">
                                                <Link to='/blog-details' >An Exclusive Clothing Collaboration</Link>
                                            </div>
                                            <div className="article-btn">
                                                <Link to='/blog-details' className="tf-btn btn-line fw-6">Read more<i className="icon fa-solid fa-arrow-right px-1" /></Link>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="blog-article-item">
                                        <div className="article-thumb">
                                            <Link to='/blog-details'>
                                                <img className="lazyload" data-src="/assets/images/blog/blog-2.jpg" src="/assets/images/blog/blog-2.jpg" alt="img-blog" />
                                            </Link>
                                            <div className="article-label">
                                                <Link to='/blog-details' className="tf-btn btn-sm radius-3 btn-fill animate-hover-btn">Accessories</Link>
                                            </div>
                                        </div>
                                        <div className="article-content">
                                            <div className="article-title">
                                                <Link to='/blog-details' >The next generation of leather alternatives</Link>
                                            </div>
                                            <div className="article-btn">
                                                <Link to='/blog-details' className="tf-btn btn-line fw-6">Read more<i className="icon fa-solid fa-arrow-right px-1" /></Link>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="blog-article-item">
                                        <div className="article-thumb">
                                            <Link to='/blog-details'>
                                                <img className="lazyload" data-src="/assets/images/blog/blog-4.jpg" src="/assets/images/blog/blog-4.jpg" alt="img-blog" />
                                            </Link>
                                            <div className="article-label">
                                                <Link to='/blog-details' className="tf-btn btn-sm radius-3 btn-fill animate-hover-btn">Accessories</Link>
                                            </div>
                                        </div>
                                        <div className="article-content">
                                            <div className="article-title">
                                                <Link to='/blog-details' >Hello Fashion by Colombian-American</Link>
                                            </div>
                                            <div className="article-btn">
                                                <Link to='/blog-details' className="tf-btn btn-line fw-6">Read more<i className="icon fa-solid fa-arrow-right px-1" /></Link>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="blog-article-item">
                                        <div className="article-thumb">
                                            <Link to='/blog-details'>
                                                <img className="lazyload" data-src="/assets/images/blog/blog-5.jpg" src="/assets/images/blog/blog-5.jpg" alt="img-blog" />
                                            </Link>
                                            <div className="article-label">
                                                <Link to='/blog-details' className="tf-btn btn-sm radius-3 btn-fill animate-hover-btn">Accessories</Link>
                                            </div>
                                        </div>
                                        <div className="article-content">
                                            <div className="article-title">
                                                <a to='/blog-details' >Christine Is A True Style Icon</a>
                                            </div>
                                            <div className="article-btn">
                                                <Link to='/blog-details' className="tf-btn btn-line fw-6">Read more<i className="icon fa-solid fa-arrow-right px-1" /></Link>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="blog-article-item">
                                        <div className="article-thumb">
                                            <Link to='/blog-details'>
                                                <img className="lazyload" data-src="/assets/images/blog/blog-7.jpg" src="/assets/images/blog/blog-7.jpg" alt="img-blog" />
                                            </Link>
                                            <div className="article-label">
                                                <Link to='/blog-details' className="tf-btn btn-sm radius-3 btn-fill animate-hover-btn">Design</Link>
                                            </div>
                                        </div>
                                        <div className="article-content">
                                            <div className="article-title">
                                                <Link to='/blog-details' >Effortlessly Blends The Carefree Style</Link>
                                            </div>
                                            <div className="article-btn">
                                                <Link to='/blog-details' className="tf-btn btn-line fw-6">Read more<i className="icon fa-solid fa-arrow-right px-1" /></Link>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="blog-article-item">
                                        <div className="article-thumb">
                                            <Link to='/blog-details'>
                                                <img className="lazyload" data-src="/assets/images/blog/blog-6.jpg" src="/assets/images/blog/blog-6.jpg" alt="img-blog" />
                                            </Link>
                                            <div className="article-label">
                                                <Link to='/blog-details' className="tf-btn btn-sm radius-3 btn-fill animate-hover-btn">Accessories</Link>
                                            </div>
                                        </div>
                                        <div className="article-content">
                                            <div className="article-title">
                                                <Link to='/blog-details' >The Variety Of Styles And Prices Are Endless</Link>
                                            </div>
                                            <div className="article-btn">
                                                <Link to='/blog-details' className="tf-btn btn-line fw-6">Read more<i className="icon fa-solid fa-arrow-right px-1" /></Link>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="blog-article-item">
                                        <div className="article-thumb">
                                            <Link to='/blog-details'>
                                                <img className="lazyload" data-src="/assets/images/blog/blog-8.jpg" src="/assets/images/blog/blog-8.jpg" alt="img-blog" />
                                            </Link>
                                            <div className="article-label">
                                                <Link to='/blog-details' className="tf-btn btn-sm radius-3 btn-fill animate-hover-btn">Design</Link>
                                            </div>
                                        </div>
                                        <div className="article-content">
                                            <div className="article-title">
                                                <Link to='/blog-details' >Something About This Style Of Jeans</Link>
                                            </div>
                                            <div className="article-btn">
                                                <Link to='/blog-details' className="tf-btn btn-line fw-6">Read more<i className="icon fa-solid fa-arrow-right px-1" /></Link>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="blog-article-item">
                                        <div className="article-thumb">
                                            <Link to='/blog-details'>
                                                <img className="lazyload" data-src="/assets/images/blog/blog-9.jpg" src="/assets/images/blog/blog-9.jpg" alt="img-blog" />
                                            </Link>
                                            <div className="article-label">
                                                <Link to='/blog-details' className="tf-btn btn-sm radius-3 btn-fill animate-hover-btn">Tech</Link>
                                            </div>
                                        </div>
                                        <div className="article-content">
                                            <div className="article-title">
                                                <Link to='/blog-details' >One Of The Most Iconic Fashion Bloggers</Link>
                                            </div>
                                            <div className="article-btn">
                                                <Link to='/blog-details' className="tf-btn btn-line fw-6">Read more<i className="icon fa-solid fa-arrow-right px-1" /></Link>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default BlogDetails