import React from 'react'

function NewArrivals() {
    return (
        <>
            <section className='wrapper-section narrival-section'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className='sub-Title'>
                                <div className='small-width'></div>
                                <p>Featured</p>
                            </div>
                            <ul>
                                <li>
                                    <h4 className="Title">New Arrivals</h4>
                                </li>
                                <li>
                                    <a href="" className="viewAllBtn flex">
                                        <span>See All</span>
                                        {/* <i className="fa-solid fa-arrow-right-long arrowRight" /> */}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row mt-5 align-items-center">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <a href="">
                                <div className="narrival-left-side">
                                    <div className='discount-div'>
                                        <span className="green-badge">New</span>
                                    </div>

                                    <img src="/assets/images/new-Arrivals/Arrivals-1.png" alt="Narrival" />
                                    <div className='narrival-content'>
                                        <h4>Casual Home dress</h4>
                                        <p>Home Comfortable dress available in multiple colors.</p>
                                        <a href="">Shop Now</a>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="narrival-right-side">
                                <div className="row">
                                    <div className="col-12">
                                        <a href="">
                                            <div className='narrival-right-side-inner'>
                                                <div className='discount-div'>
                                                    <span className="yellow-badge">30% OFF</span>
                                                </div>
                                                <img src="/assets/images/new-Arrivals/Arrivals-2.png" alt="Narrival-2" />
                                                <div className='narrival-content'>
                                                    <h4>Women’s Collections</h4>
                                                    <p>Featured woman collections that give you another vibe.</p>
                                                    <a href="">Shop Now</a>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col-6">
                                        <a href="">
                                            <div className=' narrival-right-side-inner-small'>
                                                <img src="/assets/images/new-Arrivals/Arrivals-3.png" alt="Narrival-2" />
                                                <div className='narrival-content-small'>
                                                    <h4>Fashion</h4>
                                                    <p>Casual Dress</p>
                                                    <a href="">Shop Now</a>
                                                </div>
                                            </div>
                                        </a>
                                    </div>

                                    <div className="col-6">
                                        <a href="">
                                            <div className=' narrival-right-side-inner-small'>
                                                <img src="/assets/images/new-Arrivals/Arrivals-4.png" alt="Narrival-2" />
                                                <div className='narrival-content-small'>
                                                    <h4>Fashion</h4>
                                                    <p>Casual Dress</p>
                                                    <a href="">Shop Now</a>
                                                </div>
                                            </div>
                                        </a>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default NewArrivals
