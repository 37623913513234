import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import "./assets/css/auth.css";
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from "yup";
import { email } from '../helpers/yup.validation.schema';
import { useFormik } from 'formik';
import { forgotPassword } from '../middlewares/auth';
import ErrorList from '../Components/error-list/ErrorList';
import ToastService from "../helpers/toast-services";
import ROUTE_URLS from '../config/routes';
import { forgetPasswordReset } from '../slices/auth.slice';


const Forgotpassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        forgotPasswordError,
        forgotPasswordLoading,
        forgotPasswordMessage,
    } = useSelector((state) => state.auth);

    useEffect(() => {
        if (!forgotPasswordLoading) {
            if (forgotPasswordMessage) {
                ToastService.success(forgotPasswordMessage);
                navigate(ROUTE_URLS.VERIFY_FORGET_CODE, { replace: true });
                dispatch(forgetPasswordReset());
            }
        }
    }, [forgotPasswordMessage, forgotPasswordLoading]);

    const handleSubmit = (values) => {
        const payload = {
            email: values.email,
        }
        dispatch(forgotPassword(payload));
    }

    const forgetSchema = Yup.object({
        email: email,
    });

    const forgetFormik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: forgetSchema,
        onSubmit: handleSubmit,
    });

    return (
        <>
            <div className='indiazona-login'>
                <div className='container right-panel-active' id="container">
                    <div className="form-container sign-up-container">
                        <form onSubmit={forgetFormik.handleSubmit} className='CreateAccount-Form'>
                            <h4 className='mb-4'>Forgot Your Password</h4>
                            <div className="mt-2">
                                {forgotPasswordError && <ErrorList error={forgotPasswordError} />}
                            </div>
                            <div className='password-input mb-2'>
                                <input className='m-0' type="email" name='email' value={forgetFormik.values.email} placeholder="Email"
                                    onChange={forgetFormik.handleChange}
                                    onBlur={forgetFormik.handleBlur} />
                                {forgetFormik.touched.email && forgetFormik.errors.email && (
                                    <div className="error"> {forgetFormik.errors.email}</div>
                                )}
                            </div>
                            <button className='my-2 common-btn Login-btn w-100' type='submit' disabled={forgotPasswordLoading}>{forgotPasswordLoading ? 'Processing...' : 'Send OTP'}</button>
                            <Link to='/login' className='py-2'>Back to login.</Link>
                        </form>
                    </div>
                    <div className="overlay-container">
                        <div className="overlay">
                            <div className="overlay-panel overlay-left">
                                <div className="india-zona-login">
                                    <img src="/assets/images/svg-img/logo.svg" alt="logo" />
                                </div>
                                <p className='py-2'>To keep connected with us please enter email for forgot your password!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Forgotpassword