import { get, post } from ".";

const URI = "/doodle-show";

const getDoodle = (payload) => {
    const URL = `${URI}`;
    return get(URL, payload);
};

const DoodleService = {
    getDoodle,
}
export default DoodleService;

