import React from 'react'
import SideBarManu from './SideBarManu'
import Footer from '../../Components/Common/Footer'
import { Link } from 'react-router-dom'

const OrderDetails = () => {
    return (
        <>
            <section className="page-title">
                <div className="pattern-layer"></div>
                <div className="container-full">
                    <div className="content-box">
                        <h3>Personal Information</h3>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item active" aria-current="page"><Link to='/'>Home</Link></li>
                                <li className="breadcrumb-item" aria-current="page">/</li>
                                <li className="breadcrumb-item" aria-current="page">Order Details</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </section>
            <section className="profile-section profile-wrapper">
                <div className="container">
                    <div className="row">
                        <SideBarManu />
                        <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-8 account-wrapper">
                            <div className="account-card order-details">
                                <div className="order-head">
                                    <div className="head-thumb">
                                        <img src="./assets/images/Product/p-1.png" alt="product img" />
                                    </div>
                                    <div className="clearfix m-l20">
                                        <div className="badge">In Progress</div>
                                        <h4 className="mb-0">Order #17493</h4>
                                    </div>
                                </div>
                                <div className="row mb-sm-4 mb-2">
                                    <div className="col-sm-6">
                                        <div className="shiping-tracker-detail">
                                            <span>Item</span>
                                            <h6><b>Casual Shirt</b></h6>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="shiping-tracker-detail">
                                            <span>Courier</span>
                                            <h6><b>Casual Shirt</b></h6>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="shiping-tracker-detail">
                                            <span>Start Time</span>
                                            <h6><b>05 April 2024, 15:43:23</b></h6>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="shiping-tracker-detail">
                                            <span>Address</span>
                                            <h6><b>Address 451 Wall Street UK, London</b></h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="content-btn m-b15">
                                    {/* <Link to="/wishlist" className="btn btn-secondary me-xl-3 me-2 m-b15 btnhover20">Export Details</Link>
                                    <Link to="" className="btn btn-outline-secondary m-b15 me-xl-3 me-2 btnhover20">Request Confirmation</Link> */}
                                    <Link to="" className="btn btn-outline-danger m-b15 btnhover20">Cancel Order</Link>
                                </div>
                                <div className="clearfix">
                                    <div className="dz-tabs style-3">
                                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                            <button className="nav-link active" id="nav-order-history-tab" data-bs-toggle="tab" data-bs-target="#nav-order-history" role="tab" aria-controls="nav-order-history" aria-selected="true">Order History</button>
                                            <button className="nav-link" id="nav-Item-tab" data-bs-toggle="tab" data-bs-target="#nav-Item" role="tab" aria-controls="nav-Item" aria-selected="false" tabIndex={-1}>Item Details</button>
                                            <button className="nav-link" id="nav-courier-tab" data-bs-toggle="tab" data-bs-target="#nav-courier" role="tab" aria-controls="nav-courier" aria-selected="false" tabIndex={-1}>Courier</button>
                                            <button className="nav-link" id="nav-receiver-tab" data-bs-toggle="tab" data-bs-target="#nav-receiver" role="tab" aria-controls="nav-receiver" aria-selected="false" tabIndex={-1}>Receiver</button>
                                        </div>
                                    </div>
                                    <div className="tab-content" id="nav-tabContent">
                                        <div className="tab-pane fade show active" id="nav-order-history" role="tabpanel" aria-labelledby="nav-order-history-tab" tabIndex={0}>
                                            <div className="widget-timeline style-1">
                                                <ul className="timeline">
                                                    <li>
                                                        <div className="timeline-badge success" />
                                                        <div className="timeline-box">
                                                            <a className="timeline-panel" href="">
                                                                <h6 className="mb-1">Product Shiped</h6>
                                                                <span>08/04/2024 5:23pm</span>
                                                            </a>
                                                            <p><strong>Courier Service : </strong>UPS, R. Gosling</p>
                                                            <p><strong>Estimated Delivery Date : </strong>09/04/2024</p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="timeline-badge primary" />
                                                        <div className="timeline-box">
                                                            <a className="timeline-panel" href="">
                                                                <h6 className="mb-0">Product Shiped</h6>
                                                                <span>08/04/2024 5:23pm</span>
                                                            </a>
                                                            <p><strong>Tracking Number : </strong>3409-4216-8759</p>
                                                            <p><strong>Warehouse : </strong>Top Shirt 12b</p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="timeline-badge" />
                                                        <div className="timeline-box">
                                                            <a className="timeline-panel" href="">
                                                                <h6 className="mb-0">Product Packaging</h6>
                                                                <span>09/04/2024 4:34pm</span>
                                                            </a>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="timeline-badge" />
                                                        <div className="timeline-box">
                                                            <a className="timeline-panel" href="">
                                                                <h6 className="mb-0">Order Palced</h6>
                                                                <span>10/04/2024 2:36pm</span>
                                                            </a>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="nav-Item" role="tabpanel" aria-labelledby="nav-Item-tab" tabIndex={0}>
                                            <h5>Item Details</h5>
                                            <div className="tracking-item">
                                                <div className="tracking-product"><img src="./assets/images/Product/HJ-5.png" /></div>
                                                <div className="tracking-product-content">
                                                    <h6><b>Collar Casual Shirt</b></h6>
                                                    <small className="d-block"><strong>Price</strong> : ₹ 150</small>
                                                    <small className="d-block"><strong>Size</strong> : Xl</small>
                                                </div>
                                            </div>
                                            <div className="tracking-item-content">
                                                <span>Total Price</span>
                                                <h6>+ ₹150</h6>
                                            </div>
                                            <div className="tracking-item-content border-bottom border-light mb-2">
                                                <span className="text-success">Total Discounts</span>
                                                <h6>- ₹55</h6>
                                            </div>
                                            <div className="tracking-item-content">
                                                <span>Order Total</span>
                                                <h6>₹95</h6>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="nav-courier" role="tabpanel" aria-labelledby="nav-courier-tab" tabIndex={0}>
                                            <p>Commyolk Suspendisse et justo. Praesent mattis augue Aliquam ornare hendrerit augue Cras tellus In pulvinar lectus a est Curabitur eget orci Cras laoreet. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Suspendisse et justo. Praesent mattis commyolk augue aliquam ornare.</p>
                                        </div>
                                        <div className="tab-pane fade" id="nav-receiver" role="tabpanel" aria-labelledby="nav-receiver-tab" tabIndex={0}>
                                            <h5 className="text-success mb-4">Thank you Your order has been received</h5>
                                            <ul className="tracking-receiver">
                                                <li>Order Number : <strong>#17493</strong></li>
                                                <li>Date : <strong>17/04/2024, 02:34pm</strong></li>
                                                <li>Total : <strong>₹95</strong></li>
                                                <li>Payment Methods : <strong>Cash on Delivery</strong></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OrderDetails