import * as Yup from "yup";
export const Password = Yup.string().required("Password is required");

export const Pass = Yup.string()
  .min(6, "Password is short")
  .max(50, "Password is long")
  .required("Required");

export const ConfirmPassword = Yup.string()
  .min(6, "Password is short")
  .max(50, "Password is long")
  .oneOf([Yup.ref("password"), null], "Password doesn't match")
  .required("Required");

export const con_password = Yup.string()
  .min(6, "Password is short")
  .max(50, "Password is long")
  .oneOf([Yup.ref("new_password"), null], "Password doesn't match")
  .required("Required");

export const email = Yup.string()
  .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g, "Invalid email")
  .required("Required");
export const name = Yup.string().required("Required");

export const otp = Yup.string()
  .length(6, "OTP must be exactly 6 digits")
  .required("Required");

export const phone_number = Yup.string()
  .required("Required")
  .matches(/^\+?\d{10,14}$/, "Phone number is not valid")


//profile update validation

export const validAddress = Yup.string()
  .required('Address is required')
  .min(5, 'Address must be at least 5 characters long')
export const country_id = Yup.string()
  .required('Country is required')
export const state_id = Yup.string()
  .required('State is required')
export const city_id = Yup.string()
  .required('City is required')

export const postal_code = Yup.string()
  .matches(/^\d{6}$/, 'Pincode is not valid')
  .required('Postal code is required')

export const phone = Yup.string()
  .matches(/^\+?\d{10,14}$/, "Phone number is not valid")
export const profilPassword = Yup.string()
export const ProfileConfirmPassword = Yup.string()
  .min(6, "Password is short")
  .max(50, "Password is long")
  .oneOf([Yup.ref("password"), null], "Password doesn't match")