import { post } from ".";

const URI = "/all-search";

const searchProduct = (payload) => {
    const URL = `${URI}`;
    return post(URL, payload);
}

export const SearchService = {
    searchProduct,
}