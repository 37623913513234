import { createSlice } from "@reduxjs/toolkit";
import { PRODUCTS } from "../helpers/slice.name";


export const productSlice = createSlice({
    name: PRODUCTS,
    initialState: {
        loading: false,

        productListLoading: false,
        productsList: [],
        productListMessage: "",
        productListError: "",

        productDetailsLoading: false,
        productDetails: {},
        productDetailsMessage: "",
        productDetailsError: "",

        todayDealLoading: false,
        todayDeal: [],
        todayDealMessage: "",
        todayDealError: "",
    },
    reducers: {
        productListRequest: (state, action) => {
            state.productListLoading = true;
            state.productListMessage = "";
            state.productListError = "";
        },
        productListSuccess: (state, action) => {
            state.productListLoading = false;
            state.productsList = action.payload.data;
            state.productListMessage = action.payload.message;
        },
        productListError: (state, action) => {
            state.productListLoading = false;
            state.productsList = action.payload.data;
            state.productListError = action.payload.message;
        },

        productDetailsRequest: (state) => {
            state.productDetailsLoading = true;
            state.productDetailsMessage = "";
            state.productDetailsError = "";
        },
        productDetailsSuccess: (state, action) => {
            state.productDetailsLoading = false;
            state.productDetails = action.payload.data;
            state.productDetailsMessage = action.payload.message;
        },
        productDetailsError: (state, action) => {
            state.productDetailsLoading = false;
            state.productDetailsError = action.payload;
        },

        todayDealRequest: (state) => {
            state.todayDealLoading = true;
            state.todayDealMessage = "";
            state.todayDealError = "";
        },
        todayDealSuccess: (state, action) => {
            state.todayDealLoading = false;
            state.todayDeal = action.payload.data;
            state.todayDealMessage = action.payload.message;
        },
        todayDealError: (state, action) => {
            state.todayDealLoading = false;
            state.todayDealError = action.payload;
        }
    }
});

export const {
    productListRequest,
    productListSuccess,
    productListError,
    productDetailsRequest,
    productDetailsSuccess,
    productDetailsError,

    todayDealRequest,
    todayDealSuccess,
    todayDealError
} = productSlice.actions;

export default productSlice.reducer;