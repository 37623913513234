import React from 'react'
import ProductDetails from '../Components/Products/ProductDetails';
import HomeProductSlider from '../Components/Products/HomeProductSlider';
import ShippingSection from '../Components/Products/ShippingSection';
import OtherProductsSlider from '../Components/Products/OtherProductsSlider';
import ProductListBreadcrumb from '../Components/Products/ProductListBreadcrumb';

const ProductDetailsPage = () => {

    return (
        <div>
            <main>
                <section className="details-wrapper-listing-section">
                    <div className="container-fluid">
                        <div className="row">
                            <ProductListBreadcrumb />
                        </div>
                    </div>
                </section>
                <ProductDetails />
                {/* <HomeProductSlider title={'Similar Products'} /> */}
                <ShippingSection />
                {/* <OtherProductsSlider name={'Bought Together'} /> */}
            </main>
        </div>
    )
}

export default ProductDetailsPage;