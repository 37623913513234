import React, { useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useState } from 'react';
// import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useDispatch } from 'react-redux';
import Skeleton from 'react-loading-skeleton';

const SideBarManu = () => {
    const navigate = useNavigate()
    const IsLogedin = localStorage.getItem('IN_USER_DATA')
    const userDetails = JSON.parse(IsLogedin)
    // useEffect(() => {
    //     !IsLogedin ? navigate('/') : <></>
    // }, [IsLogedin])

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const logout = () => {
        localStorage.clear('IN_USER_DATA')
        navigate('/')
    }
    return (
        <>
            <aside className="col-xxl-3 col-xl-3 col-lg-3 col-md-4  sticky-top profile-account-sidebar-wrapper">
                <div className="toggle-info">
                    <h5 className="title mb-0">Account Menu</h5>
                    <a className="toggle-btn active" href="#" variant="primary" onClick={handleShow}>Account Menu</a>
                </div>
                <div className="account-sidebar-wrapper">
                    <div className="account-sidebar" id="accountSidebar">
                        <div className="profile-head">
                            <div className="user-thumb">
                                {userDetails ? (
                                    <img className="Edit-profile" src={userDetails?.avatar_original ? userDetails?.avatar_original : ''} alt={userDetails?.avatar_original} />
                                ) : (
                                    <Skeleton circle={true} height={122} />
                                )}
                            </div>
                            <h5 className="user-title mb-0">{userDetails ? userDetails?.name : <Skeleton height={50} />}</h5>
                            <span className="text desh-text">{userDetails?.email}</span>
                        </div>
                        <div className="account-nav">
                            <ul>
                                <li>
                                    <NavLink
                                        to='/profile'
                                        className={({ isActive }) => isActive ? 'menu__img active' : 'menu__img'}
                                    >
                                        Profile
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to='/user-dashboard'
                                        className={({ isActive }) => isActive ? 'menu__img active' : 'menu__img'}
                                    >
                                        Dashboard
                                    </NavLink>
                                </li>
                                <hr style={{ height: "2px", color: "gray", 'backgroundColor': "gray" }} />
                                <li>
                                    <NavLink
                                        to='/user-orders'
                                        className={({ isActive }) => isActive ? 'menu__img active' : 'menu__img'}
                                    >
                                        Orders
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to='/user-address'
                                        className={({ isActive }) => isActive ? 'menu__img active' : 'menu__img'}
                                    >
                                        Address
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to='/wishlist'
                                        className={({ isActive }) => isActive ? 'menu__img active' : 'menu__img'}
                                    >
                                        Wishlists
                                    </NavLink>
                                </li>
                                <hr style={{ height: "2px", color: "gray", 'backgroundColor': "gray" }} />
                                <li>
                                    <button
                                        to='/wishlist'
                                        className='btn desh-btn'
                                        onClick={logout}
                                    >
                                        LogOut
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </aside>
            {/* offcanvas for mobile users */}
            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Account Menu</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="account-nav">
                        <ul>
                            <li>
                                <NavLink
                                    to='/profile'
                                    className={({ isActive }) => isActive ? 'menu__img active' : 'menu__img'}
                                >
                                    Profile
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to='/user-dashboard'
                                    className={({ isActive }) => isActive ? 'menu__img active' : 'menu__img'}
                                >
                                    Dashboard
                                </NavLink>
                            </li>
                            <hr style={{ height: "2px", color: "gray", 'backgroundColor': "gray" }} />
                            <li>
                                <NavLink
                                    to='/user-orders'
                                    className={({ isActive }) => isActive ? 'menu__img active' : 'menu__img'}
                                >
                                    Orders
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to='/user-address'
                                    className={({ isActive }) => isActive ? 'menu__img active' : 'menu__img'}
                                >
                                    Address
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to='/wishlist'
                                    className={({ isActive }) => isActive ? 'menu__img active' : 'menu__img'}
                                >
                                    Wishlists
                                </NavLink>
                            </li>
                            <hr style={{ height: "2px", color: "gray", 'backgroundColor': "gray" }} />
                            <li>
                                <button
                                    to='/wishlist'
                                    className='btn desh-btn'
                                    onClick={logout}
                                >
                                    LogOut
                                </button>
                            </li>
                        </ul>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
            {/* offcanvas for mobile users */}
        </>
    )
}

export default SideBarManu