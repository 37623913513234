import React from 'react'
import { Link } from 'react-router-dom'
import './landingpage.css'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { SELLER_ADD, SELLER_LOGIN } from '../../BaseUrl'

const LandingPage = () => {
  return (
    <>
      <header className="landing-header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6 col-6">
              <div className="header-logo py-2">
                <div className="logo-inner">
                  <Link className="navbar-brand d-md-block d-lg-block " to="/landing-page">
                    <img src="/assets/images/svg-img/logo.svg" alt="Logo" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-6">
              <div className="social-icon">
                <ul className="social_link list-inline mb-0 h-100 d-flex align-items-center">
                  <p className='d-lg-block d-md-block d-none'>Follow @indiazonafamily</p>
                  <li><a href="https://www.facebook.com/indiazonafamily" target='_blank'><i className="fa-brands fa-facebook" style={{ color: "#1c67fa", fontSize: '20px' }} /></a></li>
                  <li><a href="https://x.com/indiazonafamily" target='_blank'><i className="fa-brands fa-x-twitter" style={{ color: "#0c0d0d", fontSize: '20px' }} /></a></li>
                  <li><a href="https://www.instagram.com/indiazonafamily/" target='_blank'><img src='/assets/images/svg-img/instagram.svg' /></a></li>
                  {/* <li><a href="#"><i className="fa-brands fa-youtube" style={{ color: "#ff131b", fontSize: '20px' }}></i> </a></li> */}
                  <li><a href="https://www.linkedin.com/company/indiazonafamily/" target='_blank'><i className="fa-brands fa-linkedin" style={{ color: "#1467be", fontSize: '20px' }} /></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section className="landing-banner">
        <div className="container">
          <div className="row">
            <div className="col-xxl-7 col-xl-7 col-lg-12 col-md-12 col-sm-12">
              <div className="landing-banner-inner">
                <h1>Vendor Registration In Progress</h1>
                <div className='banner-button d-flex gap-2 justify-content-center align-items-center'>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <a className='btn w-100' href={SELLER_ADD} target='_blank'>Become a Seller</a>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <a className='btn w-100' href={SELLER_LOGIN} target='_blank'>Login to Seller</a>
                    </div>
                  </div>
                </div>
                <h6 className='mb-lg-2 mb-md-2 mb-3'>Launching soon for customers</h6>
                <div className='row justify-content-center'>
                  <div className="d-flex col-lg-2 col-md-2 col-6">
                    <Link className='btn landing-page-btn w-100 mb-0' to='/home'>Demo</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-5 col-xl-5 col-lg-12 col-md-12 col-sm-12">
              <div className="row ">
                <div className="col-xxl-12 col-xl-12 col-md-6 col-lg-6 col-sm-12">
                  <div className="landing-banner-inner-orange">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="landing-banner-inner-orange-content">
                          <h5 className=" text-white mb-3 text-center">About Indiazona</h5>
                          <p className='text-white text-justify'>Indiazona- Bharat ki Apni Dukaan is a pivot to empower Indian Businesses, an e-commerce marketplace bringing Made in India, Handmade & Sustainable products to the forefront.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-12 col-xl-12 col-md-6 col-lg-6 col-sm-12">
                  <div className="product-Handmade-green">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="Product-name ">
                          <h5 className=" text-white mb-3 text-center" >Contact Us</h5>
                          <h6 className='text-white mb-2 text-center'>Phone : <b><a href='tel: +918374320725'>+91 8374320725</a></b></h6>
                          <h6 className='text-white mb-2 text-center'>Email : <b><a href="mailto:care@indiazona.com" target='_blank'>care@indiazona.com</a></b></h6>
                          <p className='text-white text-center'>11, Clive Row, 4th Floor,Kolkata, India - 700001</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default LandingPage