import { get, post } from ".";

const URI = "/categories";

const getHomeCategories = (payload) => {
    const URL = `${URI}/homeCategories`;
    return get(URL, payload);
};

const CategoryService = {
    getHomeCategories,
}

export default CategoryService;