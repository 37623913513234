import { createSlice } from "@reduxjs/toolkit";
import { ORDER } from '../helpers/slice.name';

const orderSlice = createSlice({
    name: ORDER,
    initialState: {

        createOrderLoading: false,
        createOrder: {},
        createOrderMessage: '',
        createOrderError: '',

        getOrderHistoryLoading: false,
        getOrderHistory: [],
        getOrderHistoryMessage: '',
        getOrderHistoryError: '',
    },
    reducers: {
        createOrderRequest: (state, action) => {
            state.createOrderLoading = true
            state.createOrderMessage = ''
            state.createOrderError = ''
        },
        createOrderSuccess: (state, action) => {
            state.createOrderLoading = false
            state.createOrder = action.payload.data
            state.createOrderMessage = action.payload.message
        },
        createOrderError: (state, action) => {
            state.createOrderLoading = false
            state.createOrderError = action.payload.message
        },
        createOrderReset: (state, action) => {
            state.createOrderMessage = ''
            state.createOrderError = ''
        },

        getOrderHistoryRequest: (state, action) => {
            state.getOrderHistoryLoading = true
            state.getOrderHistoryMessage = ''
            state.getOrderHistoryError = ''
        },
        getOrderHistorySuccess: (state, action) => {
            state.getOrderHistoryLoading = false
            state.getOrderHistory = action.payload.data
            state.getOrderHistoryMessage = action.payload.message
        },
        getOrderHistoryError: (state, action) => {
            state.getOrderHistoryLoading = false
            state.getOrderHistoryError = action.payload.message
        },
        getOrderHistoryReset: (state, action) => {
            state.getOrderHistoryMessage = ''
            state.getOrderHistoryError = ''
        }
    }
})

export const {
    createOrderRequest,
    createOrderSuccess,
    createOrderError,
    createOrderReset,

    getOrderHistoryRequest,
    getOrderHistorySuccess,
    getOrderHistoryError
} = orderSlice.actions
export default orderSlice.reducer;