import { post } from ".";


//otp verify
const otpVerify = (payload) => {
    const URL = `/auth/resend-otp`;
    return post(URL, payload);
};

export const otpservice = {
    otpVerify
}

export default otpservice;