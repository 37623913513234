import React, { useState } from 'react'
import Header from '../Components/Common/Header';
import { Outlet } from 'react-router-dom';
import Footer from '../Components/Common/Footer';

const CartLayout = () => {

    const [mobileMenu, setMobileMenu] = useState(false);
    const handleMobileMenuClick = () => {
        setMobileMenu(!mobileMenu);
    };

    const hearderMobile = (onClick) => (
        <li className="mobile-menu-trigger" onClick={onClick}>
            <span></span>
        </li>
    );

    return (
        <>
            <Header hearderMobile={hearderMobile} onClick={handleMobileMenuClick} />
            <Outlet />
            {/* <Footer /> */}
        </>
    )
}

export default CartLayout