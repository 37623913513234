import { get, post, postFormData } from ".";

const getCountries = (payload) => {
    const URL = `/countries`;
    return get(URL, payload);
};
const getStates = (countryId, payload) => {
    return get(`/states-by-country/${countryId}`, payload);
};

const getCities = (stateId, payload) => {
    return get(`/cities-by-state/${stateId}`, payload);
};
const getAddresses = () => {
    return get(`/user/shipping/address`);
};

const addAddress = (payload) => {
    return postFormData(`/user/shipping/create`, payload);
};
const DeleteAddress = (payload) => {
    return post(`/user/shipping/delete`, payload);
};
const updateAddress = (payload) => {
    return postFormData(`/user/shipping/update`, payload);
};

const setPrimaryAdd = (payload) => {
    return post(`/user/shipping/make_default`, payload)
}

export const addressservice = {
    getCountries,
    getStates,
    getCities,
    getAddresses,
    setPrimaryAdd,
    addAddress,
    updateAddress,
    DeleteAddress
}

export default addressservice;