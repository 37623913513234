import React, { useState } from 'react'
import CompareModel from '../Model/CompareModel';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const OtherProductsSlider = () => {

    const [showCompare, setShowCompare] = useState(false);
    const { homeCategory, homeCategoryLoading } = useSelector((state) => state.category);
    const navigate = useNavigate();
    const catArray = homeCategory?.filter((item) => item.product.data.length > 0).map(o => {
        let prod = o.product.data.filter(o => o.indiazona_price !== 0)
        if (prod.length > 6) {
            prod = prod.slice(0, 6)
        }
        return { ...o, product: prod }
    }).filter((item) => item.product.length > 0) || [];

    const handleShowCompare = (e) => {
        e.preventDefault();
        setShowCompare(true);
    }
    const handleHideCompare = () => setShowCompare(false);

    return (
        <section className="wrapper-section product sarees&kurti">
            {catArray.length > 0 && catArray.map((o, i) => {
                return (
                    <div className="container-fluid mt-lg-5 mt-md-4 mt-4" key={i}>
                        <div className="row">
                            <div className="col-12">
                                <ul>
                                    <li>
                                        <h4 className="Title">{o.short_name}</h4>
                                    </li>
                                    <li>
                                        {/* <a href={`${o.name.replace(" ", "_")}/product-list`} className='viewAllBtn flex'> */}
                                        <a href={`${o.slug}/product-list`} className='viewAllBtn flex'>
                                            <span>See All</span>
                                            {/* <i className="fa-solid fa-arrow-right-long arrowRight" /> */}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="colthing-wrapper product-default">
                            <div className="row">
                                <div className="col-12">
                                    <div className="product-default-inner row">
                                        {o.product.map((e, i) =>
                                            <div className="product-default-card col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-6" key={i}>
                                                <a href={`/${o?.slug}/product-details/${e?.id}`} target='_blank' rel="noopener noreferrer">
                                                    <div className="product-box shop-card">
                                                        <div className="product-inner-box">
                                                            <div className="product-img-box">
                                                                <LazyLoadImage src={e.photos[0]} alt={`pro-0`} effect="blur" />
                                                            </div>
                                                            {/* <div className="shop-meta">
                                                    <ul>
                                                        <li>
                                                            <i className="far fa-heart" />
                                                        </li>
                                                        <li>
                                                            <i className="fa-solid fa-share" />
                                                        </li>
                                                        <span onClick={handleShowCompare}>
                                                            <li>
                                                                <i className="fas fa-exchange-alt" />
                                                            </li>
                                                        </span>
                                                    </ul>
                                                </div> */}
                                                        </div>
                                                        <div className="product-content">
                                                            <h6>{e.name}</h6>
                                                            <p>{e.proDesc}</p>
                                                            <div className="flex gap-4 mt-1 mb-1 aligin-items-center md-gap-2">
                                                                <h6>
                                                                    <del style={{ fontSize: 12, color: '#a6a6a6' }}>₹{e.tag_price}</del>
                                                                    <span style={{ fontSize: 13, padding: '2px 6px', }}>₹{e.indiazona_price}</span>
                                                                    <span className="off-span" style={{ borderLeft: '1px solid #000', padding: '2px 6px', fontSize: '13', color: 'green' }}>({e.discount}%)</span>
                                                                </h6>
                                                            </div>
                                                            <a className="shopNowBtn" href={`/${o?.slug}/product-details/${e?.id}`}>
                                                                Shop Now
                                                            </a>
                                                        </div>

                                                    </div>
                                                </a>
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>)
            })}
            <CompareModel showCompare={showCompare} handleHideCompare={handleHideCompare} />
        </section >
    )
}

export default OtherProductsSlider