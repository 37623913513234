import { createSlice } from "@reduxjs/toolkit";
import { COLOR } from "../helpers/slice.name";

export const colorSlice = createSlice({
    name: COLOR,
    initialState: {
        loading: false,

        colorLoading: false,
        colors: [],
        colorMessage: "",
        colorError: "",
    },

    reducers: {
        colorRequest: (state) => {
            state.colorLoading = true;
            state.colorMessage = "";
            state.colorError = "";
        },
        colorSuccess: (state, action) => {
            state.colorLoading = false;
            state.colors = action.payload.data;
            state.colorMessage = action.payload.message;
        },
        colorError: (state, action) => {
            state.colorLoading = false;
            state.colorError = action.payload;
        },
    }
});

export const { colorRequest, colorSuccess, colorError } = colorSlice.actions;

export default colorSlice.reducer;
