import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const PageNotFound = () => {

  const navigate = useNavigate();
  return (
    <div className="page-not-found">
      <div className="container">
        <div className="content-wrapper">
          <img src='/assets/images/not-found.jpg' alt="Page Not Found" className="not-found-image" />
          <div className="text-content">
            <h2>Oops...That link is broken.</h2>
            <p>Sorry for the inconvenience. Go to our homepage to check out our latest collections.</p>
          </div>
        </div>
        <div className="row mt-4">
          <div className="center">
            <button className="page-btn" onClick={() => navigate('/')}>
              <svg width="180px" height="65px" viewBox="0 0 180 65">
                <polyline points="179,1 179,59 1,59 1,1 179,1" />
              </svg>
              <span>Back To Homepage</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageNotFound