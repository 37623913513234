import AuthService from "../services/auth";
import LocalstorageService from "../helpers/localstorage-services";
import {
  loginError,
  loginRequest,
  loginSuccess,
  forgotPasswordRequest,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordError,
  setLoggedInUser,
  logoutRequest,
  logoutSuccess,
  logoutError,
  signUpRequest,
  signUpSuccess,
  signUpError,
  emailVerificationSuccess,
  emailVerificationError,
  emailVerificationRequest,
  resendCodeRequest,
  resendCodeSuccess,
  resendCodeError,
  sendMobileOtpRequest,
  sendMobileOtpSuccess,
  sendMobileOtpError,
  verifyMobileOtpRequest,
  verifyMobileOtpSuccess,
  verifyMobileOtpError,

  verifyEmailCodeRequest,
  verifyEmailCodeSuccess,
  verifyEmailCodeError,
} from "../slices/auth.slice";

export function LoginUser(payload) {
  return (dispatch) => {
    dispatch(loginRequest());
    AuthService.login(payload)
      .then((response) => {
        const { result, access_token, user = {}, message } = response.data;
        if (result === true) {
          
          const localStorage = { token: access_token, user: user };
          LocalstorageService.loginUser(localStorage);
          dispatch(loginSuccess({ userObj: user, message }));
          dispatch(setLoggedInUser(payload.email));
        } else {
          dispatch(loginError(message));
        }
      })
      .catch((error) => {
        dispatch(loginError(error));
      });
  };
}

export function SignUp(payload) {
  return (dispatch) => {
    dispatch(signUpRequest());
    AuthService.signUp(payload)
      .then((response) => {
        const { result, access_token, user = {}, message } = response.data;
        if (result === true) {
          const localStorage = { token: access_token, user: user };
          LocalstorageService.loginUser(localStorage);
          dispatch(signUpSuccess({ userObj: user, message }));
          dispatch(setLoggedInUser(payload.email_or_phone));
        } else {
          dispatch(signUpError(message));
        }
      })
      .catch((error) => {
        dispatch(signUpError(error));
      });
  };
}

export function forgotPassword(payload) {
  return (dispatch) => {
    dispatch(forgotPasswordRequest());
    AuthService.forgotPassword(payload)
      .then((response) => {
        const { status, data, message, error } = response.data;
        if (status === 1) {
          localStorage.setItem('IN_EMAIL', payload.email)
          dispatch(forgotPasswordSuccess({ data, message }));
        } else {
          dispatch(forgotPasswordError(error));
        }
      })
      .catch((error) => {
        dispatch(forgotPasswordError(error));
      });
  };
}

export function emailCodeVerification(payload) {
  return (dispatch) => {
    dispatch(verifyEmailCodeRequest());
    AuthService.verifyEmailCode(payload)
      .then((response) => {
        const { status, data, message, error } = response.data;
        if (status === 1) {
          dispatch(verifyEmailCodeSuccess({ data, message }));
        } else {
          dispatch(verifyEmailCodeError(error));
        }
      })
      .catch((error) => {
        dispatch(verifyEmailCodeError(error));
      });
  }
}


export function resetPassword(payload, link) {
  return (dispatch) => {
    dispatch(resetPasswordRequest());
    AuthService.resetPassword(payload, link)
      .then((response) => {
        const { status, message, error } = response.data;
        if (status === 1) {
          dispatch(resetPasswordSuccess(message));
        } else {
          dispatch(resetPasswordError(error));
        }
      }).catch((error) => {
        dispatch(resetPasswordError(error));
      });
  };
}

export function logoutUser() {
  return (dispatch) => {
    dispatch(logoutRequest());
    AuthService.logout()
      .then((response) => {
        const { status, message, error } = response.data;
        if (status === 1) {
          dispatch(logoutSuccess(message));
        } else {
          dispatch(logoutError(error));
        }
      })
      .catch((error) => {
        dispatch(logoutError(error));
      });
  };
}

export function verifyUserEmail(payload) {
  return (dispatch) => {
    dispatch(emailVerificationRequest());
    AuthService.verifyEmail(payload)
      .then((response) => {
        const { result, message } = response.data;
        if (result === true) {
          dispatch(emailVerificationSuccess(message));
        } else {
          dispatch(emailVerificationError(message));
        }
      })
      .catch((error) => {
        dispatch(emailVerificationError(error));
      });
  };
}

export function resendCodeWhileVerify(payload) {
  return (dispatch) => {
    dispatch(resendCodeRequest());
    AuthService.resendCode(payload)
      .then((response) => {
        const { result, message } = response.data;
        if (result === true) {
          // console.log(result, message);
          dispatch(resendCodeSuccess(message));
        } else {
          dispatch(resendCodeError(message));
        }
      })
      .catch((error) => {
        dispatch(resendCodeError(error));
      });
  };
}

export function sendPhoneNumberOtp(payload) {
  return (dispatch) => {
    dispatch(sendMobileOtpRequest());
    AuthService.sendPhoneNumberOtp(payload)
      .then((response) => {
        const { status, data, message, error } = response.data;
        if (status === 1) {
          // console.log('SEND PHONE', data.user)
          LocalstorageService.setLoggedInUserDetails(data.user);
          dispatch(sendMobileOtpSuccess({ data, message }));
        } else {
          dispatch(sendMobileOtpError(error));
        }
      }).catch((error) => {
        dispatch(sendMobileOtpError(error));
      })
  }
}

export function verifyPhoneNumberOtp(payload) {
  return (dispatch) => {
    dispatch(verifyMobileOtpRequest());
    AuthService.verifyPhoneNumberOtpUrl(payload)
      .then((response) => {
        const { status, message, data, error } = response.data;
        console.log(response, '444444444');
        if (status === 1) {
          LocalstorageService.setLoggedInUserDetails(data.user);
          dispatch(verifyMobileOtpSuccess({ data, message }));
        } else {
          dispatch(verifyMobileOtpError(error));
        }
      }).catch((error) => {
        dispatch(verifyMobileOtpError(error));
      })
  }
}