import { ChevronDown, ShoppingBag } from 'lucide-react';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { fetchHomeCategories } from '../../middlewares/category';

const ProductListCateogry = ({ mobileMenu, setMobileMenu }) => {
  const dispatch = useDispatch();
  const [subMenuActive, setSubMenuActive] = useState(null);
  const [currentMenuTitle, setCurrentMenuTitle] = useState('');

  const { homeCategory } = useSelector((state) => state.category);

  const showSubMenu = (title) => {
    setCurrentMenuTitle(title);
    setSubMenuActive(title);
  };

  const hideSubMenu = () => {
    setSubMenuActive(null);
    setCurrentMenuTitle('');
  };

  useEffect(() => {
    dispatch(fetchHomeCategories())
  }, [dispatch])

  return (
    <section className="header header-menu" >
      <div className="container-fluid position-relative">
        <div className="row v-center">
          <div className="header-item item-center">
            <div className={`menu-overlay ${mobileMenu ? 'active' : ''}`} onClick={() => setMobileMenu(false)} />
            <nav className={`menu ${mobileMenu ? 'active' : ''}`}>
              <div className={`mobile-menu-head ${subMenuActive ? 'active' : ''}`} >
                <div className="go-back" onClick={hideSubMenu}><i className="fa fa-angle-left" /></div>
                <div className="current-menu-title">{currentMenuTitle}</div>
                <div className="mobile-menu-close" onClick={() => setMobileMenu(false)}>×</div>
              </div>
              <ul className="menu-main">
                <li className={`menu-item-has-children header-bg ${subMenuActive === 'Categories' ? 'active' : ''}`}>
                  <a className="px-3" onClick={() => showSubMenu('Categories')}>
                    Categories
                    <span className="seeAll" style={{ fontSize: 12, paddingInline: 15 }}>
                      (See All)
                    </span>
                    <ChevronDown className='iconFont' /></a>
                  <div className={`sub-menu single-column-menu ${subMenuActive === 'Categories' ? 'active' : ''}`}>
                    <ul>
                      {homeCategory.map((category, i) => (
                        <li key={i}>
                          <Link to={`/${category.slug}/product-list`}>{category.short_name}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </li>
                <li><Link to='/'>Home</Link>
                </li>
                <li className={`menu-item-has-children ${subMenuActive === 'Fashion' ? 'active' : ''}`}>
                  <a onClick={() => showSubMenu('Fashion')}>Fashion's <ChevronDown fontSize={1} /></a>
                  <div className={`sub-menu mega-menu mega-menu-column-4 ${subMenuActive === 'Fashion' ? 'active' : ''}`}>
                    <div className="list-item text-center"><a href="#"><img src="https://th.bing.com/th/id/R.ce8de1adaa3fcc169f0d97c436b8274b?rik=6W1qX6Qp03lNWg&riu=http%3a%2f%2fimages.unsplash.com%2fphoto-1504633273314-6a929fcd7090%3fixlib%3drb-0.3.5%26q%3d80%26fm%3djpg%26crop%3dentropy%26cs%3dtinysrgb%26w%3d1080%26fit%3dmax%26ixid%3deyJhcHBfaWQiOjEyMDd9%26s%3de8e0ff2cce3792a276fa84086113a17c&ehk=chx16tzqSRzpfA6BYM4FNaZZ%2fMJwEtxaqHPW1FZb9FI%3d&risl=&pid=ImgRaw&r=0" alt="Fashion" />
                      <h4 className="title">Fashion</h4>
                    </a>
                    </div>
                    <div className="list-item text-center"><a href="#"><img src="https://img.freepik.com/free-photo/male-technician-repairing-computer-motherboard-wooden-desk_23-2147923493.jpg?w=740&t=st=1671765056~exp=1671765656~hmac=4c8dffc9558819b4c21b126aaa394150f48adc3be9c8004c0b65d0420709ec00" alt="Electronics" />
                      <h4 className="title">Electronics</h4>
                    </a>
                    </div>
                    <div className="list-item text-center"><a href="#"><img src="https://images.unsplash.com/photo-1571380401583-72ca84994796?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80" alt="Mobiles" />
                      <h4 className="title">Mobiles</h4>
                    </a>
                    </div>
                    <div className="list-item text-center"><a href="#"><img src="https://images.unsplash.com/photo-1578643463396-0997cb5328c1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=736&q=80" alt="Appliances" />
                      <h4 className="title">Appliances</h4>
                    </a>
                    </div>
                  </div>
                </li>
                <li className={`menu-item-has-children ${subMenuActive === 'Mobiles' ? 'active' : ''}`}>
                  <a onClick={() => showSubMenu('Mobiles')}>Mobiles<ChevronDown fontSize={1} /></a>
                  <div className={`sub-menu mega-menu mega-menu-column-4 ${subMenuActive === 'Mobiles' ? 'active' : ''}`}>
                    <div className="list-item">
                      <h4 className="title">Men's Fashion</h4>
                      <ul>
                        <li><a href="#">T-Shirts</a></li>
                        <li><a href="#">Jeans</a></li>
                        <li><a href="#">Suit</a></li>
                        <li><a href="#">Kurta</a></li>
                        <li><a href="#">Watch</a></li>
                      </ul>
                      <h4 className="title">Beauty</h4>
                      <ul>
                        <li><a href="#">Moisturizer</a></li>
                        <li><a href="#">Face powder</a></li>
                        <li><a href="#">Lipstick</a></li>
                      </ul>
                    </div>
                    <div className="list-item">
                      <h4 className="title">Women's Fashion</h4>
                      <ul>
                        <li><a href="#">Sarees</a></li>
                        <li><a href="#">Sandals</a></li>
                        <li><a href="#">Watchs</a></li>
                        <li><a href="#">Shoes</a></li>
                      </ul>
                      <h4 className="title">Furniture</h4>
                      <ul>
                        <li><a href="#">Chairs</a></li>
                        <li><a href="#">Tables</a></li>
                        <li><a href="#">Doors</a></li>
                        <li><a href="#">Bed</a></li>
                      </ul>
                    </div>
                    <div className="list-item">
                      <h4 className="title">Home, Kitchen</h4>
                      <ul>
                        <li><a href="#">Kettle</a></li>
                        <li><a href="#">Toaster</a></li>
                        <li><a href="#">Dishwasher</a></li>
                        <li><a href="#">Microwave oven</a></li>
                        <li><a href="#">Pitcher</a></li>
                        <li><a href="#">Blender</a></li>
                        <li><a href="#">Colander</a></li>
                        <li><a href="#">Tureen</a></li>
                        <li><a href="#">Cookware</a></li>
                      </ul>
                    </div>
                    <div className="list-item"><img src="https://images.unsplash.com/photo-1549497538-303791108f95?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=761&q=80" alt="Chair" />
                    </div>
                  </div>
                </li>
                <li className={`menu-item-has-children ${subMenuActive === 'Furniture' ? 'active' : ''}`}>
                  <a onClick={() => showSubMenu('Furniture')}>Furniture <ChevronDown fontSize={1} /></a>
                  <div className={`sub-menu mega-menu mega-menu-column-4 ${subMenuActive === 'Furniture' ? 'active' : ''}`}>
                    <div className="list-item">
                      <h4 className="title">Men's Fashion</h4>
                      <ul>
                        <li><a href="#">T-Shirts</a></li>
                        <li><a href="#">Jeans</a></li>
                        <li><a href="#">Suit</a></li>
                        <li><a href="#">Kurta</a></li>
                        <li><a href="#">Watch</a></li>
                      </ul>
                      <h4 className="title">Beauty</h4>
                      <ul>
                        <li><a href="#">Moisturizer</a></li>
                        <li><a href="#">Face powder</a></li>
                        <li><a href="#">Lipstick</a></li>
                      </ul>
                    </div>
                    <div className="list-item">
                      <h4 className="title">Women's Fashion</h4>
                      <ul>
                        <li><a href="#">Sarees</a></li>
                        <li><a href="#">Sandals</a></li>
                        <li><a href="#">Watchs</a></li>
                        <li><a href="#">Shoes</a></li>
                      </ul>
                      <h4 className="title">Furniture</h4>
                      <ul>
                        <li><a href="#">Chairs</a></li>
                        <li><a href="#">Tables</a></li>
                        <li><a href="#">Doors</a></li>
                        <li><a href="#">Bed</a></li>
                      </ul>
                    </div>
                    <div className="list-item">
                      <h4 className="title">Home, Kitchen</h4>
                      <ul>
                        <li><a href="#">Kettle</a></li>
                        <li><a href="#">Toaster</a></li>
                        <li><a href="#">Dishwasher</a></li>
                        <li><a href="#">Microwave oven</a></li>
                        <li><a href="#">Pitcher</a></li>
                        <li><a href="#">Blender</a></li>
                        <li><a href="#">Colander</a></li>
                        <li><a href="#">Tureen</a></li>
                        <li><a href="#">Cookware</a></li>
                      </ul>
                    </div>
                    <div className="list-item"><img src="https://images.unsplash.com/photo-1549497538-303791108f95?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=761&q=80" alt="Chair" />
                    </div>
                  </div>
                </li>
                <li className={`menu-item-has-children ${subMenuActive === 'Electronic' ? 'active' : ''}`}>
                  <a onClick={() => showSubMenu('Electronic')}>Electronic<ChevronDown fontSize={1} /></a>
                  <div className={`sub-menu mega-menu mega-menu-column-4 ${subMenuActive === 'Electronic' ? 'active' : ''}`}>
                    <div className="list-item">
                      <h4 className="title">Men's Fashion</h4>
                      <ul>
                        <li><a href="#">T-Shirts</a></li>
                        <li><a href="#">Jeans</a></li>
                        <li><a href="#">Suit</a></li>
                        <li><a href="#">Kurta</a></li>
                        <li><a href="#">Watch</a></li>
                      </ul>
                      <h4 className="title">Beauty</h4>
                      <ul>
                        <li><a href="#">Moisturizer</a></li>
                        <li><a href="#">Face powder</a></li>
                        <li><a href="#">Lipstick</a></li>
                      </ul>
                    </div>
                    <div className="list-item">
                      <h4 className="title">Women's Fashion</h4>
                      <ul>
                        <li><a href="#">Sarees</a></li>
                        <li><a href="#">Sandals</a></li>
                        <li><a href="#">Watchs</a></li>
                        <li><a href="#">Shoes</a></li>
                      </ul>
                      <h4 className="title">Furniture</h4>
                      <ul>
                        <li><a href="#">Chairs</a></li>
                        <li><a href="#">Tables</a></li>
                        <li><a href="#">Doors</a></li>
                        <li><a href="#">Bed</a></li>
                      </ul>
                    </div>
                    <div className="list-item">
                      <h4 className="title">Home, Kitchen</h4>
                      <ul>
                        <li><a href="#">Kettle</a></li>
                        <li><a href="#">Toaster</a></li>
                        <li><a href="#">Dishwasher</a></li>
                        <li><a href="#">Microwave oven</a></li>
                        <li><a href="#">Pitcher</a></li>
                        <li><a href="#">Blender</a></li>
                        <li><a href="#">Colander</a></li>
                        <li><a href="#">Tureen</a></li>
                        <li><a href="#">Cookware</a></li>
                      </ul>
                    </div>
                    <div className="list-item"><img src="https://images.unsplash.com/photo-1549497538-303791108f95?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=761&q=80" alt="Chair" />
                    </div>
                  </div>
                </li>
                <li className={`menu-item-has-children ${subMenuActive === 'Appliances' ? 'active' : ''}`}>
                  <a onClick={() => showSubMenu('Appliances')}>Appliances <ChevronDown fontSize={1} /></a>
                  <div className={`sub-menu mega-menu mega-menu-column-4 ${subMenuActive === 'Appliances' ? 'active' : ''}`}>
                    <div className="list-item">
                      <h4 className="title">Men's Fashion</h4>
                      <ul>
                        <li><a href="#">T-Shirts</a></li>
                        <li><a href="#">Jeans</a></li>
                        <li><a href="#">Suit</a></li>
                        <li><a href="#">Kurta</a></li>
                        <li><a href="#">Watch</a></li>
                      </ul>
                      <h4 className="title">Beauty</h4>
                      <ul>
                        <li><a href="#">Moisturizer</a></li>
                        <li><a href="#">Face powder</a></li>
                        <li><a href="#">Lipstick</a></li>
                      </ul>
                    </div>
                    <div className="list-item">
                      <h4 className="title">Women's Fashion</h4>
                      <ul>
                        <li><a href="#">Sarees</a></li>
                        <li><a href="#">Sandals</a></li>
                        <li><a href="#">Watchs</a></li>
                        <li><a href="#">Shoes</a></li>
                      </ul>
                      <h4 className="title">Furniture</h4>
                      <ul>
                        <li><a href="#">Chairs</a></li>
                        <li><a href="#">Tables</a></li>
                        <li><a href="#">Doors</a></li>
                        <li><a href="#">Bed</a></li>
                      </ul>
                    </div>
                    <div className="list-item">
                      <h4 className="title">Home, Kitchen</h4>
                      <ul>
                        <li><a href="#">Kettle</a></li>
                        <li><a href="#">Toaster</a></li>
                        <li><a href="#">Dishwasher</a></li>
                        <li><a href="#">Microwave oven</a></li>
                        <li><a href="#">Pitcher</a></li>
                        <li><a href="#">Blender</a></li>
                        <li><a href="#">Colander</a></li>
                        <li><a href="#">Tureen</a></li>
                        <li><a href="#">Cookware</a></li>
                      </ul>
                    </div>
                    <div className="list-item"><img src="https://images.unsplash.com/photo-1549497538-303791108f95?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=761&q=80" alt="Chair" />
                    </div>
                  </div>
                </li>
                <li className={`menu-item-has-children ${subMenuActive === 'Beauty & Toys' ? 'active' : ''}`}>
                  <a onClick={() => showSubMenu('Beauty & Toys')}>Beauty & Toys<ChevronDown fontSize={1} /></a>
                  <div className={`sub-menu mega-menu mega-menu-column-4 ${subMenuActive === 'Beauty & Toys' ? 'active' : ''}`}>
                    <div className="list-item">
                      <h4 className="title">Men's Fashion</h4>
                      <ul>
                        <li><a href="#">T-Shirts</a></li>
                        <li><a href="#">Jeans</a></li>
                        <li><a href="#">Suit</a></li>
                        <li><a href="#">Kurta</a></li>
                        <li><a href="#">Watch</a></li>
                      </ul>
                      <h4 className="title">Beauty</h4>
                      <ul>
                        <li><a href="#">Moisturizer</a></li>
                        <li><a href="#">Face powder</a></li>
                        <li><a href="#">Lipstick</a></li>
                      </ul>
                    </div>
                    <div className="list-item">
                      <h4 className="title">Women's Fashion</h4>
                      <ul>
                        <li><a href="#">Sarees</a></li>
                        <li><a href="#">Sandals</a></li>
                        <li><a href="#">Watchs</a></li>
                        <li><a href="#">Shoes</a></li>
                      </ul>
                      <h4 className="title">Furniture</h4>
                      <ul>
                        <li><a href="#">Chairs</a></li>
                        <li><a href="#">Tables</a></li>
                        <li><a href="#">Doors</a></li>
                        <li><a href="#">Bed</a></li>
                      </ul>
                    </div>
                    <div className="list-item">
                      <h4 className="title">Home, Kitchen</h4>
                      <ul>
                        <li><a href="#">Kettle</a></li>
                        <li><a href="#">Toaster</a></li>
                        <li><a href="#">Dishwasher</a></li>
                        <li><a href="#">Microwave oven</a></li>
                        <li><a href="#">Pitcher</a></li>
                        <li><a href="#">Blender</a></li>
                        <li><a href="#">Colander</a></li>
                        <li><a href="#">Tureen</a></li>
                        <li><a href="#">Cookware</a></li>
                      </ul>
                    </div>
                    <div className="list-item"><img src="https://images.unsplash.com/photo-1549497538-303791108f95?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=761&q=80" alt="Chair" />
                    </div>
                  </div>
                </li>
                <li><a href="#">Grocery</a>
                </li>
                <li><a href="#">Todays Deal</a>
                </li>
                {/* <li className="header-bg">
                  <a className="d-flex align-items-center text-dark px-3 h-100"><span className="mx-2">
                    <ShoppingBag className='iconFont' />
                  </span><span className="d-none d-xl-block mx-2 fs-14 fw-700 text-black">₹
                    0.00</span><span className="nav-box-text d-none d-xl-block ml-2 text-black fs-12">
                      ( <span className="cart-count">0 </span> Items )
                    </span>
                  </a>
                </li> */}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </section >

  )
}

export default ProductListCateogry