import React from 'react'
import { Link } from 'react-router-dom'

const ProductListBreadcrumb = ({ breadcrumbs }) => {

    return (
        <div className="breadcrumb-listing">
            <nav aria-label="breadcrumb">
                {/* <ol className="breadcrumb">
                    {breadcrumbs?.map((breadcrumb, index) => (
                        <li key={index} className={`breadcrumb-item ${breadcrumb.active ? 'active' : ''}`}>
                            {breadcrumb.active ? (
                                <span>{breadcrumb.label}</span>
                            ) : (
                                <Link to={breadcrumb.path}>{categories[breadcrumb.label] || breadcrumb.label} <span className="px-1">/</span></Link>
                            )}
                        </li>
                    ))}
                </ol> */}
                {/* <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">Home <span className="px-1">/</span></a></li>
                    <li className="breadcrumb-item"><a href="#"> Clothing <span className="px-1">/</span></a></li>
                    <li className="breadcrumb-item active" aria-current="page"> Men T-shirts </li>
                </ol> */}
            </nav>

            {/* <div className="show-products">
                <p><strong>{breadcrumbs[breadcrumbs?.length - 1]?.label}</strong><span className="px-2">(Showing 1 – 40 products of 29,083 products)</span></p>
            </div> */}
        </div>
    )
}

export default ProductListBreadcrumb