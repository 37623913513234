import { createSlice, current } from "@reduxjs/toolkit";
import { WISHLIST } from "../helpers/slice.name";

export const wishListSlice = createSlice({
    name: WISHLIST,
    initialState: {

        getWishListLoading: false,
        getWishList: [],
        wishListCount: 0,
        getWishListSuccessMessage: "",
        getWishListError: "",

        addProductWishListLoading: false,
        addProductWishList: null,
        addProductWishListSuccessMessage: "",
        addProductWishListError: "",

        checkProductWishListLoading: false,
        checkProductWishList: null,
        checkProductWishListSuccessMessage: "",
        checkProductWishListError: "",

        deleteProductWishListLoading: false,
        deleteProductWishList: null,
        deleteProductWishListSuccessMessage: "",
        deleteProductWishListError: "",
    },
    reducers: {
        getWishListRequest: (state, action) => {
            state.getWishListLoading = true;
            state.getWishListSuccessMessage = "";
            state.getWishListError = "";
        },
        getWishListSuccess: (state, action) => {
            const prev = current(state.getWishList)
            state.getWishListLoading = false;
            state.getWishListSuccessMessage = action.payload.message;
            if(prev?.length > 0){
                const curr = current(state.deleteProductWishList)
                if(curr?.product_id){
                    state.getWishList = prev.filter(o=>o.product.id!==curr.product_id)
                } else {
                    state.getWishList = action.payload;
                }
            } else {
                state.getWishList = action.payload;
            }
        },
        getWishListError: (state, action) => {
            state.getWishListLoading = false;
            state.getWishListError = action.payload;
        },

        addProductWishListRequest: (state, action) => {
            state.addProductWishListLoading = true;
            state.addProductWishListSuccessMessage = "";
            state.addProductWishListError = "";
        },
        addProductWishListSuccess: (state, action) => {
            state.addProductWishListLoading = false;
            state.addProductWishList = action.payload;
            state.addProductWishListSuccessMessage = action.payload;
        },
        addProductWishListError: (state, action) => {
            state.addProductWishListLoading = false;
            state.addProductWishListError = action.payload;
        },
        resetAddProductWishList: (state) => {
            state.addProductWishListError = "";
            state.addProductWishListSuccessMessage = "";
        },
        checkProductWishListRequest: (state, action) => {
            state.checkProductWishListLoading = true;
            state.checkProductWishListSuccessMessage = "";
            state.checkProductWishListError = "";
        },
        checkProductWishListSuccess: (state, action) => {
            state.checkProductWishListLoading = false;
            state.checkProductWishList = action.payload;
            state.checkProductWishListSuccessMessage = action.payload.message;
        },
        checkProductWishListError: (state, action) => {
            state.checkProductWishListLoading = false;
            state.checkProductWishListError = action.payload;
        },

        deleteProductWishListRequest: (state, action) => {
            state.deleteProductWishListLoading = true;
            state.deleteProductWishListSuccessMessage = "";
            state.deleteProductWishListError = "";
        },
        deleteProductWishListSuccess: (state, action) => {
            state.deleteProductWishListLoading = false;
            state.deleteProductWishList = action.payload.data;
            state.deleteProductWishListSuccessMessage = action.payload;
        },
        deleteProductWishListError: (state, action) => {
            state.deleteProductWishListLoading = false;
            state.deleteProductWishListError = action.payload;
        },
        resetDeleteProductWishList: (state) => {
            state.deleteProductWishListError = "";
            state.deleteProductWishListSuccessMessage = "";
    },
        updateWishListCount: (state, action) => {
            state.wishListCount = action.payload;
        },
}
});

export const {
    getWishListRequest,
    getWishListSuccess,
    getWishListError,
    resetDeleteProductWishList,

    addProductWishListRequest,
    addProductWishListSuccess,
    addProductWishListError,

    checkProductWishListRequest,
    checkProductWishListSuccess,
    checkProductWishListError,

    deleteProductWishListRequest,
    deleteProductWishListSuccess,
    deleteProductWishListError,
    resetAddProductWishList,
    updateWishListCount
} = wishListSlice.actions;

export default wishListSlice.reducer;
