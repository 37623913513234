import React, { useEffect, useState } from 'react'
import SideBarManu from './SideBarManu'
import { BadgeCheck, Eye, EyeOff } from 'lucide-react'
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
    ProfileConfirmPassword,
    name,
    phone,
    profilPassword,
} from "../../helpers/yup.validation.schema";
import { profilUpdater } from '../../middlewares/UserProfile/UpdateProfile'
import ToastService from '../../helpers/toast-services'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import PhoneInput from 'react-phone-input-2';
import { resetProfile } from '../../slices/ProfileSlice';

const AccountProfile = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { editProfileMessage, editProfileError, editProfileLoading } = useSelector((state) => state.profile)
    const IsLogedin = localStorage.getItem('IN_USER_DATA')
    const [pic, setPic] = useState(null);
    const [countryCode, setCountryCode] = useState(null)
    const [userDetails, setUserDetails] = useState(null)

    const handleProfilSubmit = (values) => {
        const decode = userDetails?.phone.split(countryCode)[1]

        const formData = new FormData();
        formData.append('userid', userDetails?.id);
        formData.append('name', values.name);
        {
            values?.password !== undefined && (
                formData.append('password', values.password)
            )
        }
        {
            values?.phone_code !== null && (
                formData.append('phone_code', values.phone_code === '' ? decode === null ? userDetails?.phone_code : decode : values.phone_code)
            )
        }
        {
            values?.phone !== undefined && (
                formData.append('phone', values.phone === '' ? decode : values.phone)
            )
        }

        if (pic) {
            formData.append('image', pic);
        }
        dispatch(profilUpdater(formData));
        ProfileFormik.resetForm();
    };

    const profileShema = Yup.object({
        password: profilPassword,
        cpassword: ProfileConfirmPassword,
        name: name,
        phone: phone
    });

    const ProfileFormik = useFormik({
        initialValues: {
            image: '',
            phone_code: '',
            name: userDetails?.name || "",
            email: "",
            password: "",
            cpassword: '',
            phone: ''
        },
        validationSchema: profileShema,
        onSubmit: handleProfilSubmit,
    });

    useEffect(() => {
        if (IsLogedin) {
            navigate('/profile')
        } else {
            navigate('/')
            toast.error('Please log in again to continue');
        }
        IsLogedin ? navigate('/profile') : navigate('/')
    }, [IsLogedin]);

    useEffect(() => {
        if (editProfileMessage) {
            ToastService.success(editProfileMessage);
        }
        if (editProfileError) {
            ToastService.error(editProfileError);
        }
        setUserDetails(JSON.parse(IsLogedin))
        dispatch(resetProfile())
    }, [editProfileMessage, editProfileError]);

    const [showPassword, setShowPassword] = useState(false);
    const [showPass, setShowPass] = useState(false);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const isValidType = ['image/jpeg', 'image/png', 'image/jpg'].includes(file.type);

            if (!isValidType) {
                toast.error('Unsupported file format. Only jpg, jpeg, and png are allowed.');
                return;
            }

            ProfileFormik.setFieldValue('image', file);
            setPic(file);
        }
    };

    const handlePhoneChange = (value, country) => {
        setCountryCode(country.dialCode);
        const phoneNumberWithoutCode = value.startsWith(country.dialCode)
            ? value.replace(country.dialCode, '')
            : value;
        ProfileFormik.setFieldValue('phone', phoneNumberWithoutCode);
    };

    const hasChanges = (values) => {
        return Object.keys(ProfileFormik.initialValues).some(key => ProfileFormik.initialValues[key] !== values[key]);
    };

    useEffect(() => {
        if (userDetails) {
            ProfileFormik.setValues({
                name: userDetails.name || "",
                email: userDetails.email || "",
                phone_code: countryCode,
            });
        }
    }, [userDetails, countryCode]);
    return (
        <>
            <section className="page-title">
                <div className="pattern-layer"></div>
                <div className="container-full">
                    <div className="content-box">
                        <h3>Personal Information</h3>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item active" aria-current="page"><Link to='/'>Home</Link></li>
                                <li className="breadcrumb-item" aria-current="page">/</li>
                                <li className="breadcrumb-item" aria-current="page">Profile Page </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </section>
            <section className="profile-section profile-wrapper">
                <div className="container">
                    <div className="row">
                        <SideBarManu />
                        <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-8 account-wrapper">
                            <div className="account-card">
                                <form onSubmit={ProfileFormik.handleSubmit}>
                                    <div className="profile-edit">
                                        <div className="avatar-upload d-flex align-items-center">
                                            <div className=" position-relative ">
                                                <div className="avatar-preview thumb">
                                                    <div id="imagePreview">
                                                        {userDetails ? (
                                                            <img className="Edit-profile" src={userDetails?.avatar_original ? userDetails?.avatar_original : ''} alt={userDetails?.avatar_original} />
                                                        ) : (
                                                            <Skeleton circle={true} height={122} width={122} />
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="change-btn  thumb-edit d-flex align-items-center flex-wrap">
                                                    {userDetails ? (
                                                        <>
                                                            <input
                                                                type="file"
                                                                name="image"
                                                                onChange={handleFileChange}
                                                                className="form-control d-none"
                                                                id="imageUpload"
                                                                accept=".png, .jpg, .jpeg" />
                                                            <label htmlFor="imageUpload" className="btn btn-light ms-0">
                                                                <i className="fa-solid fa-camera" />
                                                            </label>
                                                        </>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="clearfix mx-2">
                                            <h2 className="title mb-0">
                                                {userDetails ? userDetails?.name : <Skeleton width={150} />}
                                            </h2>
                                            <span className="text desh-text">
                                                {userDetails ? userDetails?.email : <Skeleton width={200} />}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="form-group m-b25">
                                                <label className="label-title">User Name</label>
                                                <input
                                                    name="name"
                                                    value={ProfileFormik.values.name}
                                                    onChange={ProfileFormik.handleChange}
                                                    onBlur={ProfileFormik.handleBlur}
                                                    className="form-control edit-profile-input"
                                                />
                                                <div className="error"> {ProfileFormik.errors.name}</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12">
                                            <div className="form-group m-b25">
                                                <div className='d-flex justify-content-between'>
                                                    <div className='d-flex gap-1'>
                                                        <label className="label-title">Email address</label>
                                                        <p>
                                                            {userDetails ? (
                                                                userDetails.email_verified === true ? (
                                                                    <BadgeCheck size={24} color='green' />
                                                                ) : (
                                                                    <BadgeCheck size={24} color='red' />
                                                                )
                                                            ) : (
                                                                <Skeleton width={24} height={24} />
                                                            )}
                                                        </p>
                                                    </div>
                                                    {userDetails?.email_verified === false && (
                                                        <div className='d-flex verify-badge'>
                                                            <span className="badge badge-success" style={{ cursor: 'pointer' }} onClick={() => { navigate('/email-verification') }}>Verify</span>
                                                        </div>
                                                    )}
                                                </div>
                                                <input
                                                    className="form-control edit-profile-input"
                                                    disabled
                                                    type="email"
                                                    name="email"
                                                    readOnly
                                                    value={userDetails?.email || <Skeleton />}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12">
                                            <div className="form-group m-b25">
                                                <div className='d-flex justify-content-between'>
                                                    <div className='d-flex gap-1'>
                                                        <label className="label-title">Phone</label>
                                                        <p>
                                                            {userDetails ? (
                                                                userDetails.phone_verified_at === true ? (
                                                                    <BadgeCheck size={24} color='green' />
                                                                ) : (
                                                                    <BadgeCheck size={24} color='red' />
                                                                )
                                                            ) : (
                                                                <Skeleton width={24} height={24} />
                                                            )}
                                                        </p>
                                                    </div>
                                                    {userDetails?.phone === null ? <div className='d-flex verify-badge'>
                                                        <span className="badge badge-success" style={{ cursor: 'pointer' }} onClick={() => { navigate('/phone') }}>Verify</span>
                                                    </div> : userDetails?.phone ? (
                                                        <div className='d-flex verify-badge'>
                                                            <span className="badge badge-success" style={{ cursor: 'pointer' }} >Verify</span>
                                                        </div>
                                                    ) : null}
                                                </div>
                                                {/* <PhoneInput
                                                    className="edit-profile-input"
                                                    inputClass='phone-flag-input'
                                                    inputExtraProps={{
                                                        name: 'phone',
                                                        required: true,
                                                        autoFocus: true,
                                                    }}
                                                    country={'in'}
                                                    onChange={handlePhoneChange}
                                                    value={userDetails?.phone_code + userDetails?.phone}
                                                /> */}
                                                <div className="error">{ProfileFormik.errors.phone}</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12">
                                            <div className="form-group m-b25">
                                                <label className="label-title">New password
                                                </label>
                                                <div className='position-relative'>
                                                    <input
                                                        type={showPassword ? "text" : "password"}
                                                        name="password"
                                                        value={ProfileFormik.values.password}
                                                        onChange={ProfileFormik.handleChange}
                                                        onBlur={ProfileFormik.handleBlur}
                                                        className="form-control edit-profile-input" />
                                                    <div className='icon-password'>
                                                        {showPassword ? <EyeOff onClick={() => setShowPassword(!showPassword)} /> : <Eye onClick={() => setShowPassword(!showPassword)} />}
                                                    </div>
                                                </div>
                                                <div className="error"> {ProfileFormik.errors.password}</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12">
                                            <div className="form-group m-b25">
                                                <label className="label-title">Confirm new password</label>
                                                <div className='position-relative'>
                                                    <input
                                                        type={showPass ? "text" : "password"}
                                                        name="cpassword"
                                                        onChange={ProfileFormik.handleChange}
                                                        value={ProfileFormik.values.cpassword}
                                                        onBlur={ProfileFormik.handleBlur}
                                                        className="form-control edit-profile-input" />
                                                    <div className='icon-password'>
                                                        {showPass ? <EyeOff onClick={() => setShowPass(!showPass)} /> : <Eye onClick={() => setShowPass(!showPass)} />}
                                                    </div>
                                                </div>
                                                <div className="error"> {ProfileFormik.errors.cpassword}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex  justify-content-end align-items-center">
                                        <button className="btn desh-btn mt-3 mt-sm-0" type="submit" disabled={editProfileLoading || !hasChanges(ProfileFormik.values)}>{editProfileLoading ? "Processing..." : "Update profile"}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div >
            </section >
        </>
    )
}

export default AccountProfile