import React from 'react'

const RatingGraphSection = () => {
    return (
        <div className="col-lg-4 col-md-4 col-sm-12">
            {/* <div className="row justify-content-between">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <h5 className="font-16 mb-2">Ratings</h5>
                            <div className="rating-content">
                                <h4>4.2 <span><i className="fa-solid fa-star" /></span> </h4>
                                <p className="font-12 p-0">3.1k Verified Buyers</p>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12">
                            <div className="rating-stars">
                                <div className=" index-ratingBarContainer">
                                    <div className="row flex customer-ratting-row">
                                        <div className="col-md-1 p-0 col-1">
                                            <div className="index-rating">
                                                <span className="index-ratingLevel">5</span>
                                                <span className="index-ratingStar"><i className="fa-solid fa-star" /></span>
                                            </div>
                                        </div>
                                        <div className="col-md-8 px-1 col-9">
                                            <div className="progress">
                                                <div className="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" aria-valuenow={75} aria-valuemin={0} aria-valuemax={100} style={{ width: '75%' }} />
                                            </div>
                                        </div>
                                        <div className=" p-0 col-md-2 p-0 col-2">
                                            <div className="index-count">11739</div>
                                        </div>
                                    </div>
                                </div>
                                <div className=" index-ratingBarContainer">
                                    <div className="row flex customer-ratting-row">
                                        <div className="col-md-1 p-0 col-1">
                                            <div className="index-rating">
                                                <span className="index-ratingLevel">4</span>
                                                <span className="index-ratingStar"><i className="fa-solid fa-star" /></span>
                                            </div>
                                        </div>
                                        <div className="col-md-8 px-1 col-9">
                                            <div className="progress">
                                                <div className="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" aria-valuenow={37} aria-valuemin={0} aria-valuemax={100} style={{ width: '37%' }} />
                                            </div>
                                        </div>
                                        <div className="col-md-2 p-0 col-2">
                                            <div className="index-count">12300</div>
                                        </div>
                                    </div>
                                </div>
                                <div className=" index-ratingBarContainer">
                                    <div className="row flex customer-ratting-row">
                                        <div className="col-md-1 p-0 col-1">
                                            <div className="index-rating">
                                                <span className="index-ratingLevel">3</span>
                                                <span className="index-ratingStar"><i className="fa-solid fa-star" /></span>
                                            </div>
                                        </div>
                                        <div className="col-md-8 px-1 col-9">
                                            <div className="progress">
                                                <div className="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" aria-valuenow={17} aria-valuemin={0} aria-valuemax={100} style={{ width: '17%' }} />
                                            </div>
                                        </div>
                                        <div className="col-md-2 p-0 col-2">
                                            <div className="index-count">1200</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="index-ratingBarContainer">
                                    <div className="row flex customer-ratting-row">
                                        <div className="col-md-1 p-0 col-1">
                                            <div className="index-rating">
                                                <span className="index-ratingLevel">2</span>
                                                <span className="index-ratingStar"><i className="fa-solid fa-star" /></span>
                                            </div>
                                        </div>
                                        <div className="col-md-8 px-1 col-9">
                                            <div className="progress">
                                                <div className="progress-bar progress-bar-striped progress-bar-animated bg-warning" role="progressbar" aria-valuenow={7} aria-valuemin={0} aria-valuemax={100} style={{ width: '7%' }} />
                                            </div>
                                        </div>
                                        <div className="col-md-2 p-0 col-2">
                                            <div className="index-count">1179</div>
                                        </div>
                                    </div>
                                </div>
                                <div className=" index-ratingBarContainer">
                                    <div className="row flex customer-ratting-row">
                                        <div className="col-md-1 p-0 col-1">
                                            <div className="index-rating">
                                                <span className="index-ratingLevel">1</span>
                                                <span className="index-ratingStar"><i className="fa-solid fa-star" /></span>
                                            </div>
                                        </div>
                                        <div className="col-md-8 px-1 col-9">
                                            <div className="progress">
                                                <div className="progress-bar progress-bar-striped progress-bar-animated bg-danger" role="progressbar" aria-valuenow={18} aria-valuemin={0} aria-valuemax={100} style={{ width: '18%' }} />
                                            </div>
                                        </div>
                                        <div className="col-md-2 p-0 col-2 ">
                                            <div className="index-count">119</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="rating-stars ">
                                <div className="mt-3">
                                    <h5 className="font-16">What Customers Said</h5>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 ">
                                            <div className="customer-ratting pt-2">
                                                <h6 className="f-14">Fit</h6>
                                                <div className="row flex customer-ratting-row">
                                                    <div className="col-md-10 col-9">
                                                        <div className="progress">
                                                            <div className="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" aria-valuenow={75} aria-valuemin={0} aria-valuemax={100} style={{ width: '75%' }} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 d-flex col-3">
                                                        <div className="index-count-per">Just
                                                            Right&nbsp;(58%)
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="customer-ratting pt-2">
                                                <h6 className="f-14">Length</h6>
                                                <div className="row flex customer-ratting-row">
                                                    <div className="col-md-10 col-9">
                                                        <div className="progress">
                                                            <div className="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" aria-valuenow={75} aria-valuemin={0} aria-valuemax={100} style={{ width: '75%' }} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 d-flex col-3">
                                                        <div className="index-count-per">Just
                                                            Right&nbsp;(58%)
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default RatingGraphSection