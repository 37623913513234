import React from 'react'
import SideBarManu from './SideBarManu'
import Footer from '../../Components/Common/Footer'

const ReturnPage = () => {
    return (
        <>
            <section className="page-title">
                <div className="pattern-layer"></div>
                <div className="container-full">
                    <div className="content-box">
                        <h3>Personal Information</h3>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item active" aria-current="page"><a href="#">Home</a></li>
                                <li className="breadcrumb-item" aria-current="page">/</li>
                                <li className="breadcrumb-item" aria-current="page">Return Page</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </section>
            <section className="profile-section profile-wrapper">
                <div className="container">
                    <div className="row">
                        <SideBarManu />
                        <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-8 account-wrapper">
                            <div className="account-card">
                                <div className="row">
                                    <div className="col-12 m-b30">
                                        <h3 className="mb-2">Request For Product Return</h3>
                                    </div>
                                    <div className="col-lg-6 m-b30">
                                        <div className="order-cancel-card">
                                            <div className="order-head">
                                                <h6 className="mb-0">Request No: <span className="text-primary">#1374837</span></h6>
                                                <a href="javascript:void(0);" className="btn-link text-underline text-success">Return Made</a>
                                            </div>
                                            <a href="account-return-request-detail.html" className="order-cancel-box">
                                                <div className="cancel-media">
                                                    <img src="./assets/images/Product/pro10.jpg" alt="product" />
                                                </div>
                                                <div className="order-cancel-content">
                                                    <span>March 21, 2024</span>
                                                    <h5 className="title mb-0">Collar Casual Shirt</h5>
                                                    <p className="mb-2">Quantity: <strong className="text-black">1</strong></p>
                                                    <h6 className="mb-0">₹ 105</h6>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 m-b30">
                                        <div className="order-cancel-card">
                                            <div className="order-head">
                                                <h6 className="mb-0">Request No: <span className="text-primary">#1374837</span></h6>
                                                <a href="javascript:void(0);" className="btn-link text-underline text-priamry">Request Submited</a>
                                            </div>
                                            <a href="account-return-request-detail.html" className="order-cancel-box">
                                                <div className="cancel-media">
                                                    <img src="./assets/images/Product/pro10.jpg" alt="product" />
                                                </div>
                                                <div className="order-cancel-content">
                                                    <span>March 21, 2024</span>
                                                    <h5 className="title mb-0">Collar Casual Shirt</h5>
                                                    <p className="mb-2">Quantity: <strong className="text-black">1</strong></p>
                                                    <h6 className="mb-0">₹ 304</h6>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 m-b30">
                                        <div className="order-cancel-card">
                                            <div className="order-head">
                                                <h6 className="mb-0">Request No: <span className="text-primary">#1374837</span></h6>
                                                <a href="javascript:void(0);" className="btn-link text-underline text-priamry">Request Submited</a>
                                            </div>
                                            <a href="account-return-request-detail.html" className="order-cancel-box">
                                                <div className="cancel-media">
                                                    <img src="./assets/images/Product/pro10.jpg" alt="product" />
                                                </div>
                                                <div className="order-cancel-content">
                                                    <span>March 21, 2024</span>
                                                    <h5 className="title mb-0">Classic Denim Skinny Jeans</h5>
                                                    <p className="mb-2">Quantity: <strong className="text-black">1</strong></p>
                                                    <h6 className="mb-0">₹ 657</h6>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 m-b30">
                                        <div className="order-cancel-card">
                                            <div className="order-head">
                                                <h6 className="mb-0">Request No: <span className="text-primary">#1374837</span></h6>
                                                <a href="javascript:void(0);" className="btn-link text-underline text-priamry">Request Submited</a>
                                            </div>
                                            <a href="account-return-request-detail.html" className="order-cancel-box">
                                                <div className="cancel-media">
                                                    <img src="./assets/images/Product/pro10.jpg" alt="product" />
                                                </div>
                                                <div className="order-cancel-content">
                                                    <span>March 21, 2024</span>
                                                    <h5 className="title mb-0">Plaid Wool Winter Coat</h5>
                                                    <p className="mb-2">Quantity: <strong className="text-black">1</strong></p>
                                                    <h6 className="mb-0">₹ 245</h6>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ReturnPage