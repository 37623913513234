import { Undo2 } from 'lucide-react'
import React from 'react'
import { Link } from 'react-router-dom'

const MobileSearchBar = () => {
    return (
        <>

            <div className="container-fluid">
                <div className='row'>
                    <div className="backbutton-mobile my-3">
                    <span style={{ cursor: 'pointer' }} >
                            <Link to='/'><Undo2 /></Link>
                        </span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="searchForm position-relative">
                            <form action="https://indiazona.addonwebtech.com/search" method="GET" className="stop-propagation flex navForm" role="search">
                                <input className="form-control searchInput" type="search" placeholder="Bharatki Apni Dukaan" aria-label="Search" id="search" />
                                <button className="btn btn-success btnSearch" type="submit">
                                    <i className="fa-solid fa-magnifying-glass" />
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal-dialog">
                <div className="modal-content border-0">
                    <div className="modal-header position-relative text-right justify-content-start d-flex">
                       
                    </div>
                    <div className="modal-body searchBox">

                    </div>
                </div>
            </div>
        </>
    )
}

export default MobileSearchBar