import { createSlice } from "@reduxjs/toolkit";
import { SEARCH } from "../helpers/slice.name";

export const searchSlice = createSlice({
    name: SEARCH,
    initialState: {
        searchLoading: false,
        search: [],
        searchMessage: "",
        searchError: "",
    },
    reducers: {
        searchRequest: (state, action) => {
            state.searchLoading = true;
            state.searchMessage = "";
            state.searchError = "";
        },
        searchSuccess: (state, action) => {
            state.searchLoading = false;
            state.search = action.payload.data;
            state.searchMessage = action.payload.message;
        },
        searchError: (state, action) => {
            state.searchLoading = false;
            state.searchError = action.payload;
        },
        clearSearch: (state, action) => {
            state.search = [];
            state.searchMessage = "";
            state.searchError = "";
        },
    }
});

export const { searchRequest, searchSuccess, searchError, clearSearch } = searchSlice.actions;


export default searchSlice.reducer;
