import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchWishListProducts,
  removeProductWishList,
} from "../middlewares/wishlist/wishlist";
import LocalstorageService from "../helpers/localstorage-services";
import { toast } from "react-toastify";
import { resetDeleteProductWishList } from "../slices/wishlist.slice";
import { Button } from 'primereact/button';
import { addToCart } from "../middlewares/cart/cart";
import Skeleton from "react-loading-skeleton";
import WishlistAlert from "../Components/Model/WishlistAlert";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import Swal from 'sweetalert2'
// import withReactContent from 'sweetalert2-react-content'
import { LOCALSTORAGE_WISHLIST_KEY } from "../config/constants";

const WishList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [initialLoading, setInitialLoading] = useState(true);
  const [showWishAlert, setShowWishAlert] = useState(false);
  const [wishData, setWishData] = useState(null);
  // const MySwal = withReactContent(Swal)
  const user = LocalstorageService.getLoggedInUserDetails();
  const handleHideWishAlert = () => {
    setShowWishAlert(!showWishAlert);
  }

  const handleShowWishAlert = (wishId) => {
    setShowWishAlert(true);
    setWishData(wishId)
  }

  const {
    getWishList,
    getWishListLoading,
    getWishListSuccessMessage,
    getWishListError,
    deleteProductWishListLoading,
    deleteProductWishListSuccessMessage,
    deleteProductWishListError,
  } = useSelector((state) => state.wishlist);

  useEffect(() => {
    if (initialLoading) {
      dispatch(fetchWishListProducts());
      setInitialLoading(!initialLoading);
    }
  }, []);

  useEffect(() => {
    if (deleteProductWishListSuccessMessage || deleteProductWishListError) {
      dispatch(fetchWishListProducts());
      setShowWishAlert(!showWishAlert);
      dispatch(resetDeleteProductWishList());
    }
    if (deleteProductWishListSuccessMessage) {
      toast.success(deleteProductWishListSuccessMessage.message);
      dispatch(resetDeleteProductWishList());
    }
    if (deleteProductWishListError) {
      toast.error(deleteProductWishListError);
      dispatch(resetDeleteProductWishList());
    }
  }, [deleteProductWishListSuccessMessage, deleteProductWishListError]);
  const handleConfirmPopUp = (id) => {
    if (!user?.id) {
      toast.error("User not logged in");
      navigate("/login");
      return;
    }
    // MySwal.fire({
    //     title: 'Are you sure?',
    //     text: "This product will be set to Add to Cart.",
    //     icon: 'info',
    //     showCancelButton: true,
    //     confirmButtonText: 'Yes, Add to Cart!',
    //     cancelButtonText: 'No, cancel!',
    // }).then((result) => {

    //     if (result.isConfirmed) {
    //         const variant = [{
    //             color: "#9966CC"
    //           }]
    //           const bodyFormData = new FormData();
    //           bodyFormData.append('user_id', user?.id);
    //           bodyFormData.append('product_id', id);
    //           bodyFormData.append('quantity', 1);
    //           bodyFormData.append('variant', JSON.stringify(variant))
    //         dispatch(addToCart(bodyFormData));
    //         dispatch(resetDeleteProductWishList());
    //         handleRemoveWishList(id)
    //         setShowWishAlert(!showWishAlert);
    //         MySwal.fire(
    //             'Success!',
    //             'Product has been added to cart.',
    //             'success'
    //         );
    //     } 
    //     if(result.isDismissed)
    //     {
    //       return
    //     }
    // });
  };
  const handleRemoveWishList = (productId) => {
    const user = LocalstorageService.getLoggedInUserDetails();
    const bodyFormData = new URLSearchParams();
    bodyFormData.append("product_id", productId);
    bodyFormData.append("user_id", user?.id);
    dispatch(removeProductWishList(bodyFormData));
  };

  return (
    <div>
      <main>
        <section className="page-title">
          <div className="pattern-layer"></div>
          <div className="container-full">
            <div className="content-box">
              <h3>Wishlist</h3>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item active" aria-current="page">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    /
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    My Wishlist
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </section>

        <section className="flat-spacing-11">
          <div className="container-fluid">
            <div className="tf-cart-countdown">
              <div className="title-left">
                <svg
                  width={20}
                  height={30}
                  viewBox="0 0 20 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_197_107)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.6124 30C14.1399 27.741 14.2806 25.3011 13.0346 22.6804C12.6881 24.0864 12.0543 24.9397 11.1331 25.2405C11.9885 22.7974 11.2737 20.1579 8.98848 17.322C8.93916 20.2509 8.2353 22.3845 6.87685 23.7228C5.00545 25.5646 5.02746 27.6432 6.94285 29.9586C-1.00824 25.7274 -2.12999 20.0523 3.57745 12.9338C3.93145 14.6535 4.78926 15.7093 6.15102 16.1011C4.66668 9.80233 6.22842 4.43526 10.8362 0C10.8645 9.84282 13.8827 10.7177 17.699 15.1112C21.8192 20.3839 19.398 26.4685 12.6124 30Z"
                      fill="url(#paint0_linear_197_107)"
                    />
                    <path
                      d="M10.68 24.7702C10.6598 24.7215 10.6194 24.6758 10.5618 24.6365C10.5042 24.5972 10.4308 24.5653 10.347 24.5432C10.2203 24.516 10.0855 24.5014 9.94904 24.5C9.85412 24.4995 9.75997 24.5093 9.67235 24.5288C9.58474 24.5483 9.5055 24.5772 9.43944 24.6136C9.30827 24.6917 9.21671 24.7865 9.17346 24.8889C9.05748 25.1415 8.96068 25.6938 8.8766 26.5299C8.79293 27.3042 8.75072 27.9812 8.75 28.561C8.75 28.7409 8.75 28.8845 8.76703 28.9895C8.77236 29.0423 8.77873 29.0872 8.78832 29.1241C8.79477 29.1595 8.81055 29.1943 8.83515 29.2274C8.90161 29.3124 9.01665 29.3841 9.16387 29.4323C9.31772 29.4788 9.49251 29.502 9.66926 29.4993C9.89078 29.5058 10.1085 29.4668 10.2778 29.3903C10.353 29.3491 10.4114 29.3 10.4493 29.2462C10.4872 29.1924 10.5038 29.1349 10.498 29.0775C10.4876 28.9426 10.4085 28.8117 10.2693 28.6989C10.2437 28.2241 10.2697 27.749 10.347 27.2758C10.498 26.4508 10.6232 25.8346 10.7225 25.427V25.4202C10.7406 25.3067 10.7491 25.2073 10.7491 25.1137C10.755 24.9989 10.7313 24.8844 10.6789 24.7731M10.1257 24.8083L10.1374 24.8122C10.1346 24.8118 10.1316 24.8118 10.1288 24.8122"
                      fill="#3F59A3"
                    />
                  </g>
                  <defs>
                    <linearGradient
                      id="paint0_linear_197_107"
                      x1="9.85969"
                      y1={0}
                      x2="9.85969"
                      y2={30}
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#00C53C" />
                      <stop offset="0.73" stopColor="#FF8C00" />
                    </linearGradient>
                    <clipPath id="clip0_197_107">
                      <rect width={20} height={30} fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <p>
                  <b>My Wishlist </b>
                  <span>{getWishList?.data?.length || 0} items</span>
                </p>
              </div>
              {/* <div
                className="js-countdown timer-count"
                data-timer={600}
                data-labels="d:,h:,m:,s"
              >
                <span className="countdown__item">2h : 30m : 35s</span>
              </div> */}
            </div>

            {initialLoading ? (
              <div className="row">
                {Array.from({ length: 4 }).map((_, index) => (
                  <div className="col-lg-3 col-md-4 col-sm-6" key={index}>
                    <div className="listing-product-card">
                      <div className="listing-product-inner-box">
                        <div className="listing-product-slider">
                          <div className="listing-product-img">
                            <Skeleton className='skeleton-wishimg' />
                          </div>
                          <div className="listing-product-details">
                            <h4> <Skeleton height={20} /></h4>
                            <p> <Skeleton height={10} /></p>
                            <h5> <Skeleton height={20} width={120} /></h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (getWishList?.data?.length === 0) ? (
              <div className="text-center wishlist-section">
                <p>Your wishlist is empty.</p>
                <div className="wishlist-img">
                  <LazyLoadImage src="/assets/images/wishlist.png" effect="blur" />
                </div>
                <Button outlined onClick={() => navigate('/')}>Continue Shopping</Button>
              </div>
            ) : (
              <div className="row">
                {getWishList?.data?.map((item, index) => (
                  <div key={index} className="col-lg-3 col-md-3 col-6">
                    <div className="listing-product-card">
                      <div className="listing-product-inner-box">
                        <div className="listing-product-slider">
                          <Swiper
                            pagination={{ dynamicBullets: true }}
                            modules={[Pagination]}
                            className="mySwiper"
                          >
                            {item?.product?.image?.map((image, i) =>
                              <SwiperSlide key={i}>
                                <div className="listing-product-img">
                                  <LazyLoadImage
                                    src={image}
                                    loading="lazy"
                                    alt={item.product.name}
                                    style={{ width: "100%" }}
                                  />
                                </div>
                              </SwiperSlide>
                            )}
                          </Swiper>
                        </div>
                        <div className="flex wishlist-content justify-content-between" style={{ display: 'none' }}>
                          <div className="wishlist-icon"
                            style={{ cursor: "pointer" }} onClick={() => handleConfirmPopUp(item?.product?.id)}>
                            <i className="fa-solid fa-bag-shopping" />
                          </div>
                        </div>
                        <div
                          className="wishlist-icon close-icon-x"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleShowWishAlert(item)}>
                          <i className="fa-solid fa-x" />
                        </div>
                      </div>
                      <div className="listing-product-details">
                        <Link to={`/products/${item.product.slug}`}>
                          <h5>{item.product.name}</h5>
                        </Link>
                        <p className="pro-des">Product description here</p>
                        <h4>{item.product.base_price}</h4>
                        <h6 className="daily-saver-text">Daily Saver</h6>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </section>
      </main>
      <WishlistAlert handleHideWishAlert={handleHideWishAlert} showWishAlert={showWishAlert} wishData={wishData} handleRemoveWishList={handleRemoveWishList} />
    </div>
  );
};

export default WishList;
