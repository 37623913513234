import { createSlice } from "@reduxjs/toolkit";

const otpVerificationSlice = createSlice({
    name: 'otpverifiction',
    initialState: {
        addloading: false,
        verifyDeta: null,
        SuccessMessage: "",
        ErrorMessage: "",
    },
    reducers: {
        SetVerifyUser: (state, action) => {
            console.log(action.payload);
            state.verifyDeta = action.payload;
        },
        SetSuccessMessage: (state, action) => {
            state.SuccessMessage = action.payload;
            state.addloading = false
        },
        SetErrorMessage: (state, action) => {
            state.SuccessMessage = action.payload;
        },
        resetMessages: (state) => {
            state.SuccessMessage = "";
            state.ErrorMessage = "";
        },
    }
});

export const {
    SetVerifyUser,
    SetSuccessMessage,
    SetErrorMessage,
    resetMessages, } = otpVerificationSlice.actions;
export default otpVerificationSlice.reducer;
