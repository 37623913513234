import React from 'react'
import { Link } from 'react-router-dom'

const ContectUs = () => {
    return (
        <>
            <section className="page-title">
                <div className="pattern-layer"></div>
                <div className="container-full">
                    <div className="content-box">
                        <h3>Contact Page</h3>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item active" aria-current="page"><Link to='/'>Home</Link></li>
                                <li className="breadcrumb-item" aria-current="page">/</li>
                                <li className="breadcrumb-item" aria-current="page">Contact-us</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </section>

            <section className='details-wrapper'>
                <div className="container">
                    <div className="details-wrapper-inner">
                        <div className="row">
                            <div className="col-12 mb-3">
                                <h3 className='mb-2'>Let's Get In Touch</h3>
                                <p>If you've got fantastic products or want to collaborate, reach out to us.</p>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 order-lg-0 order-md-0 order-2">
                                <div className="contact-form">
                                    <form action="">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="mb-3">
                                                    <label htmlFor="name" className="form-label">Your Name</label>
                                                    <input type="text" className="form-control contact-form-control" id="name" placeholder="Type Your Name..." />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="Email" className="form-label">Email address</label>
                                                    <input type="email" className="form-control contact-form-control" id="Email" placeholder="Type Your Email..." />
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="phone" className="form-label">Phone Number</label>
                                                    <input type="number" className="form-control contact-form-control" id="Email" placeholder="Type Your Phone..." />
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="YearMessage" className="form-label">Your Message</label>
                                                    <textarea className="form-control textarea-form-control" id="YearMessage" rows={3} defaultValue={""} placeholder="Type year message" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-end">
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <button className='w-100 btn btn-primary mt-3 mb-2 '>Submit Now</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 order-lg-0 order-md-0 order-1">
                                <div className="address-details ">
                                    <ul>
                                        <li>
                                            <h4 className='mb-2'>Address</h4>
                                            <h6 className='mb-2'>11, Clive Row</h6>
                                            <p>4th Floor</p>
                                            <p>Kolkata, India - 700001</p>
                                        </li>

                                        <li>
                                            <h4 className='mb-2'>Call To Us</h4>
                                            <p className='mb-2'>We are available 24/7, 7 days a week.</p>
                                            <p><strong>Phone :</strong> +91 8374320725</p>
                                        </li>

                                        <li>
                                            <h4 className='mb-2'>Write To US</h4>
                                            <p className='mb-2'>Fill out our form and we will contact you within 24 hours.</p>
                                            <p><strong>info@indiazona.com</strong></p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='map-location'>
                <div className='live-location'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d230.25029739864428!2d88.3497684672398!3d22.578925355359704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a0277bba65021b9%3A0x74dc710f17de75f0!2s11%2C%20Clive%20Row%2C%20China%20Bazar%2C%20Kolkata%2C%20West%20Bengal%20700001!5e0!3m2!1sen!2sin!4v1724822425310!5m2!1sen!2sin" width="100%" height="550" style={{ border: "0;" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div className="map-overlay">
                    <div className="prt-map-location">
                        <h4 className='mb-2'>Find us</h4>
                        <p>11, Clive Row, 4th Floor, Kolkata, India - 700001</p>
                        <ul className='map-list'>
                            <li><strong>E-mail :</strong> <a href="info@indiazona.com" class="prt-maplink">info@indiazona.com</a></li>
                            <li><strong>Phone number :</strong> <a href="+91 8374320725" class="prt-maplink">+91 8374320725</a></li>
                        </ul>
                    </div>
                    <div class="prt-map-location mt-3">
                        <h4 className='mb-2'>Business hours</h4>
                        <ul class="map-list">
                            <li><strong>Mon-Sat : </strong><span>9am to 6pm</span></li>
                            <li><strong>Sun : </strong> <span>closed</span></li>
                        </ul>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ContectUs