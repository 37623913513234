import { Clock, Clock1, Cloudy, Gem, Leaf, ShipWheel } from 'lucide-react'
import React from 'react'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

const Aboutus = () => {

    const teamMembers = [
        { id: 1, name: 'Rajesh Pagariya', designation: 'Founder', image: "/assets/images/clients/client-1.jpg" },
        { id: 2, name: 'Bhavana Pagariya', designation: 'CEO', image: '/assets/images/clients/client-2.jpg' },
        { id: 3, name: 'Harsh Pagariya', designation: 'Co-Founder', image: '/assets/images/clients/client-3.jpg' },
        { id: 4, name: 'Helen Tompson', designation: 'Product Manager', image: '/assets/images/clients/client-4.jpg' },
        { id: 5, name: 'Shubham', designation: 'Tech', image: '/assets/images/clients/client-5.jpg' },
        { id: 6, name: 'Edna Barton', designation: 'Chief Operating Officer', image: '/assets/images/clients/client-6.jpg' },
    ]


    return (
        <>
            <section className="page-title">
                <div className="pattern-layer"></div>
                <div className="container-full">
                    <div className="content-box">
                        <h3>About Page</h3>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item active" aria-current="page"><Link to='/'>Home</Link></li>
                                <li className="breadcrumb-item" aria-current="page">/</li>
                                <li className="breadcrumb-item" aria-current="page">About-us</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </section>

            <section className="section-b-space">
                <div className="custom-container container">
                    <div className="row align-items-center gy-4">
                        <div className="col-lg-6 order-1 order-lg-1 ratio_55  col-sm-12">
                            <div className="about-img">
                                <div className="about-tag d-lg-flex d-md-flex d-none">
                                    <a href="" className='text-center'>
                                        <h5>Women</h5>
                                        <i className="fa-solid fa-arrow-right" />
                                    </a>
                                </div>
                                <img className="bg-img img-fluid" src="/assets/images/about-women.jpg" alt />
                            </div>
                        </div>
                        <div className="col-lg-6 order-2 order-lg-2">
                            <div className="about-content">
                                <h3 className='mb-3'>Here's the newest fashion. </h3>
                                <p className='mb-3'>With increased awareness about environmental issues, sustainable fashion has gained traction.
                                    Women are embracing eco-friendly materials, upcycling, and supporting brands with
                                    transparent supply chains. Layering isn't just about staying warm—it's a styling technique
                                    that adds depth and dimension to outfits. Lightweight cardigans, duster coats, and scarves
                                    are essential layering pieces that can easily transition from day to night.</p>
                                <ul>
                                    <li className=''>
                                        <Cloudy className="iconsax" />
                                        <div>
                                            <h6>Soft Fabric</h6>
                                            <p>Get complimentary ground shipping on every order.Don’t love it? Send it back, on
                                                us.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <Clock1 className="iconsax" />
                                        <div>
                                            <h6>All Day Comfort</h6>
                                            <p>We believe getting dressed should be the easiest part of your day.</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 order-4 order-lg-3">
                            <div className="about-content about-content-1">
                                <h3 className='mb-3'>Mastering Men's Fashion</h3>
                                <p className='mb-3'>Start with foundational pieces like well-fitted jeans, classic white shirts, and versatile
                                    jackets. These basics form the backbone of your wardrobe, allowing for endless mix-and-match
                                    possibilities.Whether it's a suit or a simple button-down shirt, proper tailoring can
                                    elevate your look from average to exceptional. Invest in alterations to ensure your clothes
                                    fit impeccably, enhancing your silhouette and boosting your confidence.</p>
                                <ul>
                                    <li className=''>
                                        <Cloudy className="iconsax" />
                                        <div>
                                            <h6>Soft Fabric</h6>
                                            <p>Get complimentary ground shipping on every order.Don’t love it? Send it back, on
                                                us.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <Clock1 className="iconsax" />
                                        <div>
                                            <h6>All Day Comfort</h6>
                                            <p>We believe getting dressed should be the easiest part of your day.</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 order-3 order-lg-4 ratio_55">
                            <div className="about-img about-img-1">
                                <div className="about-tag d-lg-flex d-md-flex d-none">
                                    <a href="">
                                        <h5>Men</h5>
                                        <i className="fa-solid fa-arrow-right" />
                                    </a>
                                </div>
                                <img className="bg-img img-fluid" src="/assets/images/about-men.jpg" alt />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-b-space layout-light">
                <div className="custom-container container">
                    <div className="row gy-4">
                        <div className="col-12 mb-0">
                            <div className="about-title">
                                <h3 className='mb-2'>Superiority is our first priority.</h3>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="about-icon">
                                <div className="iconsax">
                                    <Leaf />
                                </div>
                                <h5 className='mb-2'>Superior Substances</h5>
                                <p>Our sportswear is precisely engineered to provide unparalleled comfort and durability, using
                                    quality fabrics in its expert construction.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="about-icon">
                                <div className="iconsax">
                                    <Gem />
                                </div>
                                <h5 className='mb-2'>Simple Style</h5>
                                <p>Elegant simplicity. Our sportswear exudes effortless flair that communicates volumes,
                                    embodying the essence of minimalistic design.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="about-icon">
                                <div className="iconsax">
                                    <ShipWheel />
                                </div>
                                <h5 className='mb-2'>Different Dimensions</h5>
                                <p>With a broad selection of sizes and shapes, our sportswear encourages diversity and
                                    celebrates the beauty of individuality, catering to all body types.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-b-space pb-0 fashion-girl">
                <div className="custom-container container-fluid">
                    <div className="row gy-4 align-items-end">
                        <div className="col-12 mt-0">
                            <div className="about-title">
                                <h3 className='mb-2'>Superiority is our first priority.</h3>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="fashion-box-1">
                                <p className='mb-3'>Quisquemos sodales suscipit tortor ditaemcos condimentum de cosmo lacus meleifend menean
                                    diverra loremous. Nullam sit amet orci rutrum risus laoreet semper vel non magna. Mauris vel
                                    sem a lectus vehicula ultricies. Etiam semper sollicitudin lectus indous scelerisque...</p>
                                <div>
                                    <a href="">Let's Check this out <i className="fa-solid fa-arrow-right-long" /></a>
                                </div>
                                <img className="img-fluid mt-3" src="/assets/images/fashion-1.jpg" alt />
                            </div>
                        </div>
                        <div className="col-md-4 d-none d-md-block">
                            <div className="product-img">
                                <img className="img-fluid" src="/assets/images/fashion-2.png" alt /></div>
                        </div>
                        <div className="col-md-4">
                            <div className="fashion-box-1 fashion-item">
                                <img className="img-fluid" src="/assets/images/fashion-3.jpg" alt />
                                <div className='mb-3 mt-3'>
                                    <a href="">Let's Check this out <i className="fa-solid fa-arrow-right-long" /></a>
                                </div>
                                <p className='mb-3'>Quisquemos sodales suscipit tortor ditaemcos condimentum de cosmo lacus meleifend menean
                                    diverra loremous. Nullam sit amet orci rutrum risus laoreet semper vel non magna. Mauris vel
                                    sem a lectus vehicula ultricies. Etiam semper sollicitudin lectus indous scelerisque...</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className='layout-light section-b-space'>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="about-title mb-4">
                                <h3 className='mb-2'>IndiaZona Team Members</h3>
                            </div>
                        </div>
                        <div className="col-12">
                            <Swiper
                                modules={[Autoplay]}
                                spaceBetween={30}
                                slidesPerView={6}
                                autoplay={{ delay: 3000 }}
                                grabCursor={true}
                                breakpoints={{
                                    320: { slidesPerView: 1 },
                                    640: { slidesPerView: 2 },
                                    768: { slidesPerView: 3 },
                                    991: { slidesPerView: 4 },
                                    1024: { slidesPerView: 4 },
                                    1200: { slidesPerView: 5 },
                                }}
                            >
                                {
                                    teamMembers?.map((e, i) => {
                                        return <SwiperSlide>
                                            <div className="our-team-content">
                                                <div className="team-img">
                                                    <img className="img-fluid" src={e?.image} alt />
                                                    <ul className="social-group">
                                                        <li> <a href="https://www.facebook.com/" target="_blank"><i className="fa-brands fa-facebook-f" /></a></li>
                                                        <li> <a href="https://www.facebook.com/" target="_blank"><i className="fa-brands fa-instagram"> </i></a></li>
                                                        <li> <a href="https://twitter.com/" target="_blank"><i className="fa-brands fa-x-twitter" /></a></li>
                                                    </ul>
                                                </div>
                                                <div className="team-content">
                                                    <h5>{e?.name}</h5>
                                                    <p>{e?.designation}</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    })
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>






        </>


    )
}

export default Aboutus