export const AUTH_SLICE = "auth";
export const USERS = "users";
export const PRODUCTS = "products";
export const COLOR = "colors";
export const CATEGORY = "categories";
export const CART = "carts";
export const SHORTVIDEO = "shortvideo";
export const SIDEBARFILTER = "sidebarFilter";
export const WISHLIST = "wishLists";
export const DOODLE = "doodles";
export const SEARCH = "search";
export const ORDER = "order";