import { get, post, postFormData } from '.'

const URI = "/order";

const createOrder = (payload) => {
    return postFormData(`${URI}/create`, payload);
};

const getOrderHistory = (payload) => {
    return get(`/order-history`);
}

export const OrderService = {
    createOrder,
    getOrderHistory
}
export default OrderService;
