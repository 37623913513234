import {
  LOCALSTORAGE_USER_AUTH_DETAILS_KEY,
  LOCALSTORAGE_USER_AUTH_TOKEN_KEY,
} from "../config/constants";

function getLoggedInUserToken() {
  const token = localStorage.getItem(LOCALSTORAGE_USER_AUTH_TOKEN_KEY);
  return token || null;
}

function setLoggedInUserDetails(user) {
  const existUser = JSON.parse(localStorage.getItem(LOCALSTORAGE_USER_AUTH_DETAILS_KEY))
  user.id = existUser.id
  localStorage.setItem(
    LOCALSTORAGE_USER_AUTH_DETAILS_KEY,
    JSON.stringify(user)
  );
}

function setInLocalStorage(key,value) {
  localStorage.setItem(
    key,
    JSON.stringify(value)
  );
}   

function getInLocalStorage(key) {
 return JSON.parse(localStorage.getItem(key));
}

function getLoggedInUserDetails(rawJsonString = false) {
  try {
    const userDetails = localStorage.getItem(
      LOCALSTORAGE_USER_AUTH_DETAILS_KEY
    );
    if (rawJsonString) {
      return userDetails;
    }
    return JSON.parse(userDetails);
  } catch (error) {
    return null;
  }
}

function logoutUser() {
  localStorage.removeItem(LOCALSTORAGE_USER_AUTH_TOKEN_KEY);
  localStorage.removeItem(LOCALSTORAGE_USER_AUTH_DETAILS_KEY);
}
function loginUser({ token, user }) {
  localStorage.setItem(LOCALSTORAGE_USER_AUTH_TOKEN_KEY, token);
  localStorage.setItem(
    LOCALSTORAGE_USER_AUTH_DETAILS_KEY,
    JSON.stringify(user)
  );
}

const LocalstorageService = {
  getLoggedInUserToken,
  logoutUser,
  loginUser,
  setLoggedInUserDetails,
  getLoggedInUserDetails,
  setInLocalStorage,
  getInLocalStorage
};

export default LocalstorageService;
