import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";

import { ToastContainer, Zoom } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import "./Components/Translate/Translate";
import { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ToastProvider from "./Components/Toast/ToastProvider";
import configStore from "./config/store";
import 'react-toastify/dist/ReactToastify.css';
const store = configStore();
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <SkeletonTheme baseColor="#E0E0E0" highlightColor="#F0F0F0">
      <BrowserRouter>
        <ToastProvider>
          <App />
          <ToastContainer
            position={"top-right"}
            autoClose={3000}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            transition={Zoom}
            theme="colored"
          />
        </ToastProvider>
      </BrowserRouter>
    </SkeletonTheme>
  </Provider>
  // </React.StrictMode>
);
