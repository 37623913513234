import { post } from ".";

const URI = "/products/";

const getSidebarFilter = (payload) => {
    const URL = `${URI}get-filter-sidebar`;
    return post(URL, payload);
};

const sidebarFilterService = {
    getSidebarFilter,
}
export default sidebarFilterService;