import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import LocalstorageService from '../../helpers/localstorage-services';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../../middlewares/cart/cart';
import { LOCALSTORAGE_WISHLIST_KEY } from '../../config/constants';
import { resetDeleteProductWishList, updateWishListCount } from '../../slices/wishlist.slice';
import toast from 'react-hot-toast';
import { Check } from 'lucide-react';

const WishlistAlert = ({ showWishAlert, handleHideWishAlert, wishData, handleRemoveWishList }) => {
    const dispatch = useDispatch();
    const user = LocalstorageService.getLoggedInUserDetails();
    const { getWishList, deleteProductWishListLoading } = useSelector((state) => state.wishlist);
    const { cartAddError, cartAddLoading, cartAddMessage } = useSelector(state => state.cart);
    const [selectedColor, setSelectedColor] = useState('');
    const [variant, setVariant] = useState({
        color: '',
        size: '',
    });
    const [err, setErr] = useState({
        color_err: '',
        size_err: '',
    });

    useEffect(() => {
        const wishlist = LocalstorageService.getInLocalStorage(LOCALSTORAGE_WISHLIST_KEY) || [];
        dispatch(updateWishListCount(wishlist?.length));
    }, [getWishList]);

    useEffect(() => {
        if (cartAddMessage) {
            toast.success(cartAddMessage);
            dispatch(resetDeleteProductWishList());
        }
    }, [cartAddMessage]);

    // Validation function
    const validateSelection = () => {
        let hasError = false;
        const newErr = { color_err: '', size_err: '' };

        // Validate color selection
        if (!variant.color) {
            newErr.color_err = 'Please select color';
            hasError = true;
        }

        // Validate size selection if sizes are available for the selected color
        const selectedColorData = wishData?.product?.variant?.colors?.find((o) => o.color === variant.color);
        if (selectedColorData?.sizes?.length > 0 && !variant.size) {
            newErr.size_err = 'Please select size';
            hasError = true;
        }

        setErr(newErr);
        return !hasError; // Return true if no errors
    };

    const handleAddCart = (pId) => {
        if (!validateSelection()) return; // Return if validation fails

        const cartFormData = new FormData();
        cartFormData.append('user_id', user?.id);
        cartFormData.append('product_id', pId);
        cartFormData.append('quantity', '1');
        cartFormData.append('variant', JSON.stringify([{
            color: variant.color,
            size: variant.size
        }]));

        dispatch(addToCart(cartFormData));
        handleRemoveWishList(pId);
    };

    const handleColorChange = (e) => {
        const newColor = selectedColor === e.target.value ? '' : e.target.value;
        const selectedColorData = wishData?.product?.variant?.colors?.find((o) => o.color === newColor);

        const hasAvailableSizes = selectedColorData?.sizes?.some((elem) => elem?.stock > 0);

        setSelectedColor(newColor);
        setVariant({
            color: newColor,
            size: hasAvailableSizes ? variant.size : '',
        });

        setErr({ ...err, size_err: '' });
    };

    const handleSizeChange = (e) => {
        setVariant({
            ...variant,
            size: e.target.value,
        });

        setErr({ ...err, size_err: '' });
    };

    return (
        <div>
            <Modal show={showWishAlert} onHide={handleHideWishAlert} centered animation>
                <Modal.Body>
                    <div className='wishlist-alert'>
                        <div className='row'>
                            <div className='col-4'>
                                <img className='alert-img' src={wishData?.product?.image[0]} alt="Product" />
                            </div>
                            <div className='col-8' style={{ display: 'flex' }}>
                                <div className='d-flex flex-column gap-2'>
                                    <h6>{wishData?.product?.name}</h6>
                                    <p>₹{wishData?.product?.indiazona_price} <del className='mx-1'> ₹{wishData?.product?.price}</del> <span className='text-success'>({wishData?.product?.discount}%)</span></p>
                                    <div>
                                        <div className='d-flex gap-2'>
                                            <p>Color</p>
                                            <select className='d-flex flex-wrap gap-2' onChange={handleColorChange}>
                                                <option value="">Color</option>
                                                {wishData?.product.variant?.colors?.map((elem, i) => (
                                                    <option
                                                        key={i}
                                                        value={elem.color}
                                                    >
                                                        {elem.name}
                                                    </option>

                                                ))}
                                            </select>
                                        </div>
                                        {err.color_err && <p className='text-danger'>{err.color_err}</p>}
                                    </div>
                                    <div>
                                        <div className='d-flex gap-2'>
                                            {selectedColor &&
                                                wishData?.product?.variant?.colors
                                                    ?.find((o) => o.color === selectedColor)
                                                    ?.sizes?.some((elem) => elem?.stock > 0) && (
                                                    <>
                                                        <p>Sizes</p>
                                                        <select className="" onChange={handleSizeChange} value={variant.size}>
                                                            <option value="">Size</option>
                                                            {wishData?.product?.variant?.colors
                                                                ?.find((o) => o.color === selectedColor)
                                                                ?.sizes?.map((elem, i) => (
                                                                    elem?.stock > 0 ? (
                                                                        <option key={i} className="li-circle">
                                                                            {elem?.size}
                                                                        </option>
                                                                    ) : null
                                                                ))}
                                                        </select>
                                                        
                                                    </>
                                                )}
                                        </div>
                                        {err.size_err && <p className='text-danger'>{err.size_err}</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='alert-btn mt-3'>
                            <Button severity="secondary" raised onClick={() => handleRemoveWishList(wishData?.product?.id)} disabled={deleteProductWishListLoading}>
                                {deleteProductWishListLoading ? 'Processing...' : 'Remove'}
                            </Button>
                            <Button severity="info" raised onClick={() => handleAddCart(wishData?.product?.id)} disabled={cartAddLoading}>
                                {cartAddLoading ? 'Processing...' : 'Move To Cart'}
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default WishlistAlert;
