import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ToastService from '../../../helpers/toast-services';
import { resetMessages } from '../../../slices/addressSlice';
import * as Yup from "yup";
import { fetchAddress, fetchCities, fetchCountries, fetchStates } from '../../../middlewares/UserProfile/address';
import { Modal } from 'react-bootstrap';
import {
    validAddress,
    country_id,
    state_id,
    city_id,
    postal_code,
    phone_number
} from "../../../helpers/yup.validation.schema";
import PhoneInput from 'react-phone-input-2';

const AddressModel = ({ initialData, addressId, onSubmit, onHide, mode, show }) => {
    const { countries, states, cities, SuccessMessage, ErrorMessage, userAddress, addAddress, deleteUAddress, updateAddress } = useSelector((state) => state.address);
    const dispatch = useDispatch();

    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [countryCode, setCountryCode] = useState(null)

    useEffect(() => {
        if (SuccessMessage) {
            ToastService.success(SuccessMessage);
        }
        if (ErrorMessage) {
            ToastService.error(ErrorMessage);
        }
    }, [SuccessMessage, ErrorMessage]);

    useEffect(() => {
        dispatch(fetchCountries());
    }, [dispatch]);

    useEffect(() => {
        if (selectedCountry) {
            dispatch(fetchStates(selectedCountry));
        }
    }, [selectedCountry, dispatch]);

    useEffect(() => {
        if (selectedState) {
            dispatch(fetchCities(selectedState));
        }
    }, [selectedState, dispatch]);

    useEffect(() => {
        dispatch(fetchAddress());
    }, [dispatch, addAddress, updateAddress, deleteUAddress]);

    const user_deta = JSON.parse(localStorage.getItem('IN_USER_DATA'));
    const user_id = user_deta?.id;

    const validationSchema = Yup.object({
        address: validAddress,
        country_id: country_id,
        state_id: state_id,
        city_id: city_id,
        postal_code: postal_code,
        phone: phone_number
    });
    const handleSubmit = (values) => {
        const phoneNumberWithoutCode = values.phone.split(countryCode)[1];
        const payload = {
            address: values.address,
            city_id: values.city_id,
            country_id: values.country_id,
            id: values.id,
            phone: phoneNumberWithoutCode === undefined ? initialData.phone : phoneNumberWithoutCode,
            phone_code: values.phone_code,
            postal_code: values.postal_code,
            state_id: values.state_id,
            user_id: values.user_id
        };
        onSubmit(payload);
        formik.resetForm();
        onHide();
    };

    const formik = useFormik({
        initialValues: {
            id: initialData ? initialData.id : addressId,
            phone_code: initialData ? initialData.phone_code : '',
            user_id: user_id,
            address: initialData ? initialData.address : '',
            country_id: initialData ? initialData.country_id : '',
            state_id: initialData ? initialData.state_id : '',
            city_id: initialData ? initialData.city_id : '',
            postal_code: initialData ? initialData.postal_code : '',
            phone: initialData ? initialData.phone_code + initialData.phone : '',
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: handleSubmit,
    });

    const handleClose = () => {
        formik.resetForm();
        setSelectedCountry('');
        setSelectedState('');
        onHide();
    };

    const handlePhoneChange = (value, country) => {
        const phoneCode = country.dialCode;
        setCountryCode(phoneCode)
        formik.setFieldValue('phone_code', `+${country.dialCode}`);
        formik.setFieldValue('phone', value);
    };

    const hasChanges = (values) => {
        return Object.keys(formik.initialValues).some(key => formik.initialValues[key] !== values[key]);
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="xl"
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            keyboard={false}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{mode === 'add' ? 'Add New Address' : 'Edit Address'}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='d-flex justify-content-center'>
                <form
                    onSubmit={formik.handleSubmit}
                    className="CreateAccount-Form"
                >
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="password-input mb-3">
                                <textarea style={{ height: "100px" }}
                                    className="p-2 form-control"
                                    type="text-area"
                                    name="address"
                                    value={formik.values.address}
                                    onChange={formik.handleChange}
                                    placeholder="New address *">
                                </textarea>
                                <div className="error"> {formik.errors.address}</div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="password-input mb-2">
                                <select
                                    className="form-control"
                                    name='country_id'
                                    value={formik.values.country_id}
                                    onChange={e => {
                                        formik.handleChange(e);
                                        setSelectedCountry(e.target.value);
                                    }}
                                >
                                    <option value="">Select country*</option>
                                    {countries?.map((country_id, index) => (
                                        <option value={country_id.id} key={index}> {country_id.name}</option>
                                    ))}
                                </select>
                                <div className="error"> {formik.errors.country_id}</div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="password-input mb-2">
                                <select
                                    className="form-control"
                                    name='state_id'
                                    value={formik.values.state_id}
                                    onChange={e => {
                                        formik.handleChange(e);
                                        setSelectedState(e.target.value);
                                    }}
                                >
                                    <option value="">Select state*</option>
                                    {states?.map((state_id, index) => (
                                        <option value={state_id.id} key={index}>{state_id.name}</option>
                                    ))}
                                </select>
                                <div className="error"> {formik.errors.state_id}</div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="password-input mb-2">
                                <select
                                    className="form-control"
                                    name='city_id'
                                    value={formik.values.city_id}
                                    onChange={formik.handleChange}
                                >
                                    <option value="">Select city*</option>
                                    {cities?.map((city_id, index) => (
                                        <option value={city_id.id} key={index}>{city_id.name}</option>
                                    ))}
                                </select>
                                <div className="error"> {formik.errors.city_id}</div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="password-input mb-2">
                                <input
                                    className="m-0 form-control"
                                    name="postal_code"
                                    placeholder="postal_code *"
                                    value={formik.values.postal_code}
                                    onChange={formik.handleChange}
                                />
                                <div className="error"> {formik.errors.postal_code}</div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="password-input mb-2">
                                <PhoneInput
                                    inputClass='w-100 phone-flag-input'
                                    inputExtraProps={{
                                        name: 'phone',
                                        required: true,
                                        autoFocus: true,
                                    }}
                                    country={'in'}
                                    value={formik.values.phone}
                                    onChange={handlePhoneChange}
                                    placeholder="Phone no *"
                                />
                                <div className="error"> {formik.errors.phone}</div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="row justify-content-end">
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <button type="submit" className="address-btn Login-btn w-100" disabled={mode === 'edit' ? !hasChanges(formik.values) : null}>
                                        {mode === 'add' ? 'Add Address' : 'Update Address'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default AddressModel;
