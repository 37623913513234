import { createSlice } from "@reduxjs/toolkit";
import { SIDEBARFILTER } from "../helpers/slice.name";

export const sidebarFilterSlice = createSlice({
    name: SIDEBARFILTER,
    initialState: {
        loading: false,

        sidebarFilterLoading: false,
        sidebarFilter: [],
        sidebarFilterMessage: "",
        sidebarFilterError: "",
    },

    reducers: {
        sidebarFilterRequest: (state) => {
            state.sidebarFilterLoading = true;
            state.sidebarFilterMessage = "";
            state.sidebarFilterError = "";
        },
        sidebarFilterSuccess: (state, action) => {
            state.sidebarFilterLoading = false;
            state.sidebarFilter = action.payload.data;
            state.sidebarFilterMessage = action.payload.message;
        },
        sidebarFilterError: (state, action) => {
            state.sidebarFilterLoading = false;
            state.sidebarFilterError = action.payload;
        },
    }
});

export const { sidebarFilterRequest, sidebarFilterSuccess, sidebarFilterError } = sidebarFilterSlice.actions;

export default sidebarFilterSlice.reducer;
