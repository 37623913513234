import { createSlice } from "@reduxjs/toolkit";
import { CART } from "../helpers/slice.name";

export const cartSlice = createSlice({
    name: CART,
    initialState: {
        loading: false,

        cartLoading: false,
        cart: [],
        cartCount: 0,
        cartMessage: "",
        cartError: "",

        cartAddLoading: false,
        cartAdd: '',
        cartAddMessage: "",
        cartAddError: "",

        cartRemoveLoading: false,
        cartRemove: '',
        cartRemoveMessage: "",
        cartRemoveError: "",

        cartUpdateLoading: false,
        cartUpdate: '',
        cartUpdateMessage: "",
        cartUpdateError: "",
    },
    reducers: {
        cartRequest: (state) => {
            state.cartLoading = true;
            state.cartMessage = "";
            state.cartError = "";
        },
        cartSuccess: (state, action) => {
            state.cartLoading = false;
            state.cart = action.payload.data;
            state.cartMessage = action.payload.message;
        },
        cartError: (state, action) => {
            state.cartLoading = false;
            state.cartError = action.payload.message;
        },

        cartAddRequest: (state, action) => {
            state.cartAddLoading = true;
            state.cartAddMessage = "";
            state.cartAddError = "";
        },
        cartAddSuccess: (state, action) => {
            state.cartAddLoading = false;
            state.cartAdd = action.payload.data;
            state.cartAddMessage = action.payload.message;
        },
        cartAddError: (state, action) => {
            state.cartAddLoading = false;
            state.cartAddError = action.payload.message;
        },
        resetAddedCartList: (state) => {
            state.cartAddError = "";
            state.cartAddMessage = "";
        },
        cartRemoveRequest: (state, action) => {
            state.cartRemoveLoading = true;
            state.cartRemoveMessage = "";
            state.cartRemoveError = "";
        },
        cartRemoveSuccess: (state, action) => {
            state.cartRemoveLoading = false;
            state.cartRemove = action.payload.data;
            state.cartRemoveMessage = action.payload.message;
        },
        cartRemoveError: (state, action) => {
            state.cartRemoveLoading = false;
            state.cartRemoveError = action.payload.message;
        },
        resetDeleteCartList: (state) => {
            state.cartRemoveError = "";
            state.cartRemoveMessage = "";
        },
        cartUpdateRequest: (state) => {
            state.cartUpdateLoading = true;
            state.cartUpdateMessage = "";
            state.cartUpdateError = "";
        },
        cartUpdateSuccess: (state, action) => {
            state.cartUpdateLoading = false;
            state.cartUpdate = action.payload.data;            
            state.cartUpdateMessage = action.payload.message;
        },
        cartUpdateError: (state, action) => {
            state.cartUpdateLoading = false;
            state.cartUpdateError = action.payload.message;
        },
        resetUpdateCart: (state) => {
            state.cartUpdateError = "";
            state.cartUpdateMessage = "";
        },
        updateCartCount: (state, action) => {
            state.cartCount = action.payload;
        },
    }
})

export const {
    cartRequest,
    cartSuccess,
    updateCartCount,
    cartError,
    cartAddRequest,
    cartAddSuccess,
    resetAddedCartList,
    cartAddError,
    cartRemoveRequest,
    cartRemoveSuccess,
    cartRemoveError,
    resetDeleteCartList,

    cartUpdateRequest,
    cartUpdateSuccess,
    cartUpdateError,
    resetUpdateCart

} = cartSlice.actions;

export default cartSlice.reducer;