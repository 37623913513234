import React from 'react'
import PageTitle from '../Components/Products/PageTitle';
import Footer from '../Components/Common/Footer';

const CompareProduct = () => {
    return (
        <>
            <main>
                <PageTitle name={'Compare Products'} />
                <section className="flat-spacing-12">
                    <div>
                        <div className="container">
                            <div className="tf-compare-table">

                                <div className="tf-compare-row tf-compare-grid">
                                    <div className="tf-compare-col d-md-block d-none"></div>
                                    <div className="tf-compare-col">

                                        <div className="tf-compare-item">
                                            <div className="tf-compare-remove link">Remove</div>
                                            <a className="tf-compare-image" href>
                                                <img className=" ls-is-cached lazyloaded" data-src="/assets/images/Product/pro1.jpg" src="/assets/images/Product/pro1.jpg" alt="img-compare" />
                                            </a>
                                            <a className="tf-compare-title" href>Regular Fit Oxford Shirt</a>
                                            <div className="price">
                                                <span>₹</span>799.99
                                            </div>
                                            <div className="tf-compare-group-btns d-flex gap-10">
                                                <a href='#' className="tf-btn btn-outline-dark radius-3">
                                                    <i className="fa-regular fa-eye" />
                                                    <span>QUICK VIEW</span></a>
                                                <a href="#" className="tf-btn btn-outline-dark radius-3">
                                                    <i className="fa-solid fa-bag-shopping" />
                                                    <span>QUICK ADD</span></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tf-compare-col">
                                        <div className="tf-compare-item">
                                            <div className="tf-compare-remove link">Remove</div>
                                            <a className="tf-compare-image" href>
                                                <img className=" ls-is-cached lazyloaded" data-src="/assets/images/Product/pro16.jpg" src="/assets/images/Product/pro16.jpg" alt="img-compare" />
                                            </a>
                                            <a className="tf-compare-title" href>Boxy T-shirt</a>
                                            <div className="price">
                                                <span>₹</span>799.99
                                            </div>
                                            <div className="tf-compare-group-btns d-flex gap-10">
                                                <a href="#quick_view" className="tf-btn btn-outline-dark radius-3">
                                                    <i className="fa-regular fa-eye" />
                                                    <span>QUICK VIEW</span></a>
                                                <a href="#quick_add" className="tf-btn btn-outline-dark radius-3">
                                                    <i className="fa-solid fa-bag-shopping" />
                                                    <span>QUICK ADD</span></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tf-compare-col">
                                        <div className="tf-compare-item">
                                            <div className="tf-compare-remove link">Remove</div>
                                            <a className="tf-compare-image" href>
                                                <img className=" ls-is-cached lazyloaded" data-src="/assets/images/Product/pro11.jpg" src="/assets/images/Product/pro11.jpg" alt="img-compare" />
                                            </a>
                                            <a className="tf-compare-title" href>Drawstring-detail Sports Tank
                                                Top</a>
                                            <div className="price">
                                                <span>₹</span>799.99
                                            </div>
                                            <div className="tf-compare-group-btns d-flex gap-10">
                                                <a href="#quick_view" className="tf-btn btn-outline-dark radius-3">
                                                    <i className="fa-regular fa-eye" />
                                                    <span>QUICK VIEW</span></a>
                                                <a href="#quick_add" className="tf-btn btn-outline-dark radius-3">
                                                    <i className="fa-solid fa-bag-shopping" />
                                                    <span>QUICK ADD</span></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tf-compare-col">
                                        <div className="tf-compare-item">
                                            <div className="tf-compare-remove link">Remove</div>
                                            <a className="tf-compare-image" href>
                                                <img className=" ls-is-cached lazyloaded" data-src="/assets/images/Product/pro10.jpg" src="/assets/images/Product/pro10.jpg" alt="img-compare" />
                                            </a>
                                            <a className="tf-compare-title" href>Regular Fit Oxford Shirt</a>
                                            <div className="price">
                                                <span>₹</span>799.99
                                            </div>
                                            <div className="tf-compare-group-btns d-flex gap-10">
                                                <a href="#quick_view" className="tf-btn btn-outline-dark radius-3">
                                                    <i className="fa-regular fa-eye" />
                                                    <span>QUICK VIEW</span></a>
                                                <a href="#quick_add" className="tf-btn btn-outline-dark radius-3">
                                                    <i className="fa-solid fa-bag-shopping" />
                                                    <span>QUICK ADD</span></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tf-compare-col">
                                        <div className="tf-compare-item">
                                            <div className="tf-compare-remove link">Remove</div>
                                            <a className="tf-compare-image" href>
                                                <img className=" ls-is-cached lazyloaded" data-src="/assets/images/Product/pro1.jpg" src="/assets/images/Product/pro1.jpg" alt="img-compare" />
                                            </a>
                                            <a className="tf-compare-title" href>Regular Fit Oxford Shirt</a>
                                            <div className="price">
                                                <span>₹</span>799.99
                                            </div>
                                            <div className="tf-compare-group-btns d-flex gap-10">
                                                <a href="#quick_view" className="tf-btn btn-outline-dark radius-3">
                                                    <i className="fa-regular fa-eye" />
                                                    <span>QUICK VIEW</span></a>
                                                <a href="#quick_add" className="tf-btn btn-outline-dark radius-3">
                                                    <i className="fa-solid fa-bag-shopping" />
                                                    <span>QUICK ADD</span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tf-compare-row">
                                    <div className="tf-compare-col tf-compare-field d-md-block d-none">
                                        <h6>Availability</h6>
                                    </div>
                                    <div className="tf-compare-col tf-compare-field tf-compare-stock">
                                        <div className="icon">
                                            <i className="fa-regular fa-circle-check" />
                                        </div>
                                        <span className="fw-5">In Stock</span>
                                    </div>
                                    <div className="tf-compare-col tf-compare-field tf-compare-stock">
                                        <div className="icon">
                                            <i className="fa-regular fa-circle-xmark text-danger" />
                                        </div>
                                        <span className="fw-5 text-danger">Out of Stock</span>
                                    </div>
                                    <div className="tf-compare-col tf-compare-field tf-compare-stock">
                                        <div className="icon">
                                            <i className="fa-regular fa-circle-check" />
                                        </div>
                                        <span className="fw-5">In Stock</span>
                                    </div>
                                    <div className="tf-compare-col tf-compare-field tf-compare-stock">
                                        <div className="icon">
                                            <i className="fa-regular fa-circle-check" />
                                        </div>
                                        <span className="fw-5">In Stock</span>
                                    </div>
                                    <div className="tf-compare-col tf-compare-field tf-compare-stock">
                                        <div className="icon">
                                            <i className="fa-regular fa-circle-check" />
                                        </div>
                                        <span className="fw-5">In Stock</span>
                                    </div>
                                </div>
                                <div className="tf-compare-row">
                                    <div className="tf-compare-col tf-compare-field d-md-block d-none">
                                        <h6>Vendor</h6>
                                    </div>
                                    <div className="tf-compare-col tf-compare-value text-center">Ecomus</div>
                                    <div className="tf-compare-col tf-compare-value text-center">M&amp;H</div>
                                    <div className="tf-compare-col tf-compare-value text-center">Ecomus</div>
                                    <div className="tf-compare-col tf-compare-value text-center">M&amp;H</div>
                                    <div className="tf-compare-col tf-compare-value text-center">M&amp;H</div>
                                </div>
                                <div className="tf-compare-row">
                                    <div className="tf-compare-col tf-compare-field d-md-block d-none">
                                        <h6>Color</h6>
                                    </div>
                                    <div className="tf-compare-col tf-compare-value text-center">Grey, Pink, Light Pink, White</div>
                                    <div className="tf-compare-col tf-compare-value text-center">-</div>
                                    <div className="tf-compare-col tf-compare-value text-center">Orange, Black, White</div>
                                    <div className="tf-compare-col tf-compare-value text-center">Orange, Black, White</div>
                                    <div className="tf-compare-col tf-compare-value text-center">Light Green, Black, Blue, Dark
                                        Blue, White, Light Grey</div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}

export default CompareProduct