import React from 'react'

const PageTitle = ({ name }) => {
    return (
        <section className="page-title">
            <div className="pattern-layer"></div>
            <div className="container-full">
                <div className="content-box">
                    <h3>{name}</h3>
                </div>
            </div>
        </section>

    )
}

export default PageTitle