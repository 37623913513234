import { get, post, postFormData } from '.'

const URL = '/userprofile';

const getInfo = () => {
    return get('/customer/info')
}
const updateprofile = (payload) => {
    return postFormData(`${URL}/update`, payload)
}

export const profileservice = {
    updateprofile,
    getInfo
}