import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { getOrderData } from '../../middlewares/cart/order';

const OrderSuccessPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { getOrderHistory } = useSelector((state) => state.order);
    useEffect(() => {
        dispatch(getOrderData())
    }, [])
    return (
        <section className="order-section my-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 d-flex justify-content-center align-items-center">
                        <div className="order-wpr">
                            <div className="order-check-icon text-center">
                                <i className="fa-solid fa-check" />
                            </div>
                            <div className="order-details">
                                <h4 className="text-center">Order Success</h4>
                                <p className="text-center">
                                    Your order has been successfully placed! Thank you for your purchase.
                                </p>
                                <h5 className="text-center"><small>Order ID:</small> <b>{getOrderHistory[0]?.code}</b></h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="card mt-4 order-content-summary">
                            <p>
                                Thanks for your order! We’ll send you a confirmation email and text. You’ll be notified
                                when your package is on the way. Track it anytime through your Account page or the link
                                in the footer.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center mt-lg-5 mt-md-4 mt-3">
                    <div className="col-lg-3 col-md-4 col-sm-12">
                        <div className="continue-shopping">
                            <button className="btn common-btn w-100" type='button' onClick={() => navigate('/')}>Continue Shopping</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    )
}

export default OrderSuccessPage