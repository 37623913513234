import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTodaysDeal } from '../../middlewares/products/product';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { Eye, Heart } from 'lucide-react';

const HomeProductSlider = ({ title }) => {
    const dispatch = useDispatch();
    const { todayDeal, todayDealError, todayDealLoading, todayDealMessage } =
        useSelector((state) => state?.product);
    useEffect(() => {
        const bodyFormData = new FormData();
        bodyFormData.append("per_page", 10);
        dispatch(fetchTodaysDeal(bodyFormData));
    }, []);

    return (
        <section className="product wrapper-section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className='sub-Title'>
                            <div className='small-width'></div>
                            <p>Our Products</p>
                        </div>
                        <ul>
                            <li>
                                <h4 className="Title">Featured Products</h4>
                            </li>
                            <li>
                                <a href="" className="viewAllBtn flex">
                                    <span>See All</span>
                                    {/* <i className="fa-solid fa-arrow-right-long arrowRight" /> */}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="Products-inner">
                    <div className="row">
                        <div className="col-12">
                            <Swiper
                                // modules={[Autoplay]}
                                spaceBetween={30}
                                slidesPerView={6}
                                autoplay={{ delay: 2500 }}
                                loop={true}
                                pagination={{ clickable: true }}
                                navigation={true}
                                breakpoints={{
                                    320: { slidesPerView: 2 },
                                    640: { slidesPerView: 3 },
                                    768: { slidesPerView: 3 },
                                    1024: { slidesPerView: 4 },
                                    1200: { slidesPerView: 6 },
                                }}
                            >
                                {todayDealLoading ? Array.from({ length: 6 }).map((_, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="product-wrapper">
                                            <div className="product-box">
                                                <div className="product-inner-box">
                                                    <div className="product-img-box">
                                                        <div className='discount-div'>
                                                            <span className="green-badge">30% off</span>
                                                        </div>
                                                        <Skeleton height={290} className="mb-2" />
                                                    </div>
                                                </div>
                                                <div className="product-content">
                                                    <h6><Skeleton /></h6>
                                                    <div className="flex gap-4 mt-1 mb-1 align-items-center md-gap-2">
                                                        <h6><Skeleton /></h6>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )) : todayDeal.length > 0 ? todayDeal?.map((elem, i) => 
                                    <SwiperSlide key={i}>
                                        <div className="product-wrapper">
                                            <a
                                                href={`${elem?.item_category_slug}/product-details/${elem?.id}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <div className="product-box">
                                                    <div className="product-inner-box">
                                                        <div className="product-img-box">
                                                            <img
                                                                src={elem?.photos}
                                                                alt="Handmade Pearl Set"
                                                            />
                                                            <ol>
                                                                <li><Heart className='product-icon'/></li>
                                                                <li><Eye  className='product-icon' /></li>
                                                            </ol>
                                                        </div>
                                                        <div className='Add-To-Cart-btn'>
                                                            <button href="" className='w-100 btn'>Add To Cart</button>
                                                        </div>

                                                    </div>
                                                    <div className="product-content">
                                                        <h6>{elem?.name}</h6>
                                                        <div className='d-flex gap-2 align-items-center mt-1 mb-2'>
                                                            <div className='product-star-rating d-flex' style={{ gap: '2px' }}>
                                                                <i className="fa-solid fa-star"></i>
                                                                <i className="fa-solid fa-star"></i>
                                                                <i className="fa-solid fa-star"></i>
                                                                <i className="fa-solid fa-star"></i>
                                                                <i className="fa-solid fa-star"></i>
                                                            </div>
                                                            <div className='product--rating-number'>
                                                                <span style={{ color: '#7d7d7d' }}>(35)</span>
                                                            </div>
                                                        </div>

                                                        <div className="flex gap-4 mt-1 mb-1 align-items-center md-gap-2">
                                                            <h6>
                                                                <span style={{ fontSize: 15, padding: '2px 0px', color: '#42B649' }}>₹{elem.indiazona_price}</span>
                                                                <del style={{ fontSize: 13, color: '#FF8C00', padding: '2px 10px' }}>₹{elem.tag_price}</del>
                                                                {/* <span className="off-span" style={{ borderLeft: '1px solid #000', padding: '2px 6px', fontSize: '13', color: 'green' }}>({elem.discount}%)</span> */}
                                                            </h6>
                                                        </div>
                                                        <button className="shopNowBtn">Shop Now</button>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </SwiperSlide>
                                ) : null}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HomeProductSlider;
