import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { Film, Play } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { fetchShortVideo } from "../../middlewares/shortVideo";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import LoginModal from "../Model/LoginModal";
import { Button } from "primereact/button";

export const Shortvideo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { shortVideo, shortVideoLoading, shortVideoError } = useSelector((state) => state.shortvideo);
  const singleSortvidewo = (id) => {
    navigate("/short-videos", { state: { id: id } });
  };

  useEffect(() => {
    dispatch(fetchShortVideo());
  }, [dispatch]);

  return (
    <>
      <section className="section wrapper-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <ul>
                <li>
                  <h4 className="Title d-flex align-items-center mr-3 gap-2">
                    <span className="d-flex align-items-center justify-content-center">
                      <i className="fa-solid fa-play"></i>
                    </span>
                    Swipe & Buy{" "}
                  </h4>
                </li>
                <li>
                  <a href="/short-videos" className="viewAllBtn flex">
                    <span>See All</span>
                    {/* <i className="fa-solid fa-arrow-right-long arrowRight" /> */}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="short-video-bg">
          <div className="container-fluid">
            <div className="col-12">
              <div className="short-video">
                <div className="row shortSlider">
                  {shortVideoLoading ? (
                    <Swiper
                      // modules={[Autoplay]}
                      spaceBetween={30}
                      slidesPerView={6}
                      autoplay={{ delay: 3000 }}
                      grabCursor={true}
                      breakpoints={{
                        320: { slidesPerView: 2 },
                        640: { slidesPerView: 2 },
                        768: { slidesPerView: 3 },
                        1024: { slidesPerView: 3 },
                        1200: { slidesPerView: 6},
                      }}
                    >
                      <div className="short-video-inner">
                        <div className="videoBox">
                          {Array.from({ length: 10 }).map((_, index) => (
                            <SwiperSlide key={index}>
                              <div>
                                <Skeleton height={290} className="mb-2" />
                                <Skeleton count={2} />
                              </div>
                            </SwiperSlide>
                          ))}
                        </div>
                      </div>
                    </Swiper>
                  ) : shortVideo?.length > 0 ? (
                    <Swiper
                      modules={[Autoplay]}
                      spaceBetween={30}
                      slidesPerView={6}
                      autoplay={{ delay: 3000 }}
                      grabCursor={true}
                      breakpoints={{
                        320: { slidesPerView: 2 },
                        640: { slidesPerView: 3 },
                        768: { slidesPerView: 3 },
                        991: { slidesPerView: 4 },
                        1024: { slidesPerView: 6 },
                      }}
                    >
                      {shortVideo?.map((e, i) => (
                        <SwiperSlide key={i}>
                          <div className="short-video-inner">
                            <div className="videoBox" onClick={() => singleSortvidewo(e?.id)}>
                              {/* <video>
                              <source src={e.video1} />
                              Your browser does not support the video tag.
                            </video> */}
                              <img src={e.photos[0]} alt="Short-image" loading="lazy" className="short-vid-img" />
                              <i className="fa-solid fa-circle-play" />
                            </div>
                            <h5 className="videoTitle">{e.meta_title}</h5>
                            <p className="videoLike">{e.likes}</p>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  ) : (
                    <div className="short-video-inner">
                      <div className="videoBox">
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          No video's found
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <LoginModal visible={visible} setVisible={setVisible} /> */}
    </>
  );
};
