import React from 'react';
import { Modal, Table } from 'react-bootstrap';

const SizeChart = ({ showSizeChart, handleHideSizeChart }) => {
    return (
        <Modal show={showSizeChart} onHide={handleHideSizeChart} centered size="xl" className="tf-product-modal" id="Size-Chart">
            <Modal.Header closeButton className="py-2 border-bottom-1">
                <Modal.Title className="fs-5">Size chart</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="tf-rte">
                    <div className="tf-table-res-df">
                        <h6>Size guide</h6>
                        <Table className="tf-sizeguide-table" responsive>
                            <thead>
                                <tr>
                                    <th>Size</th>
                                    <th>US</th>
                                    <th>Bust</th>
                                    <th>Waist</th>
                                    <th>Low Hip</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>XS</td>
                                    <td>2</td>
                                    <td>32</td>
                                    <td>24 - 25</td>
                                    <td>33 - 34</td>
                                </tr>
                                <tr>
                                    <td>S</td>
                                    <td>4</td>
                                    <td>34 - 35</td>
                                    <td>26 - 27</td>
                                    <td>35 - 36</td>
                                </tr>
                                <tr>
                                    <td>M</td>
                                    <td>6</td>
                                    <td>36 - 37</td>
                                    <td>28 - 29</td>
                                    <td>38 - 40</td>
                                </tr>
                                <tr>
                                    <td>L</td>
                                    <td>8</td>
                                    <td>38 - 39</td>
                                    <td>30 - 31</td>
                                    <td>42 - 44</td>
                                </tr>
                                <tr>
                                    <td>XL</td>
                                    <td>10</td>
                                    <td>40 - 41</td>
                                    <td>32 - 33</td>
                                    <td>45 - 47</td>
                                </tr>
                                <tr>
                                    <td>XXL</td>
                                    <td>12</td>
                                    <td>42 - 43</td>
                                    <td>34 - 35</td>
                                    <td>48 - 50</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div className="tf-page-size-chart-content">
                        <div>
                            <h6>Measuring Tips</h6>
                            <div className="title">Bust</div>
                            <p>Measure around the fullest part of your bust.</p>
                            <div className="title">Waist</div>
                            <p>Measure around the narrowest part of your torso.</p>
                            <div className="title">Low Hip</div>
                            <p className="mb-0">With your feet together measure around the fullest part of your hips/rear.</p>
                        </div>
                        <div>
                            <img
                                className="sizechart lazyload"
                                data-src="images/shop/products/size_chart2.jpg"
                                src="/assets/images/size_chart2.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default SizeChart