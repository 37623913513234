import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { fetchHomeCategories } from "../../middlewares/category";
import Skeleton from "react-loading-skeleton";

const HomeCategory = () => {
  const dispatch = useDispatch();
  const { homeCategory, homeCategoryLoading } = useSelector((state) => state.category);

  useEffect(() => {
    dispatch(fetchHomeCategories());
  }, []);

  return (
    <section className="category-section">
      <nav>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="main_menu box-shadow bg-white category-menu">
                {homeCategoryLoading ? (
                  <ul style={{ padding: '13px' }}>
                    {Array.from({ length: 10 }).map((_, index) => (
                      <li key={index} style={{ padding: '13px' }}>
                        <div className="menu__img">
                          <Skeleton height={60} width={60} />
                        </div>
                        <Skeleton count={1} />
                      </li>
                    ))}
                  </ul>
                ) : (
                  <ul>
                    {homeCategory.map((category) => (
                      <li key={category.id}>
                        <Link to={`/${category.slug}/product-list`}>
                          {/* <Link to={`/${category.name}/product-list`}> */}
                          <div className="menu__img">
                            <img
                              src={category.icon}
                              alt={category.short_name}
                            />
                          </div>
                          {category.short_name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </section>
  );
};

export default HomeCategory;
