import React from 'react'

function FocusCategories() {

    const deta = [
        { id: '1', name: 'Beauty & Toys', image: '/assets/images/BeautyToys.png' },
        { id: '2', name: 'Mobiles', image: '/assets/images/Mobiles.png' },
        { id: '3', name: 'Laptops & Accessories', image: '/assets/images/LaptopsAccessories.png' },
        { id: '4', name: 'Fashion', image: '/assets/images/Fashion.png' }
    ]
    return (
        <>
            <section className='focus-categaries-section wrapper-section'>
                <div className="container">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center align-items-center">
                            <ul>
                                <li>
                                    <h4 className="Title text-center">In Focus Categories</h4>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row mt-xl-5  mt-lg-4 mt-md-3  mt-3 ">
                        {
                            deta?.map((e, i) => {
                                return <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6 mt-3" key={i}>
                                    <a href="">
                                        <div className="focus-wpr-box">
                                            <div className='focus-wpr-content'>
                                                <h6>{e.name}</h6>
                                            </div>
                                            <div className='focus-wpr-img'>
                                                <img src={e.image} alt={e.image} />
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            })
                        }

                    </div>
                </div>
            </section>
        </>
    )
}

export default FocusCategories
