import React, { useEffect, useState } from 'react'
import PageTitle from '../../Components/Products/PageTitle'
import { useDispatch, useSelector } from 'react-redux'
import { MoveLeft, MoveRight, Plus } from 'lucide-react';
import { fetchAddress, primaryAdd } from '../../middlewares/UserProfile/address';
import LocalstorageService from '../../helpers/localstorage-services';
import AddressForm from '../../Components/Model/Cart/AddressForm';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { primaryAddressReset } from '../../slices/addressSlice';
import { getCartData } from '../../middlewares/cart/cart';
import CheckOutSummary from '../../Components/Products/CheckOut/CheckOutSummary';
import ROUTE_URLS from '../../config/routes';

const ShippingAddress = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userData = LocalstorageService.getLoggedInUserDetails();
    const [showAddress, setShowAddress] = useState(false);
    const { userAddress, getAddressError, getAddressLoading, getAddressMessage, primaryAddressRequest,
        setPrimary,
        primaryAddressSuccess, SuccessMessage,
        primaryAddressFailure, } = useSelector((state) => state.address);
    const { cart } = useSelector((state) => state.cart);

    useEffect(() => {
        if (!userData?.id) {
            navigate(ROUTE_URLS.HOME, { replace: true });
        }
    }, []);


    console.log(userAddress, '23u4y2347856t')

    useEffect(() => {
        if (SuccessMessage) {
            toast.success(SuccessMessage);
            dispatch(fetchAddress());
            dispatch(primaryAddressReset());
        }
    }, [SuccessMessage]);

    useEffect(() => {
        const bodyFormData = new FormData();
        bodyFormData.append("user_id", userData?.id);
        dispatch(getCartData(bodyFormData));
    }, [])

    const handleDefault = (addressId) => {
        const payload = {
            id: addressId
        }
        dispatch(primaryAdd(payload));
    }

    useEffect(() => {
        dispatch(fetchAddress());
    }, []);

    const handleNextStep = () => {
        const findAddress = userAddress?.some((us) => us?.set_default === 1);
        if (findAddress === true) {
            navigate('/checkout/payment')
        } else {
            toast.error('Please select a default shipping address');
            return;
        }
    }

    const handleShowAddress = () => {
        setShowAddress(!showAddress);
    }

    return (
        <div>
            <main>
                <PageTitle name={'Shipping Address'} />
                <section className='checkout d-flex justify-content-center bg-white'>
                    <div className="container">
                        <div className="row">
                            <div className="delivery-header d-flex justify-content-between mt-4">
                                <div className="instruction">
                                    <h5>Delivery Address</h5>
                                    <p>We will deliver your order to this address.</p>
                                </div>
                                <p className='address-add-link'>
                                    <span onClick={() => handleShowAddress(true)}
                                        style={{ cursor: 'pointer', backgroundColor: '#3f59a3', padding: '7px', borderRadius: '5px', color: 'white' }}>
                                        <i className="fa-solid fa-plus "></i> Add New Address
                                    </span>
                                </p>
                            </div>
                            <div className="col-md-8 my-4">
                                <div className="second-step">

                                    <div className="row mt-4">
                                        {userAddress.map((address, i) => (
                                            <div key={i} className="col-md-6 mb-3">
                                                <div className={`card ${address?.set_default === 1 ? 'address-border' : ''}`} style={{ cursor: 'pointer' }}>
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-between">
                                                            <div className='d-flex justify-content-around overflow-hidden'>
                                                                <h5 className="card-title checkout-card-title w-100">{address?.customer_name || userData?.name}</h5>
                                                                {address?.set_default === 1 ? <p className='badge address-badge'>Primary</p> : ''}
                                                            </div>
                                                            <input type="radio" className='checkout-radio' checked={address?.set_default === 1 ? 'checked' : ''} onChange={() => handleDefault(address.id)}
                                                                style={{ width: '15px' }} readOnly />
                                                        </div>
                                                        <p className="card-text mt-2"><b>Address:</b> {address.address}</p>
                                                        <p className="card-text"> {address.postal_code}</p>
                                                        <p className="card-text">{address.city}, {address?.state}, {address?.country}</p>
                                                        <p className="card-text"><b>Phone Number:</b> {address?.phone_code}-{address.phone}</p>
                                                        <p className="card-text pb-3"><b>Email:</b> {userData?.email}</p>
                                                        <span style={{ backgroundColor: '#ff8c00', padding: '4px 9px', borderRadius: '5px', color: 'white' }}>
                                                            {address?.address_type}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    {/* <div className="d-flex justify-content-between mb-3">
                                        <button className='checkout-back-btn' onClick={() => navigate('/cart')}><MoveLeft /> Previous</button>
                                        <button className='checkout-proceed-btn'>Proceed <MoveRight /> </button>
                                    </div> */}
                                </div>
                            </div>
                            <CheckOutSummary />
                        </div>
                    </div>
                </section>

                <section className='checkout d-flex justify-content-center bg-white'>
                    <div className="container">
                        <div className='row'>
                            {cart?.map((e, i) =>
                                <div className='col-md-3 col-sm-12' key={i}>
                                    <div className="card mb-3 rounded-3" style={{ maxWidth: 300, border: '1px solid #cccccc' }}>
                                        <div className="row g-0">
                                            <div className="col-md-4">
                                                <div className='cart-img-div' >
                                                    <img src={e?.photos[0]} alt={`cart-${i}`} className="img-fluid rounded-start-3" />
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="card-body cart-product-text">
                                                    <p className="card-text text-muted mb-2">
                                                        {e?.product_name?.length > 30 ? `${e?.product_name?.slice(0, 30)}...` : e.product_name}
                                                    </p>
                                                    <p className="card-text">
                                                        <small className="text-success">{e?.tags}</small>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </section>

                <section className='d-flex justify-content-center bg-white'>
                    <div className='container'>
                        <div className="d-flex justify-content-between mb-3">
                            <button className='checkout-back-btn' onClick={() => navigate('/cart')}><MoveLeft /> Previous</button>
                            <button className='checkout-proceed-btn' onClick={handleNextStep}>Proceed <MoveRight /> </button>
                        </div>
                    </div>
                </section>

            </main>
            <AddressForm showAddress={showAddress} handleShowAddress={handleShowAddress} />
        </div>
    )
}

export default ShippingAddress