import React from 'react'

const HomeBanner = () => {
  return (
    <>
        <section className="home-banner">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="home-banner-col-content">
                                <h3 className='text-white'>Bring Home Unique Handmade Paintings</h3>
                                <p className='text-white'>Made in India with care, these handmade paintings add a unique charm to your home. Celebrate art that tells a story.</p>
                                <button className='bg-white mt-xl-5 mt-lg-4 mt-md-4  mt-3'>Shop Now</button>
                        </div>        
                    </div>

                    {/* <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="home-banner-col-img">
                            <img src="/assets/images/home-banner-img.png" alt="" />
                        </div>        
                    </div> */}
                </div>
            </div>
        </section>

    </>
  )
}

export default HomeBanner