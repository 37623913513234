import { MoveUp } from 'lucide-react'
import React from 'react'
import ScrollToTop from 'react-scroll-to-top'

const ScrollTop = () => {
    return (
        <div>
            <ScrollToTop
                smooth className='scroll-to-Top'
                component={<a className="scrollToTop"><MoveUp className="fa-solid fa-arrow-up fs-20" /> </a>}
                // style={{ backgroundColor: "#f15b27", color: "white", height: "50px", width: "50px" }}
            />
        </div>
    )
}

export default ScrollTop