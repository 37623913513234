import React, { useEffect, useState } from 'react'
import OTPInput from "react-otp-input";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { emailCodeVerification, forgotPassword } from '../middlewares/auth';
import { otp } from '../helpers/yup.validation.schema';
import { useFormik } from 'formik';
import * as Yup from "yup";
import ToastService from '../helpers/toast-services';
import ErrorList from '../Components/error-list/ErrorList';
import { forgetPasswordReset } from '../slices/auth.slice';
import ROUTE_URLS from '../config/routes';

const Otp = () => {
    const [minutes, setMinutes] = useState(1);
    const [seconds, setSeconds] = useState(0);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const emailAddress = localStorage.getItem('IN_EMAIL');
    const maskEmail = (email) => {
        if (!email) return "";
        const [localPart, domain] = email.split("@");
        const maskedLocalPart = localPart.substring(0, 2) + "****";
        return `${maskedLocalPart}@${domain}`;
    };
    const {
        verifyEmailCodeError,
        verifyEmailCodeLoading,
        verifyEmailCode,
        verifyEmailCodeMessage,

        forgotPasswordError,
        forgotPasswordLoading,
        forgotPasswordMessage,
    } = useSelector((state) => state.auth);

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSeconds(60);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [seconds, minutes]);

    useEffect(() => {
        if (!verifyEmailCodeLoading) {
            if (verifyEmailCodeMessage) {
                ToastService.success(verifyEmailCodeMessage);
                localStorage.removeItem('IN_EMAIL');
                navigate(ROUTE_URLS.RESET_PASSWORD, { state: verifyEmailCode }, { replace: true });
            }
        }
    }, [verifyEmailCodeMessage]);

    useEffect(() => {
        if (forgotPasswordMessage) {
            ToastService.success(forgotPasswordMessage);
            dispatch(forgetPasswordReset());
            otpFormik.setFieldValue("otp", "");
            setMinutes(1);
            setSeconds(0);
        }
    }, [forgotPasswordMessage]);

    const handleSubmit = (values) => {
        const payload = {
            email: localStorage.getItem('IN_EMAIL'),
            code: values.otp,
        };
        dispatch(emailCodeVerification(payload));
    }

    const resendOTP = () => {
        const payload = {
            email: localStorage.getItem('IN_EMAIL'),
        }
        dispatch(forgotPassword(payload));
    }

    const otpSchema = Yup.object({
        otp: otp
    });

    const otpFormik = useFormik({
        initialValues: {
            otp: '',
        },
        validationSchema: otpSchema,
        onSubmit: handleSubmit,
    });

    return (
        <>
            <div className='indiazona-login'>
                <div className='container' id="container">
                    <div className="form-container sign-in-container otp-page">
                        <div className="account-page login-page">
                            <form className="row g-3 otp-form p-0" onSubmit={otpFormik.handleSubmit}>
                                <div className="col-md-12">
                                    <h3 className="account-title  lg-mt-5 md-mt-5 mt-2 mb-2">Validate One Time Passcode</h3>
                                    {verifyEmailCodeError && <ErrorList error={verifyEmailCodeError} />}
                                    <p className="account-subtitle mb-2">Enter 6 digit secure code, received by register email</p>
                                    <span className="">
                                        <b>Email:</b> {maskEmail(emailAddress)}
                                    </span>
                                    <div className="col-md-12 otp-group my-4">
                                        <OTPInput
                                            value={otpFormik.values.otp}
                                            onChange={(val) => otpFormik.setFieldValue("otp", val)}
                                            numInputs={6}
                                            className=' otp-inputs'
                                            renderSeparator={<span className='mx-1'>-</span>}
                                            renderInput={(props, index) => (
                                                <input className='otp-input'
                                                    {...props}
                                                    type="number"
                                                />
                                            )}
                                        />
                                        {otpFormik.touched.otp && otpFormik.errors.otp && (
                                            <div className="error px-0">
                                                {otpFormik.errors.otp}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='' style={{ display: 'flex', alignItems: 'center' }}>
                                    {seconds > 0 || minutes > 0 ? (
                                        <p className='otp-time p-0' style={{ paddingTop: '15px' }}>
                                            Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                                            {seconds < 10 ? `0${seconds}` : seconds}
                                        </p>
                                    ) : (
                                        <p className='otp-time' >Didn't recieve code?</p>
                                    )}
                                    <button className="otp-button"
                                        type='button'
                                        style={{ border: 'none', background: 'none', display: 'flex', alignItems: "center", justifyContent: 'flex-end', flex: 1, cursor: 'pointer', color: seconds > 0 || minutes > 0 ? "grey" : "#00A6A6" }}
                                        onClick={resendOTP} disabled={seconds > 0 || minutes > 0}>Resend OTP</button>
                                </div>
                                <div className="input-block d-flex justify-content-center">
                                    <button className="common-btn Login-btn w-100" type="submit" disabled={verifyEmailCodeLoading || forgotPasswordLoading}>{verifyEmailCodeLoading || forgotPasswordLoading ? "Processing..." : "Submit"}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="overlay-container">
                        <div className="overlay">
                            <div className="overlay-panel overlay-right">
                                <div className="india-zona-login">
                                    <img src="/assets/images/svg-img/logo.svg" alt="logo" />
                                </div>
                                <p className='py-2'>Enter your OTP and start journey with us</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Otp