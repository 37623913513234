export const BASE_URL = "/";
const ROUTE_URLS = {
  HOME: BASE_URL,
  LOGIN: `${BASE_URL}login`,
  FORGOT_PASSWORD: `${BASE_URL}forgot-password`,
  SET_PASSWORD: `${BASE_URL}set-password`,
  RESET_PASSWORD: `${BASE_URL}reset-password`,

  PHONE: `${BASE_URL}phone`,

  VERIFY_EMAIL: `${BASE_URL}email-verification`,
  VERIFY_MOBILE_OTP: `${BASE_URL}mobile-verification`,

  VERIFY_FORGET_CODE: `${BASE_URL}otp-verification`,
};

export default ROUTE_URLS;
