import { LOCALSTORAGE_WISHLIST_KEY } from "../../config/constants";
import LocalstorageService from "../../helpers/localstorage-services";
import wishListService from "../../services/wishlist";
import { addProductWishListError, addProductWishListRequest, addProductWishListSuccess, deleteProductWishListError, deleteProductWishListRequest, deleteProductWishListSuccess, getWishListError, getWishListRequest, getWishListSuccess, updateWishListCount } from "../../slices/wishlist.slice";

export const fetchWishListProducts = (payload) => {
    return (dispatch) => {
        dispatch(getWishListRequest());
        wishListService.getWishList(payload)
        .then((response) => {
          const { status, data, message, error } = response.data;
          if (status === 1) {
            LocalstorageService.setInLocalStorage(LOCALSTORAGE_WISHLIST_KEY, data)
            dispatch(updateWishListCount(data?.length));
            dispatch(getWishListSuccess({ data, message }));
          } else {
            dispatch(getWishListError({message: error}));
          }
        })
        .catch((error) => {
          dispatch(getWishListError({ data: [], message: error }));
        });
    };
  };
export const addProductWishList = (payload) => {
    return (dispatch) => {
        dispatch(addProductWishListRequest());
        wishListService.addProductWishList(payload)
        .then((response) => {
          const { status, data, message, error } = response.data;
          if (status === 1) {
            dispatch(addProductWishListSuccess({ data, message }));
          } else {
            dispatch(addProductWishListError(error));
          }
        })
        .catch((error) => {
          dispatch(addProductWishListError({ data: [], message: error }));
        });
    };
  };
export const removeProductWishList = (payload) => {
    return (dispatch) => {
        dispatch(deleteProductWishListRequest());
        wishListService.deleteProductWishList(payload)
        .then((response) => {
          const { status, data, message, error } = response.data;
          if (status === 1) {
            dispatch(deleteProductWishListSuccess({ data, message }));
          } else {
            dispatch(deleteProductWishListError(error));
          }
        })
        .catch((error) => {
          dispatch(deleteProductWishListError({ data: [], message: error }));
        });
    };
  };