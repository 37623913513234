import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getSidebarFiltersData } from '../../middlewares/products/sidebarFilter';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';

const FilterProductList = () => {
    const [searchOpen, setSearchOpen] = useState(false);
    const [searchOpen2, setSearchOpen2] = useState(false);
    const [showAllCategories, setShowAllCategories] = useState(false);
    const [showAllBrands, setShowAllBrands] = useState(false);
    const [showAllColors, setShowAllColors] = useState(false);
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [colors, setColors] = useState([]);
    const dispatch = useDispatch();
    const { category } = useParams();
    const formik = useFormik({
        initialValues: {
            brandName: '',
            colorName: '',
        },
    });

    const { sidebarFilter } = useSelector((state) => state.sidebarFilter);
    useEffect(() => {
        if (category) {
            const payload = {
                slug: category,
            };
            dispatch(getSidebarFiltersData(payload));
        }
    }, [category]);

    useEffect(() => {
        if (sidebarFilter?.sub_categories) {
            setCategories(sidebarFilter.sub_categories);
        }
        if (sidebarFilter?.brands) {
            setBrands(sidebarFilter.brands);
        }

        if (sidebarFilter?.colors) {
            setColors(sidebarFilter.colors);
        }

    }, [sidebarFilter]);


    useEffect(() => {
        if (formik.values.brandName === '') {
            if (sidebarFilter?.brands) {
                setBrands(sidebarFilter.brands);
            }
        } else {
            const searchValue = formik.values.brandName.toLowerCase();
            const filteredBrands = sidebarFilter?.brands?.filter((brand) =>
                brand.name.toLowerCase().includes(searchValue)
            );
            setBrands(filteredBrands || []);
        }
    }, [formik.values.brandName, sidebarFilter]);


    useEffect(() => {
        if (formik.values.colorName === '') {
            if (sidebarFilter?.colors) {
                setColors(sidebarFilter.colors);
            }
        } else {
            const searchValue = formik.values.colorName.toLowerCase();
            const filteredColors = sidebarFilter?.colors?.filter((color) =>
                color.name.toLowerCase().includes(searchValue)
            );
            setColors(filteredColors || []);
        }
    }, [formik.values.colorName, sidebarFilter]);


    const toggleSearch = () => {
        setSearchOpen(!searchOpen);
    };
    const toggleSearch2 = () => {
        setSearchOpen2(!searchOpen2);
    };
    const toggleAllCategories = () => {
        setShowAllCategories(!showAllCategories);
    }
    const toggleAllBrands = () => {
        setShowAllBrands(!showAllBrands);
    }

    const toggleAllColors = () => {
        setShowAllColors(!showAllColors);
    }


    return (
        <>
            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12 d-none d-md-block d-lg-block">
                <div className="filter-position-sticky">
                    <div className="box-shadow condition-listing-vertical-Filters filter-mobile-view-hide">
                        <div className="filters-box categories-Boxs">
                            <ul className=" d-flex justify-content-between aligin-items-center">
                                <li className="d-flex aligin-items-center gap-2" style={{ alignItems: 'center' }}>
                                    <h6 className="filters-titel mb-0">Filters</h6><span className="filter-count">0</span>
                                </li>
                                <li>
                                    <button className="btn clear-Btn">Clear All</button>
                                </li>
                            </ul>
                        </div>
                        {categories.length > 0 &&
                            <div className="filters-box categories-Boxs">
                                <ul>
                                    <h6 className="filters-titel">Categories</h6>
                                    {categories.slice(0, 5).map((category, index) =>
                                        <li key={index}>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value={category.id} name={category.name} />
                                                <label className="form-check-label">{category.name}
                                                    {/* <span className="form-check-text">(1351)</span> */}
                                                </label>
                                            </div>
                                        </li>
                                    )}
                                    {showAllCategories && categories.slice(5).map((category, index) =>
                                        <li key={index}>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value={category.id} name={category.name} />
                                                <label className="form-check-label">{category.name}
                                                    {/* <span className="form-check-text">(1351)</span> */}
                                                </label>
                                            </div>
                                        </li>
                                    )}
                                    {!showAllCategories && categories.length > 5 && (
                                        <button className="more-btn btn" onClick={toggleAllCategories}>
                                            + {categories.length - 5} more
                                        </button>
                                    )}
                                </ul>
                            </div>
                        }
                        <div className="filters-box categories-Boxs">
                            <ul>
                                <div className="mb-2 d-flex aligin-items-center gap-2 justify-content-between" style={{ alignItems: 'center' }}>
                                    <h6 className="filters-titel mb-0">Brand</h6>
                                    <div className={`input-box filter-search ${searchOpen ? 'open' : ''}`}>
                                        <input className="input-search-fill"
                                            type="text"
                                            placeholder="Search..."
                                            name='brandName'
                                            onChange={formik.handleChange}
                                            value={formik.values.brandName}
                                        />
                                        <span className="search search-icon-btn">
                                            <span onClick={() => setSearchOpen(true)}>
                                                <i className="fa-solid fa-magnifying-glass search-icon search-icon-btn" />
                                            </span>
                                        </span>
                                        <span onClick={toggleSearch}>
                                            <i className="fa-solid fa-xmark close-icon close-icon-btn" />
                                        </span>
                                    </div>
                                </div>
                                {brands.slice(0, 5).map((brand, index) =>
                                    <li key={index}>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value={brand.id} name={brand.name} />
                                            <label className="form-check-label" htmlFor="Roadster">
                                                {brand.name}
                                                {/* <span className="form-check-text">(133451)</span> */}
                                            </label>
                                        </div>
                                    </li>
                                )}
                                {showAllBrands && brands.slice(5).map((brand, index) =>
                                    <li key={index}>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value={brand.id} name={brand.name} />
                                            <label className="form-check-label" htmlFor="Roadster">
                                                {brand.name}
                                                {/* <span className="form-check-text">(133451)</span> */}
                                            </label>
                                        </div>
                                    </li>
                                )}
                                {!showAllBrands && brands.length > 5 && (
                                    <button className="more-btn btn" onClick={toggleAllBrands}>
                                        + {brands.length - 5} more
                                    </button>
                                )}
                            </ul>
                        </div>


                        <div className="filters-box categories-Boxs">
                            <ul>
                                <div className="mb-2 d-flex aligin-items-center gap-2 justify-content-between" style={{ alignItems: 'center' }}>
                                    <h6 className="filters-titel mb-0">Color</h6>
                                    <div className={`input-box filter-search ${searchOpen2 ? 'open' : ''}`}>
                                        <input
                                            className="input-search-fill"
                                            type="text"
                                            placeholder="Search by name..."
                                            name="colorName"
                                            value={formik.values.colorName}
                                            onChange={formik.handleChange}
                                        />
                                        <span className="search search-icon-btn">
                                            <span onClick={() => setSearchOpen2(true)}>
                                                <i className="fa-solid fa-magnifying-glass search-icon search-icon-btn" />
                                            </span>
                                        </span>
                                        <span onClick={toggleSearch2}>
                                            <i className="fa-solid fa-xmark close-icon close-icon-btn" />
                                        </span>
                                    </div>
                                </div>
                                {colors.slice(0, 5).map((elem, i) => (
                                    <li key={i}>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value={elem?.name}
                                                id={elem?.name}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor={elem?.name}
                                            >
                                                {elem?.name}
                                                {/* <span className="form-check-text">(1351)</span> */}
                                            </label>
                                        </div>
                                    </li>
                                ))}
                                {showAllColors && colors.slice(5).map((elem, i) => (
                                    <li key={i}>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value={elem?.name}
                                                id={elem?.name}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor={elem?.name}
                                            >
                                                {elem?.name}
                                                {/* <span className="form-check-text">(1351)</span> */}
                                            </label>
                                        </div>
                                    </li>
                                ))}
                                {!showAllColors && colors.length > 5 && (
                                    <button className="more-btn btn" onClick={toggleAllColors}>
                                        + {colors.length - 5} more
                                    </button>
                                )}
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </>

    )
}

export default FilterProductList