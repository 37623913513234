import React from 'react'

const ShopCategory = () => {
    return (
        <>
            <section className='shop-category wrapper-section'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <ul>
                                <li>
                                    <h4 className="Title">Shop by Category</h4>
                                </li>
                                <li>
                                    <a href="" className="viewAllBtn flex">
                                        <span>See All</span>
                                        {/* <i className="fa-solid fa-arrow-right-long arrowRight" /> */}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="row">
                                <div className="col-12">
                                    <a href="">
                                        <div className="shop-category-banner">
                                            <img src="/assets/images/woman-fashion.png" alt="woman-fashion" />
                                            <h4>woman fashion</h4>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-12">
                                    <a href="">
                                        <div className="shop-category-banner mt-3 mb-lg-0 mb-md-0 mb-3">
                                            <img src="/assets/images/Paintings.png" alt="Paintings" />
                                            <h4>Paintings</h4>
                                        </div>
                                    </a>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <a href="">
                                <div className="shop-category-banner ">
                                    <img src="/assets/images/Handmade-bags.png" alt="Handmade-bags" />
                                    <h4>Handmade bags</h4>
                                </div>
                            </a>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default ShopCategory