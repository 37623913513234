import React from 'react'

const ShippingSection = () => {
    return (
        <section className="wrapper-section shipping">
            <div className="container-fluid">
                <div className="row ">
                    <div className="col-lg-3 col-md-6 col-sm-12" data-aos="fade-down" data-aos-offset={100} data-aos-duration={500}>
                        <div className="shipping_card flex gap-2 align-items-center">
                            <div className="shipping_img-box"><img src="/assets/images/svg-img/shopping-cart.svg" alt="cart-img" className="shipping_img" />
                            </div>
                            <div className="shipping_content">
                                <h6>Free Shipping</h6>
                                {/* <p>No one rejects, dislikes.</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 " data-aos="fade-down" data-aos-duration={500}>
                        <div className="shipping_card  flex gap-2 align-items-center">
                            <div className="shipping_img-box"><img src="/assets/images/svg-img/message-chat.svg" alt="message-chat-img" className="shipping_img" />
                            </div>
                            <div className="shipping_content">
                                <h6>Dedicated Support</h6>
                                {/* <p>It has survived not only.</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 " data-aos="fade-down" data-aos-duration={500}>
                        <div className="shipping_card  flex gap-2 align-items-center">
                            <div className="shipping_img-box"><img src="/assets/images/svg-img/lock.svg" alt="lock-img" className="shipping_img" />
                            </div>
                            <div className="shipping_content">
                                <h6>Secured Platform</h6>
                                {/* <p>All the Lorem Ipsum on.</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12" data-aos="fade-down" data-aos-duration={500}>
                        <div className="shipping_card  flex gap-2 align-items-center">
                            <div className="shipping_img-box"><img src="/assets/images/svg-img/truck.svg" alt="truck-img" className="shipping_img" />
                            </div>
                            <div className="shipping_content">
                                <h6>No Return Discounts</h6>
                                {/* <p>No one rejects, dislikes.</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default ShippingSection