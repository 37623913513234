import React from 'react'

const SellerDetails = ({ productDetails }) => {

    return (
        <div className="best-offers">
            <h5>Selller Details</h5>
            <ul>
                <li>
                    <h6 >Exchange Policy: <span className='mx-1'>{productDetails?.exchange_policy?.replace("days", " days")}</span></h6>
                </li>
                <li className='fw-light'>
                    <h6 >Return Policy: <span className='mx-1'>{productDetails?.return_policy?.replace("days", " days")}</span></h6>
                </li>
            </ul>
        </div>
    )
}

export default SellerDetails;