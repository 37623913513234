import React, { useEffect } from 'react'
import SideBarManu from './SideBarManu'
import Footer from '../../Components/Common/Footer'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

const OrdersPage = () => {
    const dispatch = useDispatch();
    const data = useSelector((state) => state?.order);

    useEffect(() => {
    }, [])
 
    return (
        <>
            <section className="page-title">
                <div className="pattern-layer"></div>
                <div className="container-full">
                    <div className="content-box">
                        <h3>Personal Information</h3>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item active" aria-current="page"><a href="#">Home</a></li>
                                <li className="breadcrumb-item" aria-current="page">/</li>
                                <li className="breadcrumb-item" aria-current="page">Orders Page</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </section>

            <section className="profile-section profile-wrapper">
                <div className="container">
                    <div className="row">
                        <SideBarManu />
                        <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-8 account-wrapper">
                            <div className="account-card">
                                <div className="table-responsive table-style-1">
                                    <table className="table table-hover mb-3">
                                        <thead>
                                            <tr>
                                                <th>Order #</th>
                                                <th>Date Purchased</th>
                                                <th>Status</th>
                                                <th>Total</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><a href="account-order-details.html" className="fw-medium">#34VB5540K83</a></td>
                                                <td>May 21, 2024</td>
                                                <td>$358.75</td>
                                                <td><span className="badge bg-info  m-0">In Progress</span></td>
                                                <td><Link to='/order-details' className="btn-link text-underline p-0">View</Link></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {/* Pagination*/}
                                <div className="d-flex justify-content-center">
                                    <nav aria-label="Table Pagination">
                                        <ul className="pagination style-1">
                                            <li className="page-item"><a className="page-link" href="#">Prew</a></li>
                                            <li className="page-item"><a className="page-link" href="#">1</a></li>
                                            <li className="page-item"><a className="page-link" href="#">2</a></li>
                                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                                            <li className="page-item"><a className="page-link" href="#">Next</a></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OrdersPage