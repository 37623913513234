import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from "yup";
import { otp } from '../helpers/yup.validation.schema';
import OTPInput from 'react-otp-input';
import { verifyPhoneNumberOtp } from '../middlewares/auth';
import { resetMessages } from '../slices/resendOtp';
import ToastService from '../helpers/toast-services';
import ErrorList from '../Components/error-list/ErrorList';
import ROUTE_URLS from '../config/routes';
import { verifyOtp } from '../middlewares/resendOtpVerify';


const MobileVerify = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [minutes, setMinutes] = useState(1);
    const [seconds, setSeconds] = useState(0);
    const userData = localStorage.getItem('IN_USER_DATA');
    const user = JSON?.parse(userData);
    const { id } = user || {};
    const { verifyMobileOtpError, verifyMobileOtpLoading, verifyMobileOtpMessage } = useSelector((state) => state.auth);
    const { ErrorMessage, SuccessMessage, verifyDeta, addloading } = useSelector((state) => state.otpVerification);
    console.log(
        ErrorMessage,
        addloading,
        verifyDeta,
        SuccessMessage, '//////'
    );
    useEffect(() => {
        const endTime = localStorage.getItem('EndTime');
        if (endTime) {
            const currentTime = new Date().getTime();
            const remainingTime = Math.max(0, endTime - currentTime);

            if (remainingTime > 0) {
                setMinutes(Math.floor(remainingTime / 60000));
                setSeconds(Math.floor((remainingTime % 60000) / 1000));
            } else {
                resetTimer();
            }
        } else {
            resetTimer();
        }
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSeconds(60);
                    setMinutes(minutes - 1);
                }
            }
            const endTime = new Date().getTime() + (minutes * 60000) + (seconds * 1000);
            localStorage.setItem('EndTime', endTime);
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [seconds, minutes]);

    useEffect(() => {
        if (!verifyMobileOtpLoading) {
            if (verifyMobileOtpMessage) {
                ToastService.success(verifyMobileOtpMessage);
                navigate(ROUTE_URLS.HOME, { replace: true });
            }
        }
    }, [verifyMobileOtpLoading]);

    useEffect(() => {
        if (SuccessMessage) {
            ToastService.success(SuccessMessage);
            dispatch(resetMessages());
        }
        if (ErrorMessage) {
            ToastService.error(ErrorMessage);
            dispatch(resetMessages());
        }
    }, [SuccessMessage, ErrorMessage, dispatch]);


    const mobileVerifySchema = Yup.object({
        otp: otp,
    });

    const resetTimer = () => {
        const initialEndTime = new Date().getTime() + 60000;
        localStorage.setItem('EndTime', initialEndTime);
        setMinutes(1);
        setSeconds(0);
    };

    const handleSubmit1 = () => {
        resetTimer()
        const payload = {
            user_id: id,
        }
        dispatch(verifyOtp(payload));
    };

    const handleSubmit = (values) => {
        resetTimer()
        const payload = {
            user_id: id,
            otp: values.otp,
        }
        dispatch(verifyPhoneNumberOtp(payload));
    }

    const formik = useFormik({
        initialValues: {
            otp: ''
        },
        validationSchema: mobileVerifySchema,
        onSubmit: handleSubmit,
    });

    return (
        <div className='indiazona-login' >
            <div className='container' id="container">
                <div className="form-container sign-in-container otp-page">
                    <div className="account-page login-page">
                        <div className="row">
                            <div className=" p-4">
                                <h3 className="account-title  mt-5 mb-2">Validate One Time Passcode</h3>
                                {verifyMobileOtpError && <ErrorList error={verifyMobileOtpError} />}

                                <p className="account-subtitle">Enter 6 digit secure code, received by register email</p>
                                <form className="row g-3 otp-form p-0" onSubmit={formik.handleSubmit}>
                                    <div className="col-md-12 otp-group mb-4">
                                        <OTPInput
                                            numInputs={6}
                                            className=' otp-inputs'
                                            renderSeparator={<span className='mx-1'>-</span>}
                                            renderInput={(props, index) => (
                                                <input className='otp-input'
                                                    {...props}
                                                    type="number"
                                                />
                                            )}
                                            value={formik.values.otp}
                                            onChange={(val) => formik.setFieldValue("otp", val)}
                                        />
                                        {formik.touched.otp && formik.errors.otp && (
                                            <div className="error">
                                                {formik.errors.otp}
                                            </div>
                                        )}
                                    </div>
                                    <div className='' style={{ display: 'flex', alignItems: 'center' }}>
                                        {seconds > 0 || minutes > 0 ? (
                                            <p className='otp-time p-0' style={{ paddingTop: '15px' }}>
                                                Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                                                {seconds < 10 ? `0${seconds}` : seconds}
                                            </p>
                                        ) : (
                                            <p className='otp-time' >Didn't recieve code?</p>
                                        )}
                                        <button className="otp-button"
                                            type='button'
                                            style={{ border: 'none', background: 'none', display: 'flex', alignItems: "center", justifyContent: 'flex-end', flex: 1, cursor: 'pointer', color: seconds > 0 || minutes > 0 ? "grey" : "#00A6A6" }}
                                            disabled={seconds > 0 || minutes > 0} onClick={handleSubmit1}>Resend OTP</button>
                                    </div>
                                    <div className="input-block d-flex justify-content-center">
                                        <button className="common-btn Login-btn w-100" type="submit" disabled={verifyMobileOtpLoading}>
                                            {verifyMobileOtpLoading ? "Processing..." : "Submit"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overlay-container">
                    <div className="overlay">
                        <div className="overlay-panel overlay-right">
                            <div className="india-zona-login">
                                <img src="/assets/images/svg-img/logo.svg" alt="logo" />
                            </div>
                            <p className='py-2'>Enter your OTP and start journey with us</p>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    )
}

export default MobileVerify