import { LOCALSTORAGE_CATEGORIES_KEY } from "../config/constants";
import LocalstorageService from "../helpers/localstorage-services";
import CategoryService from "../services/categories";
import {
  homeCategoryRequest,
  homeCategorySuccess,
  homeCategoryError,
} from "../slices/categories.slice";

export const fetchHomeCategories = (payload) => {
  return (dispatch) => {
    dispatch(homeCategoryRequest());
    CategoryService.getHomeCategories(payload)
      .then((response) => {
        const { status, data, message, error } = response.data;
        if (status === 1) {
          // localStorage.setItem("categories",JSON.stringify(data))
          LocalstorageService.setInLocalStorage(LOCALSTORAGE_CATEGORIES_KEY,data)
          dispatch(homeCategorySuccess({ data, message }));
        } else {
          dispatch(homeCategoryError(error));
        }
      })
      .catch((error) => {
        dispatch(homeCategoryError(error));
      });
  };
};
