import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { getCartData, removeCartItem, updateCart } from '../../middlewares/cart/cart';
import LocalstorageService from '../../helpers/localstorage-services';
import { toast } from 'react-toastify';
import { resetDeleteCartList, resetUpdateCart } from '../../slices/cart';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import * as Yup from "yup";

const CartDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = LocalstorageService.getLoggedInUserDetails();
    const {
        cart,
        cartError,
        cartLoading,
        cartMessage,
        cartRemoveError,
        cartUpdateMessage,
        cartUpdateError,
        cartRemoveLoading,
        cartRemoveMessage,
    } = useSelector((state) => state.cart);
    const [isChecked, setIsChecked] = useState(false);
    // const handleCheckout = () => {
    //     if (!formik.values.termConditiionError) {
    //         formik.setFieldError('termConditiionError', 'You must agree to the terms and conditions');
    //         return;
    //       }
    //     navigate('/checkout/shipping')
    // }
    // const formik = useFormik({
    //     initialValues: {
    //         termConditiionError: ''
    //     },
    //     validationSchema: Yup.object({
    //         termConditiionError: Yup.string().required('You must agree to the terms and conditions'),
    //     }),
    //     onSubmit: handleCheckout,
    //   });

    // const handleCheckboxChange = () => {
    //   setIsChecked(prevChecked => !prevChecked);
    // };
    const formik = useFormik({
        initialValues: {
            isChecked: false,
        },
        validationSchema: Yup.object({
            isChecked: Yup.boolean().oneOf([true], 'You must agree to the terms and conditions'),
        }),
        onSubmit: (values) => {
            // Navigate to the checkout shipping page if validation passes
            navigate('/checkout/shipping');
        },
    });

    // Handle checkbox change
    const handleCheckboxChange = (event) => {
        formik.setFieldValue('isChecked', event.target.checked);
    };

    // Handle checkout button click
    const handleCheckout = () => {
        // Trigger Formik's validation
        formik.submitForm();

    };
    const handleRemoveCartItem = (id) => {
        const bodyFormData = new FormData();
        bodyFormData.append("id", id);
        dispatch(removeCartItem(bodyFormData));
    };
    useEffect(() => {
        if (cartUpdateMessage) {
            const bodyFormData = new FormData();
            bodyFormData.append("user_id", user.id);
            dispatch(getCartData(bodyFormData));
            toast.success(cartUpdateMessage)
            dispatch(resetUpdateCart());
        }
        if (cartUpdateError) {
            toast.error(cartUpdateError)
            dispatch(resetUpdateCart());
        }
        if (cartRemoveMessage || cartRemoveError) {
            if (user) {
                const bodyFormData = new FormData();
                bodyFormData.append("user_id", user.id);
                dispatch(getCartData(bodyFormData));
            }
        }
        if (cartRemoveError) {
            toast.error(cartRemoveError);
            dispatch(resetDeleteCartList());
        }
    }, [dispatch, cartUpdateMessage, cartRemoveMessage, cartRemoveError, cartUpdateError]);

    useEffect(() => {
        if (user) {
            const bodyFormData = new FormData();
            bodyFormData.append("user_id", user.id);
            dispatch(getCartData(bodyFormData));
        }
    }, [dispatch]);

    const handleQuantityChange = (id, quantityChange) => {
        const updatedCart = cart.map((item) => {
            if (item.id === id) {
                const newQuantity = Math.max(item.quantity + quantityChange, 1);
                return { ...item, quantity: newQuantity };
            }
            return item;
        });

        const bodyFormData = new FormData();
        bodyFormData.append("id", id);
        bodyFormData.append("quantity", updatedCart.find((item) => item?.id === id).quantity);
        dispatch(updateCart(bodyFormData));
    };

    const calculateSubtotal = () => {
        let subtotal = 0;
        cart.forEach((item) => {
            subtotal += item?.cart_indiazona_price;
        });
        return subtotal.toFixed(2);
    };

    return (
        <section className="flat-spacing-11">
            <div className="container">
                <div className="tf-cart-countdown">
                    <div className="title-left">
                        <svg width={20} height={30} viewBox="0 0 20 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_197_107)">
                                <path fillRule="evenodd" clipRule="evenodd" d="M12.6124 30C14.1399 27.741 14.2806 25.3011 13.0346 22.6804C12.6881 24.0864 12.0543 24.9397 11.1331 25.2405C11.9885 22.7974 11.2737 20.1579 8.98848 17.322C8.93916 20.2509 8.2353 22.3845 6.87685 23.7228C5.00545 25.5646 5.02746 27.6432 6.94285 29.9586C-1.00824 25.7274 -2.12999 20.0523 3.57745 12.9338C3.93145 14.6535 4.78926 15.7093 6.15102 16.1011C4.66668 9.80233 6.22842 4.43526 10.8362 0C10.8645 9.84282 13.8827 10.7177 17.699 15.1112C21.8192 20.3839 19.398 26.4685 12.6124 30Z" fill="url(#paint0_linear_197_107)" />
                                <path d="M10.68 24.7702C10.6598 24.7215 10.6194 24.6758 10.5618 24.6365C10.5042 24.5972 10.4308 24.5653 10.347 24.5432C10.2203 24.516 10.0855 24.5014 9.94904 24.5C9.85412 24.4995 9.75997 24.5093 9.67235 24.5288C9.58474 24.5483 9.5055 24.5772 9.43944 24.6136C9.30827 24.6917 9.21671 24.7865 9.17346 24.8889C9.05748 25.1415 8.96068 25.6938 8.8766 26.5299C8.79293 27.3042 8.75072 27.9812 8.75 28.561C8.75 28.7409 8.75 28.8845 8.76703 28.9895C8.77236 29.0423 8.77873 29.0872 8.78832 29.1241C8.79477 29.1595 8.81055 29.1943 8.83515 29.2274C8.90161 29.3124 9.01665 29.3841 9.16387 29.4323C9.31772 29.4788 9.49251 29.502 9.66926 29.4993C9.89078 29.5058 10.1085 29.4668 10.2778 29.3903C10.353 29.3491 10.4114 29.3 10.4493 29.2462C10.4872 29.1924 10.5038 29.1349 10.498 29.0775C10.4876 28.9426 10.4085 28.8117 10.2693 28.6989C10.2437 28.2241 10.2697 27.749 10.347 27.2758C10.498 26.4508 10.6232 25.8346 10.7225 25.427V25.4202C10.7406 25.3067 10.7491 25.2073 10.7491 25.1137C10.755 24.9989 10.7313 24.8844 10.6789 24.7731M10.1257 24.8083L10.1374 24.8122C10.1346 24.8118 10.1316 24.8118 10.1288 24.8122" fill="#3F59A3" />
                            </g>
                            <defs>
                                <linearGradient id="paint0_linear_197_107" x1="9.85969" y1={0} x2="9.85969" y2={30} gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#00C53C" />
                                    <stop offset="0.73" stopColor="#FF8C00" />
                                </linearGradient>
                                <clipPath id="clip0_197_107">
                                    <rect width={20} height={30} fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <h6>These items are in limited supply, buy now before they're gone! </h6>
                    </div>
                    {/* <div className="js-countdown timer-count" data-timer={600} data-labels="d:,h:,m:,s">
                        <span className="countdown__item">
                            2h : 30m : 35s
                        </span>
                    </div> */}
                </div>
                {cart?.length > 0 ? (
                    <div className="row justify-content-between ">
                        <div className="col-xl-7 col-lg-12 col-md-12 col-sm-12">
                            <div className="tf-page-cart-item w-100 mb-3">
                                <form>
                                    <table className="tf-table-page-cart">
                                        <thead>
                                            <tr>
                                                <th>Product</th>
                                                <th>Price</th>
                                                <th>Quantity</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {cart.map((elem, i) => (
                                                <tr className="tf-cart-item file-delete" key={i}>
                                                    <td className="tf-cart-item_product">
                                                        <div className='d-flex align-items-center card-img-box'>
                                                            <Link to={`/${elem?.item_category_slug}/product-details/${elem?.product_id}`} className="img-box">
                                                                <div className="table-product-img-box">
                                                                    <img src={elem?.photos[0]} alt="img-product" />
                                                                </div>
                                                            </Link>
                                                            <div className="cart-info">
                                                                <div className="cart-info-detail">
                                                                    <Link to={`/${elem?.item_category_slug}/product-details/${elem?.product_id}`} className="cart-title link">{elem?.product_name}</Link>
                                                                </div>
                                                                {/* <div className="cart-meta-variant">White / M</div> */}
                                                                <span className="remove-cart link remove" onClick={() => handleRemoveCartItem(elem?.id)}>Remove</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="tf-cart-item_price" cart-data-title="Price">
                                                        <div className="cart-price">₹{elem?.indiazona_price}</div>
                                                    </td>
                                                    <td className="tf-cart-item_quantity" cart-data-title="Quantity">
                                                        <div className="cart-quantity">
                                                            <div className="wg-quantity mb-0">
                                                                <span className="btn-quantity minus-btn" onClick={() => handleQuantityChange(elem.id, -1)}>
                                                                    <svg className="d-inline-block" width={9} height={1} viewBox="0 0 9 1" fill="currentColor">
                                                                        <path d="M9 1H5.14286H3.85714H0V1.50201e-05H3.85714L5.14286 0L9 1.50201e-05V1Z" />
                                                                    </svg>
                                                                </span>
                                                                <input type="text" name="number" value={elem?.quantity} disabled />
                                                                <span className="btn-quantity plus-btn" onClick={() => handleQuantityChange(elem.id, 1)}>
                                                                    <svg className="d-inline-block" width={9} height={9} viewBox="0 0 9 9" fill="currentColor">
                                                                        <path d="M9 5.14286H5.14286V9H3.85714V5.14286H0V3.85714H3.85714V0H5.14286V3.85714H9V5.14286Z" />
                                                                    </svg>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="tf-cart-item_total" cart-data-title="Total">
                                                        <div className="cart-total">₹{(elem?.indiazona_price * elem?.quantity).toFixed(2)}</div>
                                                    </td>
                                                </tr>
                                            ))
                                            }
                                        </tbody>
                                    </table>
                                </form>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                           <div className="row justify-content-center">
                            <div className="col-12 col-lg-12 col-md-xl-12 col-lg-8 col-md-8 col-12">
                            <div className="tf-page-cart-footer">
                                <div className="tf-cart-footer-inner">
                                    {/* <div className="tf-free-shipping-bar">
                                        <div className="tf-progress-bar">
                                            <span style={{ width: '50%' }}>
                                                <div className="progress-car">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={21} height={14} viewBox="0 0 21 14" fill="currentColor">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M0 0.875C0 0.391751 0.391751 0 0.875 0H13.5625C14.0457 0 14.4375 0.391751 14.4375 0.875V3.0625H17.3125C17.5867 3.0625 17.845 3.19101 18.0104 3.40969L20.8229 7.12844C20.9378 7.2804 21 7.46572 21 7.65625V11.375C21 11.8582 20.6082 12.25 20.125 12.25H17.7881C17.4278 13.2695 16.4554 14 15.3125 14C14.1696 14 13.1972 13.2695 12.8369 12.25H7.72563C7.36527 13.2695 6.39293 14 5.25 14C4.10706 14 3.13473 13.2695 2.77437 12.25H0.875C0.391751 12.25 0 11.8582 0 11.375V0.875ZM2.77437 10.5C3.13473 9.48047 4.10706 8.75 5.25 8.75C6.39293 8.75 7.36527 9.48046 7.72563 10.5H12.6875V1.75H1.75V10.5H2.77437ZM14.4375 8.89937V4.8125H16.8772L19.25 7.94987V10.5H17.7881C17.4278 9.48046 16.4554 8.75 15.3125 8.75C15.0057 8.75 14.7112 8.80264 14.4375 8.89937ZM5.25 10.5C4.76676 10.5 4.375 10.8918 4.375 11.375C4.375 11.8582 4.76676 12.25 5.25 12.25C5.73323 12.25 6.125 11.8582 6.125 11.375C6.125 10.8918 5.73323 10.5 5.25 10.5ZM15.3125 10.5C14.8293 10.5 14.4375 10.8918 14.4375 11.375C14.4375 11.8582 14.8293 12.25 15.3125 12.25C15.7957 12.25 16.1875 11.8582 16.1875 11.375C16.1875 10.8918 15.7957 10.5 15.3125 10.5Z">
                                                        </path>
                                                    </svg>
                                                </div>
                                            </span>
                                        </div>
                                        <div className="tf-progress-msg">
                                            Buy <span className="price fw-6">₹75.00</span> more to enjoy <span className="fw-6">Free
                                                Shipping</span>
                                        </div>
                                    </div> */}
                                    <div className="tf-page-cart-checkout">
                                        <div className="tf-cart-totals-discounts">
                                            <h6>Subtotal</h6>
                                            <span className="total-value">₹ {calculateSubtotal()}</span>
                                        </div>
                                        <p className="tf-cart-tax">
                                            Taxes and shipping calculated at checkout.
                                        </p>
                                        <div className="form-check cart-checkbox">
                                            <input className="form-check-input" type="checkbox" checked={formik.values.isChecked} onChange={handleCheckboxChange} />
                                            <label htmlFor="I-agree-with-me" className="fw-4">
                                                I agree with the <Link to="/">terms and conditions</Link>
                                            </label>
                                        </div>
                                        <div className="error-message" style={{ color: 'red' }}>
                                            {formik.errors.isChecked}
                                        </div>
                                        <div className="cart-checkout-btn" style={{ cursor: 'pointer' }}>
                                            <button
                                                className={`tf-btn w-100 btn`}
                                                style={{ backgroundColor: formik.values.isChecked && '#000', color: formik.values.isChecked ? '#fff' : '#000' }}
                                                disabled={formik.values.isChecked ? false : true}
                                                onClick={handleCheckout}
                                            >
                                                <span>Check out</span>
                                            </button>
                                        </div>
                                        {/* <div className="tf-page-cart_imgtrust">
                                            <p className="text-center fw-6">Guarantee Safe Checkout</p>
                                            <div className="cart-list-social">
                                                <div className="payment-item">
                                                    <svg viewBox="0 0 38 24" xmlns="http://www.w3.org/2000/svg" role="img" width={38} height={24} aria-labelledby="pi-visa">
                                                        <title id="pi-visa">Visa</title>
                                                        <path opacity=".07" d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z">
                                                        </path>
                                                        <path fill="#fff" d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32">
                                                        </path>
                                                        <path d="M28.3 10.1H28c-.4 1-.7 1.5-1 3h1.9c-.3-1.5-.3-2.2-.6-3zm2.9 5.9h-1.7c-.1 0-.1 0-.2-.1l-.2-.9-.1-.2h-2.4c-.1 0-.2 0-.2.2l-.3.9c0 .1-.1.1-.1.1h-2.1l.2-.5L27 8.7c0-.5.3-.7.8-.7h1.5c.1 0 .2 0 .2.2l1.4 6.5c.1.4.2.7.2 1.1.1.1.1.1.1.2zm-13.4-.3l.4-1.8c.1 0 .2.1.2.1.7.3 1.4.5 2.1.4.2 0 .5-.1.7-.2.5-.2.5-.7.1-1.1-.2-.2-.5-.3-.8-.5-.4-.2-.8-.4-1.1-.7-1.2-1-.8-2.4-.1-3.1.6-.4.9-.8 1.7-.8 1.2 0 2.5 0 3.1.2h.1c-.1.6-.2 1.1-.4 1.7-.5-.2-1-.4-1.5-.4-.3 0-.6 0-.9.1-.2 0-.3.1-.4.2-.2.2-.2.5 0 .7l.5.4c.4.2.8.4 1.1.6.5.3 1 .8 1.1 1.4.2.9-.1 1.7-.9 2.3-.5.4-.7.6-1.4.6-1.4 0-2.5.1-3.4-.2-.1.2-.1.2-.2.1zm-3.5.3c.1-.7.1-.7.2-1 .5-2.2 1-4.5 1.4-6.7.1-.2.1-.3.3-.3H18c-.2 1.2-.4 2.1-.7 3.2-.3 1.5-.6 3-1 4.5 0 .2-.1.2-.3.2M5 8.2c0-.1.2-.2.3-.2h3.4c.5 0 .9.3 1 .8l.9 4.4c0 .1 0 .1.1.2 0-.1.1-.1.1-.1l2.1-5.1c-.1-.1 0-.2.1-.2h2.1c0 .1 0 .1-.1.2l-3.1 7.3c-.1.2-.1.3-.2.4-.1.1-.3 0-.5 0H9.7c-.1 0-.2 0-.2-.2L7.9 9.5c-.2-.2-.5-.5-.9-.6-.6-.3-1.7-.5-1.9-.5L5 8.2z" fill="#142688" />
                                                    </svg>
                                                </div>
                                                <div className="payment-item">
                                                    <svg viewBox="0 0 38 24" xmlns="http://www.w3.org/2000/svg" width={38} height={24} role="img" aria-labelledby="pi-paypal">
                                                        <title id="pi-paypal">PayPal</title>
                                                        <path opacity=".07" d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z">
                                                        </path>
                                                        <path fill="#fff" d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32">
                                                        </path>
                                                        <path fill="#003087" d="M23.9 8.3c.2-1 0-1.7-.6-2.3-.6-.7-1.7-1-3.1-1h-4.1c-.3 0-.5.2-.6.5L14 15.6c0 .2.1.4.3.4H17l.4-3.4 1.8-2.2 4.7-2.1z">
                                                        </path>
                                                        <path fill="#3086C8" d="M23.9 8.3l-.2.2c-.5 2.8-2.2 3.8-4.6 3.8H18c-.3 0-.5.2-.6.5l-.6 3.9-.2 1c0 .2.1.4.3.4H19c.3 0 .5-.2.5-.4v-.1l.4-2.4v-.1c0-.2.3-.4.5-.4h.3c2.1 0 3.7-.8 4.1-3.2.2-1 .1-1.8-.4-2.4-.1-.5-.3-.7-.5-.8z">
                                                        </path>
                                                        <path fill="#012169" d="M23.3 8.1c-.1-.1-.2-.1-.3-.1-.1 0-.2 0-.3-.1-.3-.1-.7-.1-1.1-.1h-3c-.1 0-.2 0-.2.1-.2.1-.3.2-.3.4l-.7 4.4v.1c0-.3.3-.5.6-.5h1.3c2.5 0 4.1-1 4.6-3.8v-.2c-.1-.1-.3-.2-.5-.2h-.1z">
                                                        </path>
                                                    </svg>
                                                </div>
                                                <div className="payment-item">
                                                    <svg viewBox="0 0 38 24" xmlns="http://www.w3.org/2000/svg" role="img" width={38} height={24} aria-labelledby="pi-master">
                                                        <title id="pi-master">Mastercard</title>
                                                        <path opacity=".07" d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z">
                                                        </path>
                                                        <path fill="#fff" d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32">
                                                        </path>
                                                        <circle fill="#EB001B" cx={15} cy={12} r={7} />
                                                        <circle fill="#F79E1B" cx={23} cy={12} r={7} />
                                                        <path fill="#FF5F00" d="M22 12c0-2.4-1.2-4.5-3-5.7-1.8 1.3-3 3.4-3 5.7s1.2 4.5 3 5.7c1.8-1.2 3-3.3 3-5.7z">
                                                        </path>
                                                    </svg>
                                                </div>
                                                <div className="payment-item">
                                                    <svg xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="pi-american_express" viewBox="0 0 38 24" width={38} height={24}>
                                                        <title id="pi-american_express">American Express</title>
                                                        <path fill="#000" d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3Z" opacity=".07" />
                                                        <path fill="#006FCF" d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32Z">
                                                        </path>
                                                        <path fill="#FFF" d="M22.012 19.936v-8.421L37 11.528v2.326l-1.732 1.852L37 17.573v2.375h-2.766l-1.47-1.622-1.46 1.628-9.292-.02Z">
                                                        </path>
                                                        <path fill="#006FCF" d="M23.013 19.012v-6.57h5.572v1.513h-3.768v1.028h3.678v1.488h-3.678v1.01h3.768v1.531h-5.572Z">
                                                        </path>
                                                        <path fill="#006FCF" d="m28.557 19.012 3.083-3.289-3.083-3.282h2.386l1.884 2.083 1.89-2.082H37v.051l-3.017 3.23L37 18.92v.093h-2.307l-1.917-2.103-1.898 2.104h-2.321Z">
                                                        </path>
                                                        <path fill="#FFF" d="M22.71 4.04h3.614l1.269 2.881V4.04h4.46l.77 2.159.771-2.159H37v8.421H19l3.71-8.421Z">
                                                        </path>
                                                        <path fill="#006FCF" d="m23.395 4.955-2.916 6.566h2l.55-1.315h2.98l.55 1.315h2.05l-2.904-6.566h-2.31Zm.25 3.777.875-2.09.873 2.09h-1.748Z">
                                                        </path>
                                                        <path fill="#006FCF" d="M28.581 11.52V4.953l2.811.01L32.84 9l1.456-4.046H37v6.565l-1.74.016v-4.51l-1.644 4.494h-1.59L30.35 7.01v4.51h-1.768Z">
                                                        </path>
                                                    </svg>
                                                </div>
                                                <div className="payment-item">
                                                    <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 38 24" width={38} height={24} aria-labelledby="pi-amazon">
                                                        <title id="pi-amazon">Amazon</title>
                                                        <path d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z" fill="#000" fillRule="nonzero" opacity=".07" />
                                                        <path d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32" fill="#FFF" fillRule="nonzero" />
                                                        <path d="M25.26 16.23c-1.697 1.48-4.157 2.27-6.275 2.27-2.97 0-5.644-1.3-7.666-3.463-.16-.17-.018-.402.173-.27 2.183 1.504 4.882 2.408 7.67 2.408 1.88 0 3.95-.46 5.85-1.416.288-.145.53.222.248.47v.001zm.706-.957c-.216-.328-1.434-.155-1.98-.078-.167.024-.193-.148-.043-.27.97-.81 2.562-.576 2.748-.305.187.272-.047 2.16-.96 3.063-.14.138-.272.064-.21-.12.205-.604.664-1.96.446-2.29h-.001z" fill="#F90" fillRule="nonzero" />
                                                        <path d="M21.814 15.291c-.574-.498-.676-.73-.993-1.205-.947 1.012-1.618 1.315-2.85 1.315-1.453 0-2.587-.938-2.587-2.818 0-1.467.762-2.467 1.844-2.955.94-.433 2.25-.51 3.25-.628v-.235c0-.43.033-.94-.208-1.31-.212-.333-.616-.47-.97-.47-.66 0-1.25.353-1.392 1.085-.03.163-.144.323-.3.33l-1.677-.187c-.14-.033-.296-.153-.257-.38.386-2.125 2.223-2.766 3.867-2.766.84 0 1.94.234 2.604.9.842.82.762 1.918.762 3.11v2.818c0 .847.335 1.22.65 1.676.113.164.138.36-.003.482-.353.308-.98.88-1.326 1.2a.367.367 0 0 1-.414.038zm-1.659-2.533c.34-.626.323-1.214.323-1.918v-.392c-1.25 0-2.57.28-2.57 1.82 0 .782.386 1.31 1.05 1.31.487 0 .922-.312 1.197-.82z" fill="#221F1F" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            </div>
                           </div>
                        </div>
                    </div>
                ) : (
                    <div className="text-center wishlist-section">
                        <p className='mb-2'>Your Shopping Cart is Empty!</p>
                        {!user?.id ? (
                            <>
                                <p><Link to='/login'>Sign in</Link> to link items to your account, or view items already in your account</p>
                                <Button outlined onClick={() => navigate('/login')}>Login</Button>
                            </>
                        ) :
                            <Button outlined onClick={() => navigate('/')}>Continue Shopping</Button>
                        }
                    </div>
                )}
            </div>
        </section>

    )
}

export default CartDetails