import LocalstorageService from "../../helpers/localstorage-services";
import { profileservice } from "../../services/Profile";
import { editProfileFailure, editProfileRequest, editProfileSuccess } from "../../slices/ProfileSlice";

export const profilUpdater = (payload) => {
    return (dispatch) => {
        dispatch(editProfileRequest());
        profileservice.updateprofile(payload)
            .then((response) => {
                const { status, message, data, error } = response.data;
                if (status === 1) {
                    LocalstorageService.setLoggedInUserDetails(data);
                    dispatch(editProfileSuccess({ data, message }))
                } else {
                    dispatch(editProfileFailure(error));
                }
            })
            .catch((error) => {
                dispatch(editProfileFailure(error));
            });
    };
}