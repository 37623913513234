import { createSlice } from "@reduxjs/toolkit";
import { AUTH_SLICE } from "../helpers/slice.name";

export const authSlice = createSlice({
  name: AUTH_SLICE,
  initialState: {
    loading: false,

    loginLoading: false,
    loggedInUser: null,
    loggedInUserEmail: "",
    loginMessage: "",
    loginError: "",
    loginSetPasswordMessage: null,

    emailVerify: null,
    emailVerifyMessage: "",
    emailVerifyError: "",

    signUpMessage: "",
    signUpError: "",

    resend: null,
    resendMessage: "",
    resendError: "",

    setPasswordFirstLoginLoading: false,
    setPasswordFirstLoginError: "",
    setPasswordFirstLoginMessage: "",

    forgotPasswordLoading: false,
    forgotPasswordError: "",
    forgotPasswordMessage: "",

    resetPasswordLoading: false,
    resetPasswordError: "",
    resetPasswordMessage: "",

    logoutLoading: false,
    logoutMessage: "",
    logoutError: "",

    sendMobileOtpLoading: false,
    sendMobileOtp: "",
    sendMobileOtpMessage: "",
    sendMobileOtpError: null,

    mobileOtpLoading: false,
    mobileOtp: null,
    mobileOtpMessage: "",
    mobileOtpError: null,


    verifyMobileOtpLoading: false,
    verifyMobileOtp: null,
    verifyMobileOtpMessage: "",
    verifyMobileOtpError: null,

    verifyEmailCodeLoading: false,
    verifyEmailCode: null,
    verifyEmailCodeMessage: "",
    verifyEmailCodeError: null,

  },
  reducers: {
    loginRequest: (state) => {
      state.loginLoading = true;
      state.loginMessage = "";
      state.loginError = "";
    },
    loginSuccess: (state, action) => {
      state.loginLoading = false;
      state.loggedInUser = action.payload.userObj;
      state.loginMessage = action.payload.message;
    },
    loginError: (state, action) => {
      state.loginLoading = false;
      state.loginError = action.payload;
    },

    signUpRequest: (state) => {
      state.loading = true;
      state.signUpMessage = "";
      state.signUpError = "";
    },

    signUpSuccess: (state, action) => {
      state.loading = false;
      state.loggedInUser = action.payload.userObj;
      state.signUpMessage = action.payload.message;
    },

    signUpError: (state, action) => {
      state.loading = false;
      state.signUpError = action.payload;
    },

    emailVerificationRequest: (state, action) => {
      state.loading = true;
      state.emailVerify = null;
      state.emailVerifyMessage = "";
      state.emailVerifyError = "";
    },

    emailVerificationSuccess: (state, action) => {
      state.loading = false;
      state.emailVerifyMessage = action.payload;
    },

    emailVerificationError: (state, action) => {
      state.loading = false;
      state.emailVerifyError = action.payload;
    },

    resendCodeRequest: (state, action) => {
      state.loading = true;
      state.resend = null;
      state.resendMessage = "";
      state.resendError = "";
    },

    resendCodeSuccess: (state, action) => {
      state.loading = false;
      state.resendMessage = action.payload;
    },

    resendCodeError: (state, action) => {
      state.loading = false;
      state.resendError = action.payload;
    },

    setLoggedInUser: (state, action) => {
      state.loggedInUserEmail = action.payload;
    },
    setLoggedInUserForLogout: (state, action) => {
      state.loggedInUser = action.payload;
    },
    resetLogin: (state, action) => {
      state.loginMessage = "";
      state.loginError = "";
    },
    resetSignup: (state, action) => {
      state.signUpMessage = "";
      state.signUpError = "";
    },

    forgotPasswordRequest: (state, action) => {
      state.forgotPasswordLoading = true;
      state.forgotPasswordMessage = "";
      state.forgotPasswordError = "";
    },
    forgotPasswordSuccess: (state, action) => {
      state.forgotPasswordLoading = false;
      state.forgotPassword = action.payload.data;
      state.forgotPasswordMessage = action.payload.message;
    },
    forgotPasswordError: (state, action) => {
      state.forgotPasswordLoading = false;
      state.forgotPasswordError = action.payload;
    },
    forgetPasswordReset: (state, action) => {
      state.forgotPasswordError = "";
      state.forgotPasswordMessage = "";
    },

    verifyEmailCodeRequest: (state, action) => {
      state.verifyEmailCodeLoading = true;
      state.verifyEmailCodeMessage = "";
      state.verifyEmailCodeError = "";
    },
    verifyEmailCodeSuccess: (state, action) => {
      state.verifyEmailCodeLoading = false;
      state.verifyEmailCode = action.payload.data;
      state.verifyEmailCodeMessage = action.payload.message;
    },
    verifyEmailCodeError: (state, action) => {
      state.verifyEmailCodeLoading = false;
      state.verifyEmailCodeError = action.payload;
    },


    resetPasswordRequest: (state) => {
      state.resetPasswordLoading = true;
    },
    resetPasswordSuccess: (state, action) => {
      state.resetPasswordLoading = false;
      state.resetPasswordMessage = action.payload;
    },
    resetPasswordError: (state, action) => {
      state.resetPasswordLoading = false;
      state.resetPasswordError = action.payload;
    },
    resetPasswordReset: (state, action) => {
      state.resetPasswordError = "";
      state.resetPasswordMessage = "";
    },

    logoutRequest: (state) => {
      state.logoutLoading = true;
      state.logoutMessage = "";
    },
    logoutSuccess: (state, action) => {
      state.logoutLoading = false;
      state.logoutMessage = action.payload;
      state.loginMessage = "";
      state.loginError = "";
    },
    logoutError: (state, action) => {
      state.logoutLoading = false;
      state.logoutError = action.payload;
    },
    resetLogoutState: (state) => {
      state.logoutLoading = false;
      state.logoutMessage = "";
      state.logoutError = "";
    },

    sendMobileOtpRequest: (state) => {
      state.sendMobileOtpLoading = true;
      state.sendMobileOtp = null;
      state.sendMobileOtpMessage = "";
      state.sendMobileOtpError = null;
    },
    sendMobileOtpSuccess: (state, action) => {
      state.sendMobileOtpLoading = false;
      state.sendMobileOtp = action.payload.data;
      state.sendMobileOtpMessage = action.payload.message;
    },
    sendMobileOtpError: (state, action) => {
      state.sendMobileOtpLoading = false;
      state.sendMobileOtpError = action.payload;
    },

    mobileOtpRequest: (state) => {
      state.mobileOtpLoading = true;
      state.mobileOtp = null;
      state.mobileOtpMessage = "";
      state.mobileOtpError = null;
    },
    mobileOtpSuccess: (state, action) => {
      state.mobileOtpLoading = false;
      state.mobileOtp = action.payload.data;
      state.mobileOtpMessage = action.payload.message;
    },
    mobileOtpError: (state, action) => {
      state.mobileOtpLoading = false;
      state.mobileOtpError = action.payload;
    },

    verifyMobileOtpRequest: (state) => {
      state.verifyMobileOtpLoading = true;
      state.verifyMobileOtp = null;
      state.verifyMobileOtpMessage = "";
      state.verifyMobileOtpError = null;
    },
    verifyMobileOtpSuccess: (state, action) => {
      state.verifyMobileOtpLoading = false;
      state.verifyMobileOtp = action.payload.data;
      state.verifyMobileOtpMessage = action.payload.message;
    },
    verifyMobileOtpError: (state, action) => {
      state.verifyMobileOtpLoading = false;
      state.verifyMobileOtpError = action.payload;
    },

  },
});

export const {
  loginRequest,
  loginSuccess,
  loginError,
  loginEmail,
  resetLogin,
  signUpRequest,
  signUpSuccess,
  signUpError,
  resetSignup,
  setLoggedInUser,
  
  resetPasswordRequest,
  resetPasswordError,
  resetPasswordSuccess,
  resetPasswordReset,

  logoutRequest,
  logoutSuccess,
  logoutError,
  resetLogoutState,
  setLoggedInUserForLogout,
  resendCodeError,
  resendCodeRequest,
  resendCodeSuccess,
  emailVerificationRequest,
  emailVerificationError,
  emailVerificationSuccess,

  sendMobileOtpRequest,
  sendMobileOtpSuccess,
  sendMobileOtpError,

  verifyMobileOtpRequest,
  verifyMobileOtpSuccess,
  verifyMobileOtpError,

  forgotPasswordRequest,
  forgotPasswordSuccess,
  forgotPasswordError,
  forgetPasswordReset,

  verifyEmailCodeRequest,
  verifyEmailCodeSuccess,
  verifyEmailCodeError,
} = authSlice.actions;

export default authSlice.reducer;
