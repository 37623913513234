import React from 'react';
import { Tooltip } from 'react-bootstrap';

const ReactToolTip = (text) => (props) => {
    return (
        <Tooltip id="button-tooltip" {...props}>
            {text}
        </Tooltip>
    )
}

export default ReactToolTip