import React from 'react'
import { Link } from 'react-router-dom'

const FAQ = () => {
    return (
        <>
            <section className="page-title">
                <div className="pattern-layer"></div>
                <div className="container-full">
                    <div className="content-box">
                        <h3>Faq Page</h3>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item active" aria-current="page"><Link to='/'>Home</Link></li>
                                <li className="breadcrumb-item" aria-current="page">/</li>
                                <li className="breadcrumb-item" aria-current="page">Faqs</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </section>

            <section className='faq-section'>
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-10 col-sm-12">
                            <div className="faq-accordion">
                                <div class="accordion" id="accordionExample">
                                    <div class="accordion-item">
                                        <h6 class="accordion-header border-0 faq-accordion-header" id="headingOne">
                                            <button class="accordion-button faq-accordin-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                How did my package ship?
                                            </button>
                                        </h6>
                                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <p>Credibly engineer robust quality vectors before team building channels. Objectively do develop sticky vortals and tactical products. Proactively enhance its cooperative convergence with multimedia based leadership. Rapidiously brand client-centered action.Progressively expedite cutting-edge leadership whereas compelling human capital assertively parallel</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h6 class="accordion-header border-0 faq-accordion-header" id="headingTwo">
                                            <button class="accordion-button  faq-accordin-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                Why does my tracking number state 2-Day, 3-Day Delivery?
                                            </button>
                                        </h6>
                                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <p>Credibly engineer robust quality vectors before team building channels. Objectively do develop sticky vortals and tactical products. Proactively enhance its cooperative convergence with multimedia based leadership. Rapidiously brand client-centered action.Progressively expedite cutting-edge leadership whereas compelling human capital assertively parallel</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h6 class="accordion-header border-0 faq-accordion-header" id="headingThree">
                                            <button class="accordion-button faq-accordin-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                How long will my package take to arrive?
                                            </button>
                                        </h6>
                                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <p>Credibly engineer robust quality vectors before team building channels. Objectively do develop sticky vortals and tactical products. Proactively enhance its cooperative convergence with multimedia based leadership. Rapidiously brand client-centered action.Progressively expedite cutting-edge leadership whereas compelling human capital assertively parallel</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item">
                                        <h6 class="accordion-header border-0 faq-accordion-header" id="headingFour">
                                            <button class="accordion-button faq-accordin-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                What are business days?
                                            </button>
                                        </h6>
                                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <p>Credibly engineer robust quality vectors before team building channels. Objectively do develop sticky vortals and tactical products. Proactively enhance its cooperative convergence with multimedia based leadership. Rapidiously brand client-centered action.Progressively expedite cutting-edge leadership whereas compelling human capital assertively parallel</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item">
                                        <h6 class="accordion-header border-0 faq-accordion-header" id="headingFive">
                                            <button class="accordion-button faq-accordin-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                How do I know my package has shipped?
                                            </button>
                                        </h6>
                                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <p>Credibly engineer robust quality vectors before team building channels. Objectively do develop sticky vortals and tactical products. Proactively enhance its cooperative convergence with multimedia based leadership. Rapidiously brand client-centered action.Progressively expedite cutting-edge leadership whereas compelling human capital assertively parallel</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item">
                                        <h6 class="accordion-header border-0 faq-accordion-header" id="headingSix">
                                            <button class="accordion-button faq-accordin-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                Why are certain products unavailable to ship to Internationally?
                                            </button>
                                        </h6>
                                        <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <p>Credibly engineer robust quality vectors before team building channels. Objectively do develop sticky vortals and tactical products. Proactively enhance its cooperative convergence with multimedia based leadership. Rapidiously brand client-centered action.Progressively expedite cutting-edge leadership whereas compelling human capital assertively parallel</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="need-section">
                <div className="container">
                    <div className="need-wrapper">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="need-inner text-center">
                                    <h1>You Still Need Help?</h1>
                                    <p className='mt-3 mb-4 '>Things are better as a Vendora Member. Get first and exclusive access to the newest style & innovation, free shipping, birthday rewards and more..</p>
                                    <button className='btn btn-primary '><Link to='/contect-us'>Contact Us</Link></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default FAQ