import React from 'react';
import { Accordion } from 'react-bootstrap';

const ProductDetailsAccordion = ({ productDetails }) => {
    return (
        <div className="col-lg-8 col-md-8 col-sm-12">
            <Accordion flush>
                <Accordion.Item eventKey="0">
                    <Accordion.Header className="border-0">
                        <h6 className="font-16">Description</h6>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className="row mt-1">
                            {productDetails?.meta_description ? (
                                <p className="f-14 mb-1" dangerouslySetInnerHTML={{ __html: productDetails.meta_description }} />
                            ) : (
                                <p className="f-14 mb-1">Description not available.</p>
                            )}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>


                {/* <Accordion.Item eventKey="1">
                    <Accordion.Header className='border-0'>
                        <h6 className="font-16">Product Details</h6>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className="Product-detail-delivery">
                            <h5 className="font-16">Product Details</h5>
                            <p>Brown Tshirt for men</p>
                            <p>Graphic printed</p>
                            <p>Regular length</p>
                            <p>Round neck</p>
                            <p>Short, drop shoulder sleeves</p>
                            <p>Knitted cotton fabric</p>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                    <Accordion.Header className='border-0'>
                        <h6 className="font-16">Size &amp; Fit</h6>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className="Product-detail-delivery">
                            <h5 className="font-16">Size &amp; Fit</h5>
                            <p>Oversized</p>
                            <p>The model (height 6') is wearing a size M</p>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                    <Accordion.Header className='border-0'>
                        <h6 className="font-16">Material &amp; Care</h6>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className="Product-detail-delivery">
                            <h5 className="font-16">Material &amp; Care</h5>
                            <p className="font-12">Machine wash max. 30ºC. Short spin.</p>
                            <p className="font-12">Iron maximum 110ºC.</p>
                            <p className="font-12">Do not bleach/bleach.</p>
                            <p className="font-12">Do not dry clean.</p>
                            <p className="font-12">Tumble dry, medium heat.</p>
                        </div>
                    </Accordion.Body>
                </Accordion.Item> */}

                <Accordion.Item eventKey="4">
                    <Accordion.Header className='border-0'>
                        <h6 className="font-16">Specifications</h6>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className="row mt-1">
                            <div className='col-12'>
                                {productDetails?.product_specification ? (
                                    <p dangerouslySetInnerHTML={{ __html: productDetails.product_specification }} />
                                ) : (
                                    <p className="f-14 mb-1">Specifications not available.</p>
                                )}
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
};

export default ProductDetailsAccordion;
