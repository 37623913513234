import React from 'react'
import PageTitle from '../Components/Products/PageTitle';
import CartDetails from '../Components/Products/CartDetails';

const Cart = () => {

    return (
        <div>
            <main>
                <PageTitle name={'Shopping Cart'} />
                <CartDetails />
            </main>
        </div>
    )
}

export default Cart