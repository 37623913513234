import { createSlice } from "@reduxjs/toolkit";

const profileSlice = createSlice({
    name: 'Profile',
    initialState: {
        editProfileLoading: false,
        editProfile: '',
        editProfileMessage: '',
        editProfileError: '',
    },

    reducers: {
        editProfileRequest: (state) => {
            state.editProfileLoading = true
            state.editProfileMessage = ""
            state.editProfileError = ""
        },
        editProfileSuccess: (state, action) => {
            state.editProfileLoading = false
            state.editProfile = action.payload.data
            state.editProfileMessage = action.payload.message
        },
        editProfileFailure: (state, action) => {
            state.editProfileLoading = false
            state.editProfileError = action.payload.message
        },
        resetProfile: (state) => {
            state.editProfileMessage = "";
            state.editProfileError = "";
        },
    }
})

export const { editProfileRequest, editProfileSuccess, editProfileFailure, resetProfile } = profileSlice.actions
export default profileSlice.reducer