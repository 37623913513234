import OrderService from '../../services/userOrder';
import { createOrderError, createOrderRequest, createOrderSuccess, getOrderHistoryError, getOrderHistoryRequest, getOrderHistorySuccess } from '../../slices/orderSlice';

export const generateOrder = (payload) => {
    return (dispatch) => {
        dispatch(createOrderRequest());
        OrderService.createOrder(payload)
            .then((response) => {
                const { status, data, message, error } = response.data;
                if (status === 1) {
                    dispatch(createOrderSuccess({ data, message }))
                } else {
                    dispatch(createOrderError(error));
                }
            }).catch((error) => {
                dispatch(createOrderError(error))
            });
    }
}

export const getOrderData = (payload) => {
    return (dispatch) => {
        dispatch(getOrderHistoryRequest());
        OrderService.getOrderHistory(payload)
            .then((response) => {
                const { status, data, message, error } = response.data;
                // if (status === 1) {
                    dispatch(getOrderHistorySuccess({ data, message }))
                // } else {
                    dispatch(getOrderHistoryError(error));
                // }
            }).catch((error) => {
                dispatch(getOrderHistoryError(error));
            })
    }
}