import sidebarFilterService from "../../services/sidebarFilter";
import { sidebarFilterRequest, sidebarFilterError, sidebarFilterSuccess } from "../../slices/sidebarFilter.slice";
export function getSidebarFiltersData(payload) {
    return (dispatch) => {
        dispatch(sidebarFilterRequest());
        sidebarFilterService.getSidebarFilter(payload)
            .then((response) => {
                const { status, data, message, error } = response.data;
                // console.log(data, '44444')
                if (status === 1) {
                    dispatch(sidebarFilterSuccess({ data, message }));
                } else {
                    dispatch(sidebarFilterError(error));
                }
            }).catch((error) => {
                dispatch(sidebarFilterError(error));
            })
    }
}
