import { delPayload, postFormData } from ".";

const URI = "/cart";

const getCart = (payload) => {
    const URL = `${URI}/list`;
    return postFormData(URL, payload);
};

const addCart = (payload) => {
    const URL = `${URI}/add`;
    return postFormData(URL, payload);
};

const removeCart = (payload) => {
    const URL = `${URI}/delete`;
    return postFormData(URL, payload);
};
const updateQuantity = (payload) => {
    const URL = `${URI}/update-quantity`;
    return postFormData(URL, payload);
};

const CartService = {
    getCart,
    addCart,
    removeCart,
    updateQuantity
}

export default CartService;