export const ENV_PRODUCTION = "production";
export const ENV_DEVELOPMENT = "dev";

export const LOCALSTORAGE_USER_AUTH_TOKEN_KEY = "IN_USER_TOKEN";
export const LOCALSTORAGE_USER_AUTH_DETAILS_KEY = "IN_USER_DATA";
export const LOCALSTORAGE_CATEGORIES_KEY = "IN_CAT";
export const LOCALSTORAGE_WISHLIST_KEY = "IN_WISHLIST";
export const LOCALSTORAGE_CART_KEY = "IN_CART";

export const SERVER_AUTH_ERROR_STATUS_CODE = 401;
export const SERVER_VALIDATION_STATUS_CODE = 417;
export const GENERIC_ERROR_MESSAGE =
  "Something went wrong! Please try again later.";
