import { createSlice } from "@reduxjs/toolkit";

const addressSlice = createSlice({
    name: 'address',
    initialState: {
        countries: [],
        states: [],
        cities: [],
        primary: false,
        deleteAdd: false,
        SuccessMessage: "",
        ErrorMessage: "",

        getAddressLoading: false,
        userAddress: [],
        getAddressMessage: "",
        getAddressError: "",

        addAddressLoading: false,
        addAddress: '',
        addAddressError: '',
        addAddressMessage: '',

        primaryAddressLoading: false,
        updateAddressLoading: false,
        updateAddress: '',
        deleteAddress: false

    },
    reducers: {
        getAllCountries: (state, action) => {
            state.countries = action.payload;
        },
        getAllStates: (state, action) => {
            state.states = action.payload;
        },
        getAllCities: (state, action) => {
            state.cities = action.payload;
        },

        getAddressRequest: (state) => {
            state.getAddressLoading = true
            state.getAddressMessage = ""
            state.getAddressError = ""
        },
        getAddressSuccess: (state, action) => {
            state.getAddressLoading = false
            state.userAddress = action.payload.data
            state.getAddressMessage = action.payload.message
        },
        getAddressFailure: (state, action) => {
            state.getAddressLoading = false
            state.getAddressError = action.payload
        },

        addAddressRequest: (state) => {
            state.addAddressLoading = true
            state.addAddressMessage = ""
            state.addAddressMessage = ""
        },
        addAddressSuccess: (state, action) => {
            state.addAddressLoading = false
            state.addAddress = action.payload.data
            state.addAddressMessage = action.payload.message
        },
        addAddressFailure: (state, action) => {
            state.addAddressLoading = false
            state.addAddressMessage = action.payload
        },
        addAddressReset: (state, action) => {
            state.addAddressMessage = ""
            state.addAddressError = ""
        },

        updateAddressRequest: (state) => {
            state.updateAddressLoading = true
            state.SuccessMessage = ""
            state.ErrorMessage = ""
        },
        updateAddressSuccess: (state, action) => {
            state.updateAddressLoading = false
            state.updateAddress = action.payload.data
            state.SuccessMessage = action.payload.message
        },
        updateAddressFailure: (state, action) => {
            state.updateAddressLoading = false
            state.ErrorMessage = action.payload
        },

        setPrimary: (state) => {
            state.primary = false
        },
        primaryAddressRequest: (state) => {
            state.primaryAddressLoading = true
            state.SuccessMessage = ""
            state.ErrorMessage = ""
        },
        primaryAddressSuccess: (state, action) => {
            state.updateAddressLoading = false
            state.primary = action.payload.data
            state.SuccessMessage = action.payload.message
        },
        primaryAddressFailure: (state, action) => {
            state.updateAddressLoading = false
            state.ErrorMessage = action.payload
        },
        primaryAddressReset: (state, action) => {
            state.ErrorMessage = ""
            state.SuccessMessage = ""
        },

        deleteAddressRequest: (state) => {
            state.SuccessMessage = ""
            state.ErrorMessage = ""
        },
        setDeleteAddress: (state) => {
            state.deleteAdd = false
        },
        deleteAddressSuccess: (state, action) => {
            state.deleteAddress = action.payload
            state.deleteAdd = action.payload.data
            state.SuccessMessage = action.payload.message
        },
        deleteAddressFailure: (state, action) => {
            state.ErrorMessage = action.payload
        }
    }
});

export const { getAllCountries,
    getAllStates,
    getAllCities,
    getUserAddresses,

    getAddressRequest,
    getAddressSuccess,
    getAddressFailure,

    primaryAddressRequest,
    setPrimary,
    primaryAddressSuccess,
    primaryAddressFailure,
    primaryAddressReset,

    addAddressRequest,
    addAddressFailure,
    addAddressSuccess,
    addAddressReset,

    updateAddressFailure,
    updateAddressSuccess,
    updateAddressRequest,

    deleteAddressRequest,
    setDeleteAddress,
    deleteAddressSuccess,
    deleteAddressFailure
} = addressSlice.actions;
export default addressSlice.reducer;
