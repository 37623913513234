import React from 'react'
import { Link } from 'react-router-dom'

const BlogPage = () => {
    return (
        <>
            <main>
                <section className="page-title">
                    <div className="pattern-layer"></div>
                    <div className="container-full">
                        <div className="content-box">
                            <h3>Blogs</h3>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item active" aria-current="page"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item" aria-current="page">/</li>
                                    <li className="breadcrumb-item" aria-current="page">Blog Page</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </section>

                <section className="article-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-3 col-md-4 col-12">
                                <div className="blog-article-item">
                                    <div className="article-thumb">
                                        <Link to='/blog-details'>
                                            <img className="lazyload" data-src="/assets/images/blog/blog-2.jpg" src="/assets/images/blog/blog-2.jpg" alt="img-blog" />
                                        </Link>
                                        <div className="article-label">
                                            <Link to='/blog-details' className="tf-btn btn-sm radius-3 btn-fill animate-hover-btn">Accessories</Link>
                                        </div>
                                    </div>
                                    <div className="article-content">
                                        <div className="article-title">
                                            <Link to='/blog-details' className>The next generation of leather alternatives</Link>
                                        </div>
                                        <div className="article-btn">
                                            <Link to='/blog-details' className="tf-btn btn-line fw-6">Read more<i className="icon fa-solid fa-arrow-right px-1" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-4 col-12">
                                <div className="blog-article-item">
                                    <div className="article-thumb">
                                        <Link to='/blog-details'>
                                            <img className="lazyload" data-src="/assets/images/blog/blog-3.jpg" src="/assets/images/blog/blog-3.jpg" alt="img-blog" />
                                        </Link>
                                        <div className="article-label">
                                            <Link to='/blog-details' className="tf-btn btn-sm radius-3 btn-fill animate-hover-btn">Accessories</Link>
                                        </div>
                                    </div>
                                    <div className="article-content">
                                        <div className="article-title">
                                            <Link to='/blog-details' className>An Exclusive Clothing Collaboration</Link>
                                        </div>
                                        <div className="article-btn">
                                            <Link to='/blog-details' className="tf-btn btn-line fw-6">Read more<i className="icon fa-solid fa-arrow-right px-1" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-4 col-12">
                                <div className="blog-article-item">
                                    <div className="article-thumb">
                                        <Link to='/blog-details'>
                                            <img className="lazyload" data-src="/assets/images/blog/blog-4.jpg" src="/assets/images/blog/blog-4.jpg" alt="img-blog" />
                                        </Link>
                                        <div className="article-label">
                                            <Link to='/blog-details' className="tf-btn btn-sm radius-3 btn-fill animate-hover-btn">Accessories</Link>
                                        </div>
                                    </div>
                                    <div className="article-content">
                                        <div className="article-title">
                                            <Link to='/blog-details' className>Hello Fashion by Colombian-American</Link>
                                        </div>
                                        <div className="article-btn">
                                            <Link to='/blog-details' className="tf-btn btn-line fw-6">Read more<i className="icon fa-solid fa-arrow-right px-1" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-4 col-12">
                                <div className="blog-article-item">
                                    <div className="article-thumb">
                                        <Link to='/blog-details'>
                                            <img className="lazyload" data-src="/assets/images/blog/blog-5.jpg" src="/assets/images/blog/blog-5.jpg" alt="img-blog" />
                                        </Link>
                                        <div className="article-label">
                                            <Link to='/blog-details' className="tf-btn btn-sm radius-3 btn-fill animate-hover-btn">Accessories</Link>
                                        </div>
                                    </div>
                                    <div className="article-content">
                                        <div className="article-title">
                                            <Link to='/blog-details' className>Christine Is A True Style Icon</Link>
                                        </div>
                                        <div className="article-btn">
                                            <Link to='/blog-details' className="tf-btn btn-line fw-6">Read more<i className="icon fa-solid fa-arrow-right px-1" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-4 col-12">
                                <div className="blog-article-item">
                                    <div className="article-thumb">
                                        <Link to='/blog-details'>
                                            <img className="lazyload" data-src="/assets/images/blog/blog-7.jpg" src="/assets/images/blog/blog-7.jpg" alt="img-blog" />
                                        </Link>
                                        <div className="article-label">
                                            <Link to='/blog-details' className="tf-btn btn-sm radius-3 btn-fill animate-hover-btn">Design</Link>
                                        </div>
                                    </div>
                                    <div className="article-content">
                                        <div className="article-title">
                                            <Link to='/blog-details' className>Effortlessly Blends The Carefree Style</Link>
                                        </div>
                                        <div className="article-btn">
                                            <Link to='/blog-details' className="tf-btn btn-line fw-6">Read more<i className="icon fa-solid fa-arrow-right px-1" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-4 col-12">
                                <div className="blog-article-item">
                                    <div className="article-thumb">
                                        <Link to='/blog-details'>
                                            <img className="lazyload" data-src="/assets/images/blog/blog-6.jpg" src="/assets/images/blog/blog-6.jpg" alt="img-blog" />
                                        </Link>
                                        <div className="article-label">
                                            <Link to='/blog-details' className="tf-btn btn-sm radius-3 btn-fill animate-hover-btn">Accessories</Link>
                                        </div>
                                    </div>
                                    <div className="article-content">
                                        <div className="article-title">
                                            <Link to='/blog-details' className>The Variety Of Styles And Prices Are Endless</Link>
                                        </div>
                                        <div className="article-btn">
                                            <Link to='/blog-details' className="tf-btn btn-line fw-6">Read more<i className="icon fa-solid fa-arrow-right px-1" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-4 col-12">
                                <div className="blog-article-item">
                                    <div className="article-thumb">
                                        <Link to='/blog-details'>
                                            <img className="lazyload" data-src="/assets/images/blog/blog-8.jpg" src="/assets/images/blog/blog-8.jpg" alt="img-blog" />
                                        </Link>
                                        <div className="article-label">
                                            <Link to='/blog-details' className="tf-btn btn-sm radius-3 btn-fill animate-hover-btn">Design</Link>
                                        </div>
                                    </div>
                                    <div className="article-content">
                                        <div className="article-title">
                                            <Link to='/blog-details' className>Something About This Style Of Jeans</Link>
                                        </div>
                                        <div className="article-btn">
                                            <Link to='/blog-details' className="tf-btn btn-line fw-6">Read more<i className="icon fa-solid fa-arrow-right px-1" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-4 col-12">
                                <div className="blog-article-item">
                                    <div className="article-thumb">
                                        <Link to='/blog-details'>
                                            <img className="lazyload" data-src="/assets/images/blog/blog-9.jpg" src="/assets/images/blog/blog-9.jpg" alt="img-blog" />
                                        </Link>
                                        <div className="article-label">
                                            <Link to='/blog-details' className="tf-btn btn-sm radius-3 btn-fill animate-hover-btn">Tech</Link>
                                        </div>
                                    </div>
                                    <div className="article-content">
                                        <div className="article-title">
                                            <Link to='/blog-details' className>One Of The Most Iconic Fashion Bloggers</Link>
                                        </div>
                                        <div className="article-btn">
                                            <Link to='/blog-details' className="tf-btn btn-line fw-6">Read more<i className="icon fa-solid fa-arrow-right px-1" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center">
                            <nav aria-label="Table Pagination">
                                <ul className="pagination style-1">
                                    <li className="page-item"><a className="page-link" href="javascript:void(0);">Prew</a></li>
                                    <li className="page-item"><a className="page-link" href="javascript:void(0);">1</a></li>
                                    <li className="page-item"><a className="page-link" href="javascript:void(0);">2</a></li>
                                    <li className="page-item"><a className="page-link" href="javascript:void(0);">3</a></li>
                                    <li className="page-item"><a className="page-link" href="javascript:void(0);">Next</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </section>

                <section className="newest-section">
                    <div className="container">
                        <div className="authors-section">
                            <div className="row mb-3">
                                <div className="col-12">
                                    <h6 className="blog-title">Newest blog</h6>
                                    <p className="blog-disc">Say hello to future creator potentials</p>
                                </div>
                            </div>
                            <div className="newest-wrapper">
                                <div className="row">
                                    <div className="col-lg-3 col-md-4 col-sm-12">
                                        <a href>
                                            <div className="authors-card">
                                                <div className="authors-bg-img">
                                                    <img src="/assets/images/author/author-bg.png" />
                                                </div>
                                                <div className="authors-img">
                                                    <img src="/assets/images/author/profile-1.jpg" alt="author-img" />
                                                </div>
                                                <div className="authors-name">
                                                    <h5 className="text-center">Bhavik Sarkhedi</h5>
                                                    <p className="text-center">@ Write</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-12">
                                        <a href="./blog-detail.html">
                                            <div className="authors-card">
                                                <div className="authors-bg-img">
                                                    <img src="/assets/images/author/author-bg.png" />
                                                </div>
                                                <div className="authors-img">
                                                    <img src="/assets/images/author/author-1.jpg" alt="author-img" />
                                                </div>
                                                <div className="authors-name">
                                                    <h5 className="text-center">Bhavik Sarkhedi</h5>
                                                    <p className="text-center">@ Authors</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-12">
                                        <a href>
                                            <div className="authors-card">
                                                <div className="authors-bg-img">
                                                    <img src="/assets/images/author/author-bg.png" />
                                                </div>
                                                <div className="authors-img">
                                                    <img src="/assets/images/author/author-1.jpg" alt="author-img" />
                                                </div>
                                                <div className="authors-name">
                                                    <h5 className="text-center">Bhavik Sarkhedi</h5>
                                                    <p className="text-center">@ Authors</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-12">
                                        <a href>
                                            <div className="authors-card">
                                                <div className="authors-bg-img">
                                                    <img src="/assets/images/author/author-bg.png" />
                                                </div>
                                                <div className="authors-img">
                                                    <img src="/assets/images/author/author-1.jpg" alt="author-img" />
                                                </div>
                                                <div className="authors-name">
                                                    <h5 className="text-center">Bhavik Sarkhedi</h5>
                                                    <p className="text-center">@ Authors</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
        </>
    )
}

export default BlogPage