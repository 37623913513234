import { get, post } from ".";

const URI = "/auth";

const login = (payload) => {
  const URL = `${URI}/login`;
  return post(URL, payload);
};
const signUp = (payload) => {
  const URL = `${URI}/signup`;
  return post(URL, payload);
};

const verifyEmail = (payload) => {
  const URL = `${URI}/confirm_code`;
  return post(URL, payload);
};

const resendCode = (payload) => {
  const URL = `${URI}/resend_code`;
  return get(URL, payload);
};

const forgotPassword = (payload) => {
  const URL = `${URI}/forgot-password/send-code`;
  return post(URL, payload);
};

const verifyEmailCode = (payload) => {
  const URL = `${URI}/forgot-password/verify-code`;
  return post(URL, payload);
};



const resetPassword = (payload) => {
  const URL = `${URI}/email-reset-password`;
  return post(URL, payload);
};

const sendPhoneNumberOtp = (payload) => {
  const URL = `${URI}/send-otp`;
  return post(URL, payload);
}

const verifyPhoneNumberOtpUrl = (payload) => {
  const URL = `${URI}/verify-otp`;
  return post(URL, payload);
}

const logout = () => {
  const URL = `${URI}/logout`;
  return post(URL);
};

const AuthService = {
  login,
  signUp,
  forgotPassword,
  resetPassword,
  logout,
  verifyEmail,
  resendCode,
  sendPhoneNumberOtp,
  verifyPhoneNumberOtpUrl,
  verifyEmailCode
};
export default AuthService;
