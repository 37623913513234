import React, { useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/swiper-bundle.css";
import { useDispatch, useSelector } from "react-redux";
import LocalstorageService from "../../helpers/localstorage-services";
import {
  getCartData,
  removeCartItem,
  updateCart,
} from "../../middlewares/cart/cart";
import Skeleton from "react-loading-skeleton";
import { toast } from 'react-toastify';
import { resetDeleteCartList, resetUpdateCart } from "../../slices/cart";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";

const SideCartMenu = ({ showSideCart, handleCloseSideCart }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    cart,
    cartError,
    cartLoading,
    cartMessage,
    cartRemoveError,
    cartRemoveLoading,
    cartRemoveMessage,
    cartUpdate,
    cartUpdateMessage,
    cartUpdateError,
    cartUpdateLoading,
  } = useSelector((state) => state.cart);

  const [hasRemovedItem, setHasRemovedItem] = useState(false);
  const user = LocalstorageService.getLoggedInUserDetails();

  const fetchCartData = useCallback(() => {
    if (user) {
      const bodyFormData = new FormData();
      bodyFormData.append("user_id", user.id);
      dispatch(getCartData(bodyFormData));
    }
  }, [dispatch]);

  useEffect(() => {
    fetchCartData();
  }, [showSideCart]);

  useEffect(() => {
    if (cartUpdateMessage) {
      const bodyFormData = new FormData();
      bodyFormData.append("user_id", user.id);
      dispatch(getCartData(bodyFormData));
      // toast.success(cartUpdateMessage)
      dispatch(resetUpdateCart());
    }
    if(cartUpdateError){
      toast.error(cartUpdateError)
      dispatch(resetUpdateCart());
    }
    if (cartRemoveMessage && hasRemovedItem) {
      toast.success(cartRemoveMessage);
      dispatch(resetDeleteCartList());
      fetchCartData();
      setHasRemovedItem(false);
    }
  }, [cartUpdateMessage, cartUpdateError, cartRemoveMessage, hasRemovedItem, dispatch]);
  
  
  const handleRemoveCartItem = (id) => {
    const bodyFormData = new FormData();
    bodyFormData.append("id", id);
    dispatch(removeCartItem(bodyFormData));
    setHasRemovedItem(true);
  };

  const handleNavigateProduct = (elem) => {
    navigate(`${elem?.item_category_slug}/product-details/${elem?.product_id}`);
    handleCloseSideCart();
  };

  const handleQuantityChange = (id, quantityChange) => {
    const updatedCart = cart.map((item) => {
      console.log("item.quantity",item.quantity);
      
      if (item.id === id ) {
        const newQuantity = Math.max(item.quantity + quantityChange, 1);

        return { ...item, quantity: newQuantity };
      }
      return item;
    });

    const bodyFormData = new FormData();
    bodyFormData.append("id", id);
    bodyFormData.append("quantity", updatedCart.find((item) => item?.id === id).quantity);
    dispatch(updateCart(bodyFormData));
  };

  const calculateSubtotal = () => {
    let subtotal = 0;
    cart.forEach((item) => {
      subtotal += item?.cart_indiazona_price;
    });
    return subtotal.toFixed(2);
  };

  return (
    <Offcanvas show={showSideCart} onHide={handleCloseSideCart} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Shopping cart</Offcanvas.Title>
      </Offcanvas.Header>
      <div className="offcanvas-body">
        <div className="wrap position-relative">
          <div className="tf-mini-cart-wrap ">
            <div className="tf-mini-cart-main sidecart-menu">
              <div className="tf-mini-cart-sroll">
                <div className="tf-mini-cart-items">
                  {cartLoading ? (
                    Array.from({ length: 4 }).map((_, index) => (
                      <>
                        <div className="tf-mini-cart-item" key={index}>
                          <div className="tf-mini-cart-image">
                            <a href="">
                              <Skeleton height={122} />
                            </a>
                          </div>
                          <div className="tf-mini-cart-info">
                            <Skeleton className="title link" />
                            <Skeleton className="price fw-6" />
                            <div className="tf-mini-cart-btns">
                              <Skeleton className="wg-quantity small" />
                              <div className="tf-mini-cart-remove">
                                <Skeleton />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))
                  ) : cart.length === 0 ? (
                    <div className="text-center wishlist-section">
                      <p>Your Shopping Bag is Empty!</p>
                      <div className="cartlist-img">
                        <img src="/assets/images/wishlist.png" />
                      </div>
                      <Button outlined onClick={() => navigate("/")}>
                        Continue Shopping
                      </Button>
                    </div>
                  ) : (
                    cart?.map((elem, i) => (
                      <div className="tf-mini-cart-item" key={i}>
                        <div className="tf-mini-cart-image">
                          <span
                            onClick={() => handleNavigateProduct(elem)}
                            className="img-box"
                            style={{ cursor: "pointer" }}
                          >
                            <img src={elem.photos[0]} alt="pro2.jpg" />
                          </span>
                        </div>
                        <div className="tf-mini-cart-info">
                          <span
                            onClick={() => handleNavigateProduct(elem)}
                            className="title link"
                            style={{ cursor: "pointer" }}
                          >
                            {elem?.product_name}
                          </span>
                          <p className="">
                            ₹{(elem?.indiazona_price * elem?.quantity).toFixed(2)}
                          </p>
                            { 
                            elem?.variation?.size ? (
                          <p>
                              Size: 
                               <span className="fw-bold"> {elem?.variation?.size}</span>
                          </p>
                           ) : ''
                          }
                          {
                            elem?.variation?.color ? (
                          <p>
                             Color:  
                               <span className="fw-bold"> {elem?.variation?.color}</span>
                          </p>
                          ) : ''
                        }
                        <div className="d-flex justify-content-between">
                          <p>
                            Qty:  <span className="fw-bold"> {elem?.quantity} </span></p>
                          <p>
                            <span
                                onClick={() => handleRemoveCartItem(elem.id)}
                                style={{ cursor: "pointer",textDecoration:'underline' }}
                              >
                                Remove
                              </span></p>
                        </div>
                          <div className="tf-mini-cart-btns">
                            {/* <div className="wg-quantity small">
                              <span
                                className="btn-quantity minus-btn"
                                onClick={() =>
                                  handleQuantityChange(elem.id, -1)
                                }
                              >
                                -
                              </span>
                              <input
                                type="number"
                                name="number"
                                value={elem?.quantity}
                                disabled
                              />
                              <span
                                className="btn-quantity plus-btn"
                                onClick={() => handleQuantityChange(elem.id, 1)}
                              >
                                +
                              </span>
                            </div> */}
                           
                            <div className="tf-mini-cart-remove">
                              
                            </div>
                          </div>
                        </div>
                     </div>
                    ))
                  )}
                </div>

              </div>
            </div>
          </div>
          <div className="tf-mini-cart-bottom-wrap">
            <div className="tf-cart-totals-discounts">
              <div className="tf-cart-total">Subtotal</div>
              <div className="tf-totals-total-value fw-6">₹ {calculateSubtotal()}</div>
            </div>
            <div className="tf-mini-cart-line" />
            <div className="form-check cart-checkbox">
              <input
                className="form-check-input"
                type="checkbox"
                name="terms&conditions"
                id="terms&conditions"
              />
              <label htmlFor="terms&conditions" className="fw-4">
                I agree with the
                <a href="terms-conditions.html"> terms and conditions</a>
              </label>
            </div>
            <div className="tf-mini-cart-view-checkout d-flex justify-content-between">
              <span onClick={handleCloseSideCart}>
                <Link
                  to="/cart"
                  className="tf-btn btn-outline radius-3 link w-100 justify-content-center"
                >
                  View cart
                </Link>
              </span>
              <span onClick={handleCloseSideCart}>
                <Link
                  to="/cart"
                  className="tf-btn btn-fill animate-hover-btn radius-3 w-100 justify-content-center"
                >
                  Check out
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </Offcanvas>
  );
};

export default SideCartMenu;
