import { createSlice } from '@reduxjs/toolkit';
import { DOODLE } from '../helpers/slice.name';

export const doodleSlice = createSlice({
    name: DOODLE,
    initialState: {
        loading: false,

        doodleLoading: false,
        doodles: [],
        doodleMessage: "",
        doodleError: "",
    },
    reducers: {
        doodleRequest: (state) => {
            state.doodleLoading = true;
            state.doodleMessage = "";
            state.doodleError = "";
        },
        doodleSuccess: (state, action) => {
            state.doodleLoading = false;
            state.doodles = action.payload.data;
            state.doodleMessage = action.payload.message;
        },
        doodleError: (state, action) => {
            state.doodleLoading = false;
            state.doodleError = action.payload;
        },
    },
})

export const { doodleRequest, doodleSuccess, doodleError } = doodleSlice.actions;

export default doodleSlice.reducer;