import ShortVideoService from "../services/shortVideo";
import { shortVideoError, shortVideoRequest, shortVideoSuccess } from "../slices/shortVideo.slice";

export const fetchShortVideo = (payload) => {
  return (dispatch) => {
    dispatch(shortVideoRequest());
    ShortVideoService.getShortVideoService(payload)
      .then((response) => {
        const { status, data, message, error } = response.data;
        if (status === 1) {
          dispatch(shortVideoSuccess({ data, message }));
        } else {
          dispatch(shortVideoError({ data, message: error }));
        }
      })
      .catch((error) => {
        dispatch(shortVideoError({ data: [], message: error }));
      });
  };
};
