import { X } from 'lucide-react';
import React from 'react';
import { Offcanvas, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const CompareModel = ({ showCompare, handleHideCompare }) => {

    return (
        <Offcanvas show={showCompare} onHide={handleHideCompare} placement="bottom" className='offcanvas-bottom compare-product-wpr' id="offcanvasBottom">
            <div className="offcanvas-header border-bottom-0" style={{ cursor: 'pointer' }} onClick={handleHideCompare}>
                <i data-bs-dismiss="offcanvas" className="fa-solid fa-x btn-close"></i>
            </div>
            <Offcanvas.Body>
                <div className="canvas-wrapper">
                    <div className="canvas-body">
                        <Container>
                            <Row>
                                <Col lg={3} md={3} xs={12} className="compare-products">
                                    <div className="tf-compare-head">
                                        <div className="title">Compare Products</div>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} xs={12}>
                                    <div className="tf-compare-list">
                                        <div className="tf-compare-offcanvas">
                                            <div className="tf-compare-item">
                                                <div className="position-relative">
                                                    <div className="icon">
                                                        <X />
                                                    </div>
                                                    <Link to="/:category/product-list">
                                                        <img
                                                            className="radius-3"
                                                            src="/assets/images/Product/pro1.jpg"
                                                            alt="product-1"
                                                        />
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="tf-compare-item">
                                                <div className="position-relative">
                                                    <div className="icon">
                                                        <X />
                                                    </div>
                                                    <Link to="/:category/product-list">
                                                        <img
                                                            className="radius-3"
                                                            src="/assets/images/Product/pro12.jpg"
                                                            alt="product-1"
                                                        />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={3} md={3} xs={12} >
                                    <div className="tf-compare-buttons">
                                        <div className="tf-compare-buttons-wrap">
                                            <Link to='/compare-products'
                                                className="tf-btn radius-3 btn-fill justify-content-center fw-6 fs-14 flex-grow-1 animate-hover-btn" >
                                                Compare
                                            </Link>
                                            <div className="tf-compapre-button-clear-all link">
                                                <span style={{ cursor: 'pointer' }} onClick={handleHideCompare}>
                                                    Clear All
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default CompareModel